import React, { useState } from 'react';
import { Container, Card, Form, Button } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
// Image name updated to match your requirement


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #E6F0FF; /* Light Blue background */
    color: #2F4F4F; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled(Card)`
  background-color: #FAFAFA; /* Off-White for the card background */
  border-radius: 20px;
  width: 100%;
  max-width: 700px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  padding: 30px;
`;

const HeaderImage = styled.img`
  width: 100%;
  border-radius: 20px 20px 0 0;
  max-height: 200px; /* Limit the height */
  object-fit: cover; /* Ensure the image covers the area */
`;

const Title = styled(Card.Title)`
  text-align: center;
  color: #4169E1; /* Royal Blue for the title */
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: bold;
`;

const StyledForm = styled(Form)`
  margin: 20px 0;
`;

const StyledFormControl = styled(Form.Control)`
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #2F4F4F; /* Dark Gray for input borders */
`;

const SubmitButton = styled(Button)`
  width: 100%;
  background-color: #FFD700; /* Golden Yellow for the button */
  border: none;
  padding: 12px;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;

  &:hover {
    background-color: #4169E1; /* Royal Blue on hover */
  }
`;

const StyledLink = styled.a`
  color: #4169E1; /* Royal Blue for the link */
  text-decoration: none;
  margin-top: 15px;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }
`;

function TeacherAuth() {
  const [providerSignUpData, setProviderSignUpData] = useState({
    _id: uuidv4(),
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [alreadyProviderExist, setAlreadyProviderExist] = useState(false);
  const navigate = useNavigate();

  const googleSuccess = async (response) => {
    const { credential } = response;
    const payload = JSON.parse(atob(credential.split('.')[1]));
    const { email, name, family_name } = payload;

    try {
      const responseProvider = await axios.post('https://sell-skill.com/api/endpoints/providerSignUpData', {
        _id: uuidv4(),
        name,
        surname: family_name,
        email,
        password: '',
        confirmPassword: ''
      });
      const providerToken = responseProvider.data;

      if (!providerToken) {
        setAlreadyProviderExist(true);
      } else {
        localStorage.setItem('providerToken', JSON.stringify(providerToken));
        navigate("/profile-picture");
      }
    } catch (error) {
      setAlreadyProviderExist(true);
    }
  };

  const handleProviderSignUp = async () => {
    try {
      const responseProvider = await axios.post('https://sell-skill.com/api/endpoints/providerSignUpData', providerSignUpData);
      const providerToken = responseProvider.data;

      if (!providerToken) {
        setAlreadyProviderExist(true);
      } else {
        localStorage.setItem('providerToken', JSON.stringify(providerToken));
        navigate("/profile-picture");
      }
    } catch (error) {
      setAlreadyProviderExist(true);
    }
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          {/* Add the image here */}
          <HeaderImage src= '/images/collaboration-project-men.png' alt="Collaboration and teamwork" />
          <Title>Sign Up as a Provider</Title>
          <StyledForm>
            <StyledFormControl
              placeholder='Name'
              onChange={(e) => setProviderSignUpData({ ...providerSignUpData, name: e.target.value })}
            />
            <StyledFormControl
              placeholder='Surname'
              onChange={(e) => setProviderSignUpData({ ...providerSignUpData, surname: e.target.value })}
            />
            <StyledFormControl
              type='email'
              placeholder='Email'
              onChange={(e) => setProviderSignUpData({ ...providerSignUpData, email: e.target.value })}
            />
            <StyledFormControl
              type='password'
              placeholder='Password'
              onChange={(e) => setProviderSignUpData({ ...providerSignUpData, password: e.target.value })}
            />
            <StyledFormControl
              type='password'
              placeholder='Confirm Password'
              onChange={(e) => setProviderSignUpData({ ...providerSignUpData, confirmPassword: e.target.value })}
            />

            <SubmitButton onClick={handleProviderSignUp}>Next</SubmitButton>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <StyledLink href="/auth">Already have an account? Sign in</StyledLink>
            </div>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <GoogleLogin onSuccess={googleSuccess} />
            </div>
            {alreadyProviderExist && <div style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>Email already exists</div>}
          </StyledForm>
        </MainCard>
      </StyledContainer>
    </>
  );
}

export default TeacherAuth;


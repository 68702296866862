import React, { useEffect } from 'react';
import './PayPalButton.css';
import axios from 'axios'
const PayPalButton = () => {
  useEffect(() => {
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '10.00', // Replace with your amount
                },
              },
            ],
          });
        },
        onApprove:  (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
             await axios.patch('https://sell-skill.com/api/endpoints/paidCrypto')
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    addPayPalScript();
  }, []);

  return (
    <div className="paypal-container">
      <div className="paypal-content">
        <h2 className="paypal-title">Pay with PayPal</h2>
        <div id="paypal-button-container"></div>
      </div>
    </div>
  );
};

export default PayPalButton;

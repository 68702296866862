import React, { useEffect, useState } from 'react';
import { Container, Card, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TopBar from './TopBar';
import Jobs from './Jobs';
import ImgSlider from './ImgSlider';
import 'bootstrap/dist/css/bootstrap.min.css';

function PMainHome() {
  const [permission, setPermission] = useState(false);
  const tokenString = localStorage.getItem('providerToken');
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject?.token;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/verifyProvider', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermission(response.data.permission);
      } catch (error) {
        console.error('Error fetching permission:', error);
      }
    };
    fetchPermission();
  }, [token]);

  const navigateSignUpIn = () => {
    navigate('/auth');
  };

  return (
    <div style={{ background: 'linear-gradient(to right, #141e30, #243b55)', minHeight: '100vh', paddingBottom: '360px', color: 'white' }}>
      {permission ? (
        <Container fluid>
          <TopBar />
          <ImgSlider />
          <Jobs />
        </Container>
      ) : (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <Card className="text-center p-4" style={{ width: '100%', maxWidth: '400px', backgroundColor: '#1e1e2f', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <Card.Body>
              <Card.Title style={{ fontSize: '24px', marginBottom: '20px' }}>Welcome Back!</Card.Title>
              <Button variant="outline-light" onClick={navigateSignUpIn} style={{ padding: '10px 20px', fontSize: '18px' }}>
                Sign Up/In
              </Button>
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default PMainHome;

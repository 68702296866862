import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import './TradeGptLandingPage.css'; // Add custom styles for animations
import axios from 'axios';
import {useNavigate} from 'react-router-dom'
const TradeGptLandingPage = () => {
  const [formVisible, setFormVisible] = useState(false);
  const [inputText, setInputText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [viewerData, setViewerData] = useState({Name: '', Email: '', Number: ''});
  const navigate = useNavigate()
  if (window.fbq) {
    window.fbq('track', 'PageView', { 
      content_name: 'Thank You Page Lead',
      value: 0,
      currency: 'USD'
    });
  }

  const handleNext = async () => {
    navigate('/download-trade-with-gpt');
    await axios.post('https://sell-skill.com/api/endpoints/sendCustomerInfo', viewerData);
  };

  // Eye-catching phrases for the input field
  const phrases = [
    'Get the top AI stocks instantly',
    'Unlock the secrets to trading profits',
    'Discover the best trading strategies',
  ];

  useEffect(() => {
    const changeText = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % phrases.length);
      setInputText(phrases[currentIndex]);
    }, 2000); // Change text every 2 seconds

    return () => clearInterval(changeText);
  }, [currentIndex, phrases]);

  const handleDownloadClick = () => {
    setFormVisible(true);
  };

  return (
    <div className="container text-center mt-5">
      {/* Background animation */}
      <div className="background-animation"></div>
      
      {/* Logo Section */}
      <div className="mb-4 logo-animation">
        <img src="/LandingPagesImages/SK.png" alt="TradeGPT Logo" className="logo-small" />
        <h1 className="display-4 font-weight-bold slide-in-title">Profitable Trading Starts Here</h1>
      </div>

      {/* Animated Input Field with changing text */}
      <div className="input-group mb-4 mx-auto bounce" style={{ maxWidth: '600px' }}>
        <input
          type="text"
          className="form-control text-animation"
          value={inputText}
          readOnly
        />
        <div className="input-group-append">
          <button className="btn btn-primary" type="button" disabled>
            Send
          </button>
        </div>
      </div>

      {/* Ebook Promotion with flashing text */}
      <p className="lead font-weight-bold flash-text">Get 100+ TradeGPT Prompts Ebook For <span className="text-success">FREE</span> Now!</p>

      {/* Download Button with pulse effect */}
      <button onClick={handleDownloadClick} className="btn btn-success btn-lg mb-5 pulse">
        DOWNLOAD NOW!
      </button>

      {/* PDF Promo Image with floating effect */}
      <div className="d-flex justify-content-center">
        <img
          src="/LandingPagesImages/TradeGpt.jpg"
          alt="TradeGPT Ebook"
          className="promo-image floating"
        />
      </div>

      {/* Sliding form for user details */}
      <CSSTransition in={formVisible} timeout={300} classNames="drop" unmountOnExit>
        <div className="drop-form-wide">
          <h2 className="mb-3">Claim Your Free Ebook</h2>
          <input type="text" className="form-control mb-3" placeholder="Your Name"
                      onChange={(e) => setViewerData({ ...viewerData, Name: e.target.value })} />
          <input type="email" className="form-control mb-3" placeholder="Your Email" 
                      onChange={(e) => setViewerData({ ...viewerData, Email: e.target.value })}/>
          <input type="tel" className="form-control mb-3" placeholder="Your Phone Number" 
                      onChange={(e) => setViewerData({ ...viewerData, Number: e.target.value })}/>
          <button className="btn btn-primary"            onClick={handleNext}>Submit</button>
        </div>
      </CSSTransition>

      {/* Footer Disclaimer */}
      <footer className="mt-5 text-muted small">
        <p>TradeGPT © 2024 All Rights Reserved</p>
        <p>
          The prompts and suggestions provided by TradeGPT are based on market data and analysis for informational purposes only...
        </p>
      </footer>
    </div>
  );
};

export default TradeGptLandingPage;
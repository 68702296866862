import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FacebookAdsLandingPage.css'; // Custom CSS file for additional styles
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
const FacebookAdsLandingPage = () => {



  const [videoUrl1, setVideoUrl1] = useState('')
  const [videoUrl2, setVideoUrl2] = useState('')
  const [videoUrl3, setVideoUrl3] = useState('')
  const [videoUrl4, setVideoUrl4] = useState('')
  const [videoUrl5, setVideoUrl5] = useState('')
  const [videoUrl6, setVideoUrl6] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
      const fetchVideoUrl = async () => {
          try {
              const storage = getStorage();
              const videoRef1 = ref(storage, '/NewMoneyUgc/IMG_0539.MP4');
              const url1 = await getDownloadURL(videoRef1);
              setVideoUrl1(url1);

          } catch (error) {
              console.error('Error fetching video URL: ', error);
          }
      };
      fetchVideoUrl();
  }, []);

  useEffect(() => {
    const fetchVideoUrl = async () => {
        try {
            const storage = getStorage();
            const videoRef2 = ref(storage, '/NewMoneyUgc/IMG_8761.MP4');
            const url2 = await getDownloadURL(videoRef2);
            setVideoUrl2(url2);
          

        } catch (error) {
            console.error('Error fetching video URL: ', error);
        }
    };
    fetchVideoUrl();
}, []);

useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef3 = ref(storage, '/NewMoneyUgc/IMG_8763.MP4');
          const url3 = await getDownloadURL(videoRef3);
          setVideoUrl3(url3);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);


useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef4 = ref(storage, '/NewMoneyUgc/IMG_9455.MP4');
          const url4 = await getDownloadURL(videoRef4);
          setVideoUrl4(url4);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);
useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef5 = ref(storage, '/NewMoneyUgc/IMG_9745.MP4');
          const url5 = await getDownloadURL(videoRef5);
          setVideoUrl5(url5);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);
useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef6 = ref(storage, '/NewMoneyUgc/4.MP4');
          const url6 = await getDownloadURL(videoRef6);
          setVideoUrl6(url6);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);



  return (
    <div className="facebook-ads-landing-page" style={{ background: 'linear-gradient(to bottom, black, #0047AB)', color: 'white' }}>
      <Helmet>
        <title>Become an Expert Facebook Ads Marketer in 5 Weeks - Online Course</title>
        <meta 
          name="description" 
          content="Join our comprehensive Facebook Ads course and become an expert marketer in just 5 weeks. Learn essential skills to drive results and get certified." 
        />
        <meta 
          name="keywords" 
          content="Facebook Ads Course, Online Marketing Training, Facebook Marketing Skills, Expert Facebook Marketer, Learn Facebook Ads, Online Advertising" 
        />
      </Helmet>

      {/* Header Section */}
      <header className="text-center py-5">
        <h1 className="mb-3">Become an Expert Facebook Ads Marketer in Just 5 Weeks</h1>
        <p className="mb-4">Master all essential Facebook marketing skills to drive results and stay ahead in today's competitive landscape.</p>
        <div className="d-flex justify-content-center mt-3">
          <span className="badge badge-light mx-2">Online</span>
          <span className="badge badge-light mx-2">5 Weeks</span>
        </div>
      </header>

      {/* Course Image Section */}
      <section className="text-center py-5">
        <img 
          src="/LandingPagesImages/facebook-ads-course.jpg" 
          alt="Facebook Ads Course" 
          className="img-fluid course-image rounded" 
        />
      </section>

      {/* Key Features Section */}
      <section className="py-5 text-center">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h4>Full Guide from Scratch Until Pro</h4>
            </div>
            <div className="col-md-6">
              <h4>No Experience Needed</h4>
            </div>
          </div>
        </div>
      </section>

      {/* Primary CTA Button */}
      <section className="text-center py-5">
        <a href="/facebook-ads-academy-sign-up" className="btn btn-lg cta-button">Apply Now</a>
      </section>

      {/* Why Learn Facebook Ads Section */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-4 text-highlight">Here is Why You Need to Learn Facebook Ads</h2>
          <ul className="list-unstyled mt-4 text-center">
            <li>Stay ahead in today's competitive digital marketing landscape</li>
            <li>Drive more traffic and conversions to your business</li>
            <li>Expand your skillset with one of the most in-demand marketing skills</li>
            <li>Learn from industry experts and get certified</li>
          </ul>
        </div>
      </section>

      {/* Actionable Roadmap Section */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-5 text-highlight">Your Actionable Roadmap to Become a Top 1% Facebook Ads Marketer</h2>
          <div className="row justify-content-center">
            <div className="col-md-2 mb-4">
              <div className="week-box p-3 text-center">
                <h5>Week 1</h5>
                <p>Introduction to Facebook Ads Basics</p>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              <div className="week-box p-3 text-center">
                <h5>Week 2</h5>
                <p>Targeting and Audience Segmentation</p>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              <div className="week-box p-3 text-center">
                <h5>Week 3</h5>
                <p>Creating High-Converting Ads</p>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              <div className="week-box p-3 text-center">
                <h5>Week 4</h5>
                <p>Scaling Campaigns and Optimization</p>
              </div>
            </div>
            <div className="col-md-2 mb-4">
              <div className="week-box p-3 text-center">
                <h5>Week 5</h5>
                <p>Advanced Strategies and Certification</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      Your Actionable Roadmap to Become a Top 1% Facebook Ads Marketer
      {/* Secondary CTA Button */}
      <section className="text-center py-5">
        <a href="/facebook-ads-academy-sign-up" className="btn btn-lg cta-button">Apply Now</a>
      </section>

      {/* Meet Your Mentor Section */}
      <section className="py-5">
        <div className="container">
          <h2 className="text-center mb-4">Meet Your Mentor</h2>
          <div className="row mt-4 align-items-center">
            <div className="col-md-4 text-center">
              <img 
                src="/images/jun-wu.jpg" 
                alt="Mentor" 
                className="img-fluid rounded-circle mentor-image" 
              />
            </div>
            <div className="col-md-8">
              <p>Hello!

Welcome to the easiest and funnest way to learn Online Marketing.

I make helpful and fun courses to help you grow on social media and your online business.

My work speaks for itself. :)

15+ years working in the Entertainment Industry - Model/Actor/Director/Producer

15+ years working in Marketing. Branding, business strategies, and growth hacking.

7+ years as a Talent Instructor - Managing the careers and business of clients

As you get to know me, you will see everything I teach, I put to use myself. 

Find me on Instagram and I'll help you! :)

https://www.instagram.com/juntopmodel/</p>
            </div>
          </div>
        </div>
      </section>

      {/* Hear from Our Learners Section */}
     

      {/* Certification Section */}
      <section className="py-5 text-center">
        <h2 className="mb-3">Get a Certification of Completion</h2>
        <p>Receive a certificate after completing the course, showcasing your new skills.</p>
        <img 
          src="/LandingPagesImages/certificate." 
          alt="Certification of Completion" 
          className="img-fluid mt-4" 
        />
      </section>
    </div>
  );
};

export default FacebookAdsLandingPage;




// <Row>
// {/* Video 1 */}
// <Col md={4} className="mb-4">
//   <Card className="video-card border-0">
//     <Card.Body className="p-0">
//     {videoUrl1 ? (
//           <video className="course-video" controls>
//               <source src={videoUrl1} type="video/mp4" />
//           </video>
//       ) : (
//           <p>Loading video...</p>
//       )}
//     </Card.Body>
//   </Card>
// </Col>
// {/* Video 2 */}
// <Col md={4} className="mb-4">
//   <Card className="video-card border-0">
//     <Card.Body className="p-0">
//     {videoUrl2 ? (
//           <video className="course-video" controls>
//               <source src={videoUrl2} type="video/mp4" />
//           </video>
//       ) : (
//           <p>Loading video...</p>
//       )}
//     </Card.Body>
//   </Card>
// </Col>
// {/* Video 3 */}
// <Col md={4} className="mb-4">
//   <Card className="video-card border-0">
//     <Card.Body className="p-0">
//     {videoUrl3 ? (
//           <video className="course-video" controls>
//               <source src={videoUrl3} type="video/mp4" />
//           </video>
//       ) : (
//           <p>Loading video...</p>
//       )}

//     </Card.Body>
//   </Card>
// </Col>




// <Col md={4} className="mb-4">
//   <Card className="video-card border-0">
//     <Card.Body className="p-0">
//     {videoUrl4 ? (
//           <video className="course-video" controls>
//               <source src={videoUrl4} type="video/mp4" />
//           </video>
//       ) : (
//           <p>Loading video...</p>
//       )}

//     </Card.Body>
//   </Card>
// </Col>
// <Col md={4} className="mb-4">
//   <Card className="video-card border-0">
//     <Card.Body className="p-0">
//     {videoUrl5 ? (
//           <video className="course-video" controls>
//               <source src={videoUrl5} type="video/mp4" />
//           </video>
//       ) : (
//           <p>Loading video...</p>
//       )}

//     </Card.Body>
//   </Card>
// </Col>
// <Col md={4} className="mb-4">
//   <Card className="video-card border-0">
//     <Card.Body className="p-0">
//     {videoUrl6 ? (
//           <video className="course-video" controls>
//               <source src={videoUrl6} type="video/mp4" />
//           </video>
//       ) : (
//           <p>Loading video...</p>
//       )}

//     </Card.Body>
//   </Card>
// </Col>
// {/* Add more videos in similar Col elements if needed */}
// </Row>
// WorldForm.js
import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setWorldName } from '../../reducers/reducers';
function AddWorld() {
  const [worldName, setWorldNameI] = useState('');
  const [worldDescription, setWorldDescription] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleThumbnailChange = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('_id', uuidv4());
    formData.append('worldName', worldName);
    formData.append('worldDescription', worldDescription);
    formData.append('thumbnail', thumbnail);

    try {
      await axios.post('https://sell-skill.com/api/endpoints/addWorld', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      dispatch(setWorldName(worldName));
      navigate('/add-level');
    } catch (error) {
      console.error(error);
      alert('Error adding world.');
    }
  };

  return (
    <form>
      <div>
        <label>World Name:</label>
        <input
          type="text"
          value={worldName}
          onChange={(e) => setWorldNameI(e.target.value)}
          required
        />
      </div>
      <div>
        <label>World Description:</label>
        <textarea
          value={worldDescription}
          onChange={(e) => setWorldDescription(e.target.value)}
          required
        ></textarea>
      </div>
      <div>
        <label>Thumbnail:</label>
        <input
          type="file"
          onChange={handleThumbnailChange}
          accept="image/*"
          required
        />
      </div>
      <button type="button" onClick={handleSubmit}>Add World</button>
    </form>
  );
}

export default AddWorld;

// src/components/InstructorSignupForm.js
import React, { useState } from "react";
import axios from "axios";

const InstructorSignUp = () => {
    const [formData, setFormData] = useState({
        _id: "",
        name: "",
        surname: "",
        instructorDescription: "",
        allowedToUpload: false,
        instructorLevel: "",
        worlds: [
            {
                WorldDescription: "",
                levels: [
                    {
                        level: [
                            {
                                levelNumber: 1,
                                levelDescription: "",
                                lessons: [
                                    {
                                        lessonNumber: 1,
                                        lessonDescription: "",
                                        lessonResource: ""
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("http://sell-skill.com/instructor/signup", formData);
            alert(response.data.message);
        } catch (error) {
            console.error("There was an error signing up!", error);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>ID:</label>
                <input type="text" name="_id" value={formData._id} onChange={handleChange} />
            </div>
            <div>
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div>
                <label>Surname:</label>
                <input type="text" name="surname" value={formData.surname} onChange={handleChange} required />
            </div>
            <div>
                <label>Description:</label>
                <textarea name="instructorDescription" value={formData.instructorDescription} onChange={handleChange} />
            </div>
            <div>
                <label>Allowed to Upload:</label>
                <input type="checkbox" name="allowedToUpload" checked={formData.allowedToUpload} onChange={handleChange} />
            </div>
            <div>
                <label>Level:</label>
                <input type="text" name="instructorLevel" value={formData.instructorLevel} onChange={handleChange} required />
            </div>
            {/* Add fields for worlds, levels, lessons as needed */}
            <button type="submit">Sign Up</button>
        </form>
    );
};

export default InstructorSignUp;

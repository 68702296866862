import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ReactPlayer from 'react-player'; // You'll need to install react-player: npm install react-player
import './FacebookAdsTrailer.css'; // Create a CSS file for styling
import {useNavigate} from 'react-router-dom'

function FacebookAdsTrailer() {
  const [videoUrl, setVideoUrl] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const storage = getStorage();
        const videoRef = ref(storage, 'FacebookAdsCourse1/Week1/111.mp4');
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL: ', error);
      }
    };
    fetchVideoUrl();
  }, []);

  return (
    <div className="trailer-container">
      <div className="trailer-content">
        <h1 className="trailer-title">Unlock the Power of Facebook Ads!</h1>
        <p className="trailer-description">
          Ready to scale your business with the best Facebook Ads strategies? 
          Watch this trailer to get a sneak peek of what's inside our exclusive course!
        </p>
        {videoUrl ? (
          <ReactPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width="100%"
            height="500px"
            className="video-player"
          />
        ) : (
          <p>Loading video...</p>
        )}
        <button className="cta-button" onClick={() => navigate('/facebook-ads-course')}>
          Enroll Now and Boost Your Sales!
        </button>
      </div>
    </div>
  );
}

export default FacebookAdsTrailer;

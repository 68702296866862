// CourseCard.js
import React from 'react';
import { Card, Button } from 'react-bootstrap';

const CourseCard = ({ course, onDelete }) => {
  return (
    <Card style={{ width: '18rem', margin: '10px' }}>
      {course.thumbnail && <Card.Img variant="top" src={course.thumbnail} />}
      <Card.Body>
        <Card.Title>{course.title}</Card.Title>
        <Card.Text>{course.description}</Card.Text>
        <Card.Text>${course.price}</Card.Text>
        <Button variant="danger" onClick={() => onDelete(course._id)}>
          Delete
        </Button>
      </Card.Body>
    </Card>
  );
};

export default CourseCard;

import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './Videos.css'; // Import the external CSS for styling
import axios from 'axios';
import { Card } from 'react-bootstrap';
function FaW5V3() {
    const [videoUrl, setVideoUrl] = useState('');
    const navigate = useNavigate();
    const [isPaidStatus, setIsPaidStatus] = useState(false)
    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const storage = getStorage();
                const videoRef = ref(storage, 'FacebookAdsCourse1/Week5/34.mp4');
                const url = await getDownloadURL(videoRef);
                setVideoUrl(url);
            } catch (error) {
                console.error('Error fetching video URL: ', error);
            }
        };
        fetchVideoUrl();
    }, []);


       
  //payment--/payment--/payment////--payment//start--start--start//

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        
        // PayPal SDK script to include both PayPal and card payments (no funding sources are disabled)
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;


        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        // No funding source restriction, so both PayPal and card will be available
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '9.00', // Replace with your actual amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            try {
              await axios.patch('https://sell-skill.com/api/endpoints/paidFacebookAds');
              setIsPaidStatus(true);

            } catch (error) {
              console.error('Error updating payment status: ', error);
            }
            window.location.reload();
          });
        },        
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    addPayPalScript();
  }, []);

  useEffect(() => {
    const checkIsPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidFacebookAdsCourse');
      setIsPaidStatus(response.data.isPaid);
    }
    checkIsPaid();
  }, []);

  
    //payment--/payment--/payment////--payment//end--end--end--end--end//
    return (
        <div className="video-container">
            {
                isPaidStatus ? (<>            <div className="video-header">
                    <button className="back-button" onClick={() => navigate('/facebook-ads-course/week-5')}>
                        ← Back
                    </button>
                    <h1 className="course-title">Advanced targeting secrets - Lower lead costs</h1>
    
                </div>
                <div className="video-content">
                    {videoUrl ? (
                        <video className="course-video" controls>
                            <source src={videoUrl} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}
                </div></>): (
                    <div className="payment-section">
                      <Card className="payment-card">
                        <Card.Body>
                          <h2 className="payment-title">Unlock the Course</h2>
                          <p className="payment-description">Get full access to the Facebook Ads Course by making a one-time payment of $9.00.</p>
                          <div className="payment-buttons">
                            <div id="paypal-button-container" className="paypal-button"></div>
                            {/* PayPal payment button will appear here */}
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  )
            }

        </div>
    );
}

export default FaW5V3;
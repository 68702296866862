import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.textSection}>
          <h1 style={styles.title}>404 - Page Not Found</h1>
          <p style={styles.subtitle}>
            Oops! The page you're looking for doesn't exist.
          </p>
          <p style={styles.description}>
            It seems you've landed on a page that doesn't exist. But don't worry! 
            We're here to help you find your way back. You can head back to the homepage 
            or explore other sections of our website. 
          </p>
          <button style={styles.button} onClick={handleGoHome}>
            Go to Homepage
          </button>
        </div>
        <div style={styles.imageSection}>
          {/* Insert an image here */}
          {/* Example: <img src="path_to_image.jpg" alt="Not Found" style={styles.image} /> */}
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f4f4f4",
    color: "#333",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  content: {
    maxWidth: "900px",
    width: "100%",
    padding: "20px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textSection: {
    flex: 1,
    paddingRight: "20px",
  },
  title: {
    fontSize: "36px",
    fontWeight: "700",
    marginBottom: "16px",
    color: "#ff6b6b",
  },
  subtitle: {
    fontSize: "20px",
    marginBottom: "16px",
  },
  description: {
    fontSize: "16px",
    marginBottom: "32px",
    lineHeight: "1.6",
  },
  button: {
    padding: "12px 24px",
    fontSize: "16px",
    color: "#fff",
    backgroundColor: "#ff6b6b",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
  },
  imageSection: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "300px",
    borderRadius: "8px",
  },
};

export default NotFoundPage;

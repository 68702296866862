import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOverviewEstimate } from '../../reducers/reducers';
import axios from 'axios';

function Scope() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousPostData = useSelector((state) => state.allow.overview);
  const [postData, setPostData] = useState(previousPostData);
  const [permission, setPermission] = useState(false);

  let token = JSON.parse(localStorage.getItem('clientToken'))?.token;

  useEffect(() => {
    const fetchPermission = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setPermission(response.data.permission);
    };
    fetchPermission();
  }, [token]);

  const handleNextClick = () => {
    dispatch(setOverviewEstimate(postData));
    navigate("/posting-experience");
  };

  const navigateSignUpIn = () => {
    navigate('/auth');
  };

  return (
    <div style={styles.container}>
      {permission ? (
        <Container>
          <Card style={styles.outerCard}>
            <Card.Body>
              <Card style={styles.innerCard}>
                <Card.Title style={styles.title}>
                  Request's Scope
                </Card.Title>

                <div style={styles.buttonContainer}>
                  {['Large', 'Medium', 'Small'].map(scope => (
                    <Button
                      key={scope}
                      style={postData.scope === scope ? styles.selectedButton : styles.button}
                      onClick={() => setPostData({ ...postData, scope })}
                    >
                      {scope}
                    </Button>
                  ))}
                </div>

                <Button style={styles.nextButton} onClick={handleNextClick}>Next</Button>
              </Card>
            </Card.Body>
          </Card>
        </Container>
      ) : (
        <div style={styles.signInPrompt}>
          <Card style={styles.promptCard}>
            <Button onClick={navigateSignUpIn} style={styles.signInButton}>Sign Up/In</Button>
          </Card>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    height: '100vh',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Gradient background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  outerCard: {
    borderRadius: '30px',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Outer card gradient
    color: '#fff',
    width: '100%',
    maxWidth: '600px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
    padding: '30px',
  },
  innerCard: {
    borderRadius: '20px',
    background: '#0f2027', // Inner card background
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '30px',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#00c6ff', // Accent color for title
    marginBottom: '30px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '30px',
  },
  button: {
    width: '120px',
    height: '50px',
    background: '#004e92', // Standard button background
    borderColor: '#004e92',
    color: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.2s ease',
    cursor: 'pointer',
  },
  selectedButton: {
    width: '120px',
    height: '50px',
    background: '#00c6ff', // Selected button background
    borderColor: '#00c6ff',
    color: '#000428',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
    transition: 'transform 0.2s ease',
    cursor: 'pointer',
    transform: 'scale(1.05)', // Slightly larger scale for selected button
  },
  nextButton: {
    width: '100%',
    background: '#00c6ff', // Next button color
    borderColor: '#00c6ff',
    color: '#000428',
    borderRadius: '8px',
    fontWeight: 'bold',
    fontSize: '16px',
    padding: '12px',
    boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
  },
  signInPrompt: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Background for sign-in prompt
  },
  promptCard: {
    padding: '30px',
    backgroundColor: '#0f2027',
    color: '#fff',
    borderRadius: '20px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
  },
  signInButton: {
    background: '#00c6ff',
    borderColor: '#00c6ff',
    color: '#000428',
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '18px',
    boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
  },
};

export default Scope;

import React, { useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function FATypeOfWork() {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [others, setOthers] = useState('');

  const handleNextClick = async () => {
    navigate("/facebook-ads-course");
    await axios.post('https://sell-skill.com/api/endpoints/clientCategory', category);
  };

  const handleCategoryClick = (cat) => {
    setCategory((prevCategories) => {
      if (prevCategories.includes(cat)) {
        return prevCategories.filter((c) => c !== cat);
      } else {
        return [...prevCategories, cat];
      }
    });
  };

  const handleAddOther = () => {
    if (others.trim() && !category.includes(others)) {
      setCategory((prevCategories) => [...prevCategories, others]);
      setOthers('');
    }
  };

  return (
    <div style={styles.container}>
      <Container>
        <Card style={styles.outerCard}>
          <Card.Body style={styles.innerCard}>
            <Card.Title style={styles.title}>
              Which category are you interested in?
            </Card.Title>
            <div style={styles.buttonsContainer}>
              {['Affiliate marketing', 'Digital marketing', 'Coding', 'AI', 'Medicine', 'Health & fitness', 'Legal & financial advice', 'Languages', 'Hobbies & skills', 'Cryptocurrency', 'Home improvement & DIY', 'Creative services', 'Education & tutoring', 'Entertainment & Leisure', 'Dropshipping', 'Pet services'].map((cat) => (
                <Button
                  key={cat}
                  style={category.includes(cat) ? styles.selectedButton : styles.button}
                  onClick={() => handleCategoryClick(cat)}
                >
                  {cat}
                </Button>
              ))}
            </div>
            <Form.Control
              type="text"
              value={others}
              onChange={(e) => setOthers(e.target.value)}
              placeholder="Enter other field ..."
              style={styles.input}
            />
            <Button style={styles.addButton} onClick={handleAddOther}>Add Other</Button>
            <Button style={styles.nextButton} onClick={handleNextClick}>Next</Button>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #000428 0%, #004e92 100%)',
    color: '#fff',
    padding: '20px', // Added padding for mobile screens
  },
  outerCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    maxWidth: '100%', // Card adapts to screen width
    width: '500px', // Limits card width on larger screens
    margin: '0 auto', // Ensures the card is centered horizontally
  },
  innerCard: {
    textAlign: 'center',
  },
  title: {
    fontSize: '1.8rem',
    marginBottom: '1.5rem',
    color: '#ffffff',
    '@media (max-width: 768px)': { // Adjust font size for smaller screens
      fontSize: '1.4rem',
    },
  },
  buttonsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px',
    marginBottom: '20px',
  },
  button: {
    backgroundColor: 'transparent',
    borderColor: '#ffffff',
    color: '#ffffff',
    borderRadius: '30px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    '@media (max-width: 768px)': { // Adjust button size for mobile
      padding: '8px 15px',
      fontSize: '0.85rem',
    },
  },
  selectedButton: {
    backgroundColor: '#004e92',
    borderColor: '#ffffff',
    color: '#ffffff',
    borderRadius: '30px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    '@media (max-width: 768px)': { // Adjust selected button size for mobile
      padding: '8px 15px',
      fontSize: '0.85rem',
    },
  },
  input: {
    backgroundColor: 'transparent',
    borderColor: '#ffffff',
    color: '#ffffff',
    marginBottom: '20px',
    borderRadius: '20px',
    padding: '10px 15px',
    width: '100%', // Ensures the input spans the container width
  },
  addButton: {
    backgroundColor: '#004e92',
    color: '#ffffff',
    borderRadius: '30px',
    padding: '10px 20px',
    marginBottom: '20px',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    width: '100%', // Full-width for mobile
  },
  nextButton: {
    backgroundColor: '#000428',
    color: '#ffffff',
    borderRadius: '30px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    width: '100%', // Full-width for mobile
  },
};

export default FATypeOfWork;

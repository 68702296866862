import React from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import Footer from '../Footer/Footer';

const HighDemandSkills2024 = () => {
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/auth');
    };

    return (
        <>
            <Helmet>
                <title>Top 10 High-Demand Freelance Skills for 2024: Future-Proof Your Career</title>
                <meta name="description" content="Discover the top 10 freelance skills that will be in high demand in 2024. Stay ahead of the curve and learn how to future-proof your freelance career with these essential skills." />
                <meta name="keywords" content="high-demand skills, freelance skills 2024, top freelance skills, future-proof career, in-demand freelance skills" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div style={styles.container}>
                <h1 style={styles.title}>Top 10 High-Demand Freelance Skills for 2024</h1>
               
                <img src="/images/pexels1.jpg" alt="Freelancer working on laptop" style={styles.image} />
                
                <div style={styles.content}>
                    <h2 style={styles.subheading}>Why These Freelance Skills Are in High Demand in 2024</h2>
                    <p style={styles.paragraph}>As the freelance economy continues to grow, certain skills are becoming increasingly valuable. In 2024, freelancers who possess the right skills will find themselves in high demand, able to command higher rates and work with top clients. Understanding which skills are trending and why they are important can help you future-proof your career and stay ahead of the competition.</p>
                    
                    <h2 style={styles.subheading}>Skill #1: [Insert Skill Name] – Why It’s Crucial</h2>
                    <p style={styles.paragraph}>[Details about the skill and its relevance in 2024, including how it impacts businesses and why clients are willing to pay a premium for it.]</p>
                    
                    <h2 style={styles.subheading}>How to Learn These High-Demand Skills</h2>
                    <p style={styles.paragraph}>To acquire these skills, you can take online courses, attend workshops, or even self-study using resources available on the web. Platforms like Coursera, Udemy, and LinkedIn Learning offer comprehensive courses that can help you get started. Practical experience, through freelancing on platforms like Sell-Skill, will further enhance your expertise.</p>
                    
                    <button onClick={handleSignUp} style={styles.button}>Sign Up on Sell-Skill</button>

                    <div style={styles.linkContainer}>
                        <p style={styles.linkText}>Want to know more? Check out our <a href="/find-expert" style={styles.link}>expert advice</a> on choosing the right skills.</p>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

const styles = {
    container: {
        maxWidth: '850px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.8',
        color: '#333',
        boxSizing: 'border-box',
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: '700',
        color: '#222',
        textAlign: 'center',
        marginBottom: '30px',
    },
    image: {
        width: '100%',
        borderRadius: '12px',
        marginBottom: '20px',
    },
    content: {
        padding: '0 15px',
    },
    subheading: {
        fontSize: '1.8rem',
        fontWeight: '600',
        color: '#333',
        marginTop: '30px',
    },
    paragraph: {
        fontSize: '1.1rem',
        color: '#555',
        marginTop: '15px',
        textAlign: 'justify',
        lineHeight: '1.7',
    },
    button: {
        display: 'block',
        width: 'fit-content',
        margin: '30px auto',
        padding: '12px 35px',
        fontSize: '1.1rem',
        fontWeight: '600',
        color: '#fff',
        backgroundColor: '#007BFF',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    buttonHover: {
        backgroundColor: '#0056b3',
    },
    linkContainer: {
        marginTop: '40px',
        textAlign: 'center',
    },
    linkText: {
        fontSize: '1.2rem',
        color: '#333',
        marginBottom: '15px',
    },
    link: {
        color: '#007BFF',
        textDecoration: 'none',
        fontSize: '1.1rem',
    },
};

export default HighDemandSkills2024;

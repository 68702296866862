import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { setOverviewTitle } from '../../reducers/reducers';
import axios from 'axios';

function Title() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousPostData = useSelector((state) => state.allow.overview);
  const [postData, setPostData] = useState(previousPostData);
  const [permission, setPermission] = useState(false);

  let token = localStorage.getItem('clientToken');
  const tokenObject = JSON.parse(token);
  token = tokenObject.token || tokenObject;

  useEffect(() => {
    const fetchPermission = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPermission(response.data.permission);
    };
    fetchPermission();
  }, [token]);

  const handleNextClick = () => {
    dispatch(setOverviewTitle(postData));
    navigate("/posting-skills");
  };

  const navigateSignUpIn = () => {
    navigate('/auth');
  };

  return (
    <div style={styles.container}>
      {permission ? (
        <Container>
          <Card style={styles.outerCard}>
            <Card.Body>
              <Card style={styles.innerCard}>
                <Card.Title style={styles.title}>
                  <span style={styles.titleText}>Title</span>
                </Card.Title>
                <Form style={styles.form}>
                  <Form.Control
                    style={styles.input}
                    placeholder='Enter a title that describes your post'
                    onChange={(e) => setPostData({ ...previousPostData, title: e.target.value })}
                  />
                </Form>
                <Button onClick={handleNextClick} style={styles.nextButton}>Next</Button>
              </Card>
            </Card.Body>
          </Card>
        </Container>
      ) : (
        <div style={styles.permissionContainer}>
          <Card style={styles.permissionCard}>
            <Button onClick={navigateSignUpIn} style={styles.signInButton}>Sign Up/In</Button>
          </Card>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    height: '100vh',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Enhanced gradient background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  outerCard: {
    position: 'relative',
    borderRadius: '40px',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Outer Card gradient
    color: '#fff',
    width: '100%',
    maxWidth: '600px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
  },
  innerCard: {
    borderRadius: '20px',
    background: '#0f2027', // Darker background for inner card
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '30px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  titleText: {
    fontSize: '28px',
    fontWeight: 'bold',
    color: '#00c6ff', // Bright accent color for the title text
  },
  form: {
    width: '100%',
    margin: '20px 0',
  },
  input: {
    borderRadius: '8px',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #00c6ff', // Border matching the accent color
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  nextButton: {
    width: '100%',
    background: '#00c6ff', // Bright button color
    borderColor: '#00c6ff',
    color: '#000428',
    borderRadius: '8px',
    padding: '10px 0',
    fontWeight: 'bold',
    fontSize: '18px',
    boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
  },
  permissionContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Background for permission container
  },
  permissionCard: {
    borderRadius: '20px',
    backgroundColor: '#0f2027',
    color: '#fff',
    padding: '30px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
  },
  signInButton: {
    background: '#00c6ff',
    borderColor: '#00c6ff',
    color: '#000428',
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '18px',
    boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
  },
};

export default Title;

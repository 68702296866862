import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Modules.css'; // Custom CSS for additional styling
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

function FaWeek1() {
  const navigate = useNavigate();
  const [isPaidStatus, setIsPaidStatus] = useState(false)
//titles
  const videos = [
    { title: '1_ START HERE!', id: 1 },
    { title: '2_ Top Secret way to get Facebook Ads done in seconds with AI', id: 2 },
    { title: '3_ When to Run Facebook Ads Vs YouTube Ads', id: 3 },
    { title: '4_ Easy Facebook Page Setup', id: 4},
    { title: '5_ Easy Facebook Ads Manager Setup', id: 5 },
    { title: '6_ Fun Facebook Ads Manager Tour', id: 6},
    { title: '7_ Your Movie Studio - Easy Ad Campaign Structure', id: 7 },
    { title: '8_ 4 Step Customer Pathway', id: 8},
    { title: '9_ The Power of the Facebook Pixel', id: 9 },
  ];

  //go to video
  const goToVideo = (id) => {
    navigate(`/facebook-ads-course/week-1/video-${id}`);
  };

  //ipaid route


  //payment--/payment--/payment////--payment//start--start--start//

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        
        // PayPal SDK script to include both PayPal and card payments (no funding sources are disabled)
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;


        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        // No funding source restriction, so both PayPal and card will be available
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '9.00', // Replace with your actual amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            try {
              await axios.patch('https://sell-skill.com/api/endpoints/paidFacebookAds');
              setIsPaidStatus(true);

            } catch (error) {
              console.error('Error updating payment status: ', error);
            }
            window.location.reload();
          });
        },        
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    addPayPalScript();
  }, []);

  useEffect(() => {
    const checkIsPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidFacebookAdsCourse');
      setIsPaidStatus(response.data.isPaid);
    }
    checkIsPaid();
  }, []);

  
    //payment--/payment--/payment////--payment//end--end--end--end--end//
  return (
    <Container fluid className="module1-home-container text-center">
      {
        isPaidStatus ? (<>
        <h1 className="page-title mb-4">Crypto Module One Videos</h1>
      <Row className="justify-content-center">
        {videos.map((video) => (
          <Col key={video.id} xs={12} md={6} lg={6} className="mb-3">
            <Card className="video-card">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="video-title">{video.title}</span>
                <Button
                  variant="primary"
                  className="video-btn"
                  onClick={() => goToVideo(video.id)}
                >
                  Watch
                </Button>
              </Card.Header>
            </Card>
          </Col>
        ))}
      </Row></>) : (
        <div className="payment-section">
          <Card className="payment-card">
            <Card.Body>
              <h2 className="payment-title">Unlock the Course</h2>
              <p className="payment-description">Get full access to the Facebook Ads Course by making a one-time payment of $9.00.</p>
              <div className="payment-buttons">
                <div id="paypal-button-container" className="paypal-button"></div>
                {/* PayPal payment button will appear here */}
              </div>
            </Card.Body>
          </Card>
        </div>
      )
      }
    </Container>
  );
}

export default FaWeek1;
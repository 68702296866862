import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ReactPlayer from 'react-player'; // You'll need to install react-player: npm install react-player
import './TRTrailer.css'; // Create a CSS file for styling
import { useNavigate } from 'react-router-dom';

function TRTrailer() {
  const [videoUrl, setVideoUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const storage = getStorage();
        const videoRef = ref(storage, '/TRCourse/Week1/1.mp4'); // Update video path for Trading course
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL: ', error);
      }
    };
    fetchVideoUrl();
  }, []);

  return (
    <div className="trailer-container">
      <div className="trailer-content">
        <h1 className="trailer-title">Master the Art of Trading!</h1>
        <p className="trailer-description">
          Discover how to navigate the financial markets like a pro. Our comprehensive trading course covers everything from technical analysis to advanced strategies that will help you succeed in stocks, forex, and crypto. Watch the trailer to see how you can start making smarter trades today!
        </p>
        {videoUrl ? (
          <ReactPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width="100%"
            height="500px"
            className="video-player"
          />
        ) : (
          <p>Loading video...</p>
        )}
        <button className="cta-button"
          onClick={() => navigate('/trading')}
        >
          Start Trading Now!
        </button>
      </div>
    </div>
  );
}

export default TRTrailer;

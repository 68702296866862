import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProposalId, setProviderId } from '../../reducers/reducers';

function InvitationAcceptance() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [invitationAcceptances, setInvitationAcceptances] = useState([]);

    useEffect(() => {
        const killNewInvitationAcceptanceNotification = async () => {
            await axios.patch('https://sell-skill.com/api/endpoints/killNewInvitationAcceptanceNotification');
        };
        killNewInvitationAcceptanceNotification();
    }, []);

    useEffect(() => {
        const fetchInvitationsAcceptances = async () => {
            const result = await axios.get('https://sell-skill.com/api/endpoints/getInvitationAcceptance');
            setInvitationAcceptances(result.data);
        };
        fetchInvitationsAcceptances();
    }, []);

    const handleGoToPayment = async (providerId) => {
        dispatch(setProposalId(providerId));
        navigate('/paypal');
    };

    const handleGoToMessage2 = async (providerId) => {
        dispatch(setProviderId(providerId));
        navigate('/client-access-messages');
    };

    return (
        <Container className="py-5" style={styles.container}>
            {invitationAcceptances.length > 0 ? (
                invitationAcceptances.map(({ invitationAcceptance }) => (
                    <Card key={invitationAcceptance.providerId} style={styles.card}>
                        <Card.Body>
                            <Card.Text style={styles.cardText}>
                                <strong>{invitationAcceptance.providerName}</strong> has accepted your invitation
                            </Card.Text>
                            <div style={styles.buttonContainer}>
                                <Button
                                    onClick={() => handleGoToPayment(invitationAcceptance.providerId)}
                                    style={styles.button}
                                >
                                    Go to payment
                                </Button>
                                <Button
                                    onClick={() => handleGoToMessage2(invitationAcceptance.providerId)}
                                    style={styles.button}
                                >
                                    Message
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                ))
            ) : (
                <div style={styles.noAcceptanceText}>No invitation acceptances found.</div>
            )}
        </Container>
    );
}

const styles = {
    container: {
        backgroundColor: '#F5F5F5', // Light Gray background
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    card: {
        backgroundColor: '#FFFFFF', // White background
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 47, 108, 0.2)', // Navy Blue shadow
        width: '100%',
        maxWidth: '600px',
        marginBottom: '20px',
        padding: '20px',
    },
    cardText: {
        color: '#008080', // Teal text
        fontSize: '18px',
        marginBottom: '20px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    button: {
        backgroundColor: '#32CD32', // Vibrant Green
        color: '#FFFFFF',
        borderRadius: '30px',
        padding: '10px 20px',
        width: '48%',
    },
    noAcceptanceText: {
        color: '#002f6c', // Navy Blue text
        fontSize: '20px',
        textAlign: 'center',
        marginTop: '20px',
    },
};

export default InvitationAcceptance;

import React from 'react';
import { Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';

function ImgSlider() {
  const images = [
    '/images/oneP.png',
    '/images/twoP.png',
    '/images/threeP.png',
    '/images/fourP.png',
    '/images/fiveP.png',


  ];

  return (
    <StyledCarousel>
      {images.map((image, index) => (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100"
            src={image}
            alt={`Slide ${index + 1}`}
          />
        </Carousel.Item>
      ))}
    </StyledCarousel>
  );
}

export default ImgSlider;

const StyledCarousel = styled(Carousel)`
  .carousel-item {
    height: 400px;

    @media (max-width: 768px) {
      height: 200px;
    }

    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .carousel-indicators {
    bottom: -20px;

    @media (max-width: 768px) {
      bottom: -10px;
    }
  }
`;

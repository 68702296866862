import axios from 'axios';
import React, { useState } from 'react';
import { Button, Form, Container, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function MakeReport() {
    const [providerEmail, setProviderEmail] = useState('');
    const [report, setReport] = useState('');
    const navigate = useNavigate();

    const handleUploadReport = async () => {
        try {
            await axios.post('https://sell-skill.com/api/endpoints/insertReport', { providerEmail, report });
            navigate('/client');
        } catch (error) {
            console.error('Error uploading report:', error);
            // Handle error accordingly
        }
    };

    return (
        <div style={styles.container}>
            <Container>
                <Card style={styles.card}>
                    <Card.Body>
                        <Card.Title style={styles.title}>
                            <b>Create a Report</b>
                        </Card.Title>
                        <Form style={styles.form}>
                            <Form.Group controlId="formProviderEmail" style={styles.formGroup}>
                                <Form.Label style={styles.label}>Provider Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="Enter the provider email"
                                    value={providerEmail}
                                    onChange={(e) => setProviderEmail(e.target.value)}
                                    style={styles.input}
                                />
                            </Form.Group>
                            <Form.Group controlId="formReport" style={styles.formGroup}>
                                <Form.Label style={styles.label}>Report</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    placeholder="Enter the report you want to make"
                                    value={report}
                                    onChange={(e) => setReport(e.target.value)}
                                    style={styles.textarea}
                                />
                            </Form.Group>
                            <Button onClick={handleUploadReport} style={styles.button}>
                                Upload
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

const styles = {
    container: {
        minHeight: '100vh',
        backgroundColor: '#F5F5F5', // Light Gray
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    card: {
        backgroundColor: '#FFFFFF', // White
        borderRadius: '15px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        width: '100%',
        maxWidth: '600px',
        padding: '20px',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#002f6c', // Navy Blue
        fontSize: '1.8rem',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
    },
    formGroup: {
        marginBottom: '15px',
    },
    label: {
        color: '#002f6c', // Navy Blue
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        borderRadius: '5px',
        borderColor: '#008080', // Teal
        padding: '10px',
    },
    textarea: {
        borderRadius: '5px',
        borderColor: '#008080', // Teal
        padding: '10px',
        resize: 'vertical',
    },
    button: {
        backgroundColor: '#008080', // Teal
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '25px',
        padding: '10px',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
};

// Optional: Add hover effect for the button using a simple inline approach
// Note: For a more scalable solution, consider using CSS or a styling library.
const buttonHoverStyle = {
    backgroundColor: '#32CD32', // Vibrant Green
};

// You can use a state to handle hover effects if desired, but for simplicity, it's omitted here.

export default MakeReport;

import React, { useState } from 'react';
import { Container, Card, Form, Button, Alert } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function ClientSignUp() {
  const navigate = useNavigate();
  const [clientSignUpData, setClientSignUpData] = useState({
    _id: uuidv4(),
    name: '',
    surname: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({});
  const [alreadyClientExist, setAlreadyClientExist] = useState(false);

  const validateInput = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!clientSignUpData.name) newErrors.name = "Name is required";
    if (!clientSignUpData.surname) newErrors.surname = "Surname is required";
    if (!emailRegex.test(clientSignUpData.email)) newErrors.email = "Invalid email format";
    if (clientSignUpData.password.length < 6) newErrors.password = "Password must be at least 6 characters";
    if (clientSignUpData.password !== clientSignUpData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    return newErrors;
  };

  const handleClientSignUp = async () => {
    const validationErrors = validateInput();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const responseClient = await axios.post('https://sell-skill.com/api/endpoints/clientSignUp', clientSignUpData);
      const clientToken = responseClient.data;
      
      if (!clientToken) {
        setAlreadyClientExist(true);
      } else {
        localStorage.setItem('clientToken', JSON.stringify(clientToken));
        navigate("/client-profile-picture");
      }
    } catch (error) {
      console.error('Error signing up:', error);
    }
  };

  const googleSuccess = async (response) => {
    const { credential } = response;
    const payload = JSON.parse(atob(credential.split('.')[1]));
    const { email, name, family_name } = payload;

    try {
      const responseClient = await axios.post('https://sell-skill.com/api/endpoints/clientSignUp', {
        _id: uuidv4(),
        name,
        surname: family_name,
        email,
        password: '',
        confirmPassword: ''
      });
      
      const clientToken = responseClient.data;
      if (!clientToken) {
        setAlreadyClientExist(true);
      } else {
        localStorage.setItem('clientToken', JSON.stringify(clientToken));
        navigate("/client-profile-picture");
      }
    } catch (error) {
      console.error('Error with Google sign-up:', error);
    }
  };

  return (
    <div style={styles.container}>
      <Container>
        <Card style={styles.outerCard}>
          <Card style={styles.innerCard}>
            <Card.Title style={styles.title}>
              Sign up as a client
            </Card.Title>
            <Form style={styles.form}>
              <Form.Control
                placeholder='Name'
                onChange={(e) => setClientSignUpData({ ...clientSignUpData, name: e.target.value })}
                isInvalid={!!errors.name}
                style={styles.input}
              />
              <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>

              <Form.Control
                placeholder='Surname'
                onChange={(e) => setClientSignUpData({ ...clientSignUpData, surname: e.target.value })}
                isInvalid={!!errors.surname}
                style={styles.input}
              />
              <Form.Control.Feedback type="invalid">{errors.surname}</Form.Control.Feedback>

              <Form.Control
                placeholder='Email'
                onChange={(e) => setClientSignUpData({ ...clientSignUpData, email: e.target.value })}
                isInvalid={!!errors.email}
                style={styles.input}
              />
              <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>

              <Form.Control
                placeholder='Password'
                type="password"
                onChange={(e) => setClientSignUpData({ ...clientSignUpData, password: e.target.value })}
                isInvalid={!!errors.password}
                style={styles.input}
              />
              <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>

              <Form.Control
                placeholder='Confirm Password'
                type="password"
                onChange={(e) => setClientSignUpData({ ...clientSignUpData, confirmPassword: e.target.value })}
                isInvalid={!!errors.confirmPassword}
                style={styles.input}
              />
              <Form.Control.Feedback type="invalid">{errors.confirmPassword}</Form.Control.Feedback>

              <Button style={styles.button} onClick={handleClientSignUp}>
                Sign Up
              </Button>

              <div style={styles.link}>
                Already have an account? <a href="/auth" style={styles.linkText}>Let's Sign In</a>
              </div>

              <div style={styles.googleLogin}>
                <GoogleLogin onSuccess={googleSuccess} />
              </div>

              {alreadyClientExist && <Alert variant="danger" style={styles.error}>Email already exists</Alert>}
            </Form>
          </Card>
        </Card>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #0d47a1, #000000)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px'
  },
  outerCard: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCard: {
    padding: '40px',
    maxWidth: '500px',
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.2)',
    background: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
  },
  title: {
    fontSize: '24px',
    color: '#ffffff',
    textAlign: 'center',
    marginBottom: '30px',
    fontWeight: 'bold',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: '#ffffff',
    border: '1px solid rgba(255, 255, 255, 0.5)',
    borderRadius: '5px',
    padding: '10px',
  },
  button: {
    backgroundColor: '#0d47a1',
    border: 'none',
    padding: '10px',
    color: '#fff',
    borderRadius: '5px',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: '0.3s',
  },
  link: {
    textAlign: 'center',
    color: '#ffffff',
    marginTop: '10px',
  },
  linkText: {
    color: '#0d47a1',
    fontWeight: 'bold',
  },
  googleLogin: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  },
  error: {
    marginTop: '20px',
    textAlign: 'center',
  },
};

export default ClientSignUp;

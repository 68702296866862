import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function JapaneseCandleSticks() {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const [isPaidStatus, setIsPaidStatus] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '9.00', // Payment amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            await axios.patch('https://sell-skill.com/api/endpoints/paidJapaneseCandlestick');
            navigate('/japanese-candle-sticks-book');
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    addPayPalScript();
  }, []);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const storage = getStorage();
        const PdfRef = ref(storage, 'TradingEbooks/Japanese-Candlestick-Charting-Techniques.pdf');
        const url = await getDownloadURL(PdfRef);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF URL: ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPdfUrl();
  }, []);

  useEffect(() => {
    const isPaid = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidJapaneseCandlestickBook');
      setIsPaidStatus(response.data.isPaid);
    };
    isPaid();
  }, []);

  return (
    <Container className="mt-5">
      <Helmet>
        <title>Unlock the Secrets of Japanese Candlestick Charting Techniques</title>
        <meta
          name="description"
          content="Learn the art of Japanese Candlestick charting techniques with this powerful trading book by Steve Nison. Unlock profitable trading insights now!"
        />
        <meta name="keywords" content="Japanese Candlestick, trading, technical analysis, Steve Nison, trading strategies, candlestick charting techniques" />
      </Helmet>

      {
        isPaidStatus ? (
          <Card className="shadow-lg" style={{ backgroundColor: '#2c3e50', color: '#fff', borderRadius: '15px' }}>
            <Card.Header className="text-center" style={{ backgroundColor: '#1abc9c', borderRadius: '15px 15px 0 0' }}>
              <h2>Japanese Candlestick Charting Techniques</h2>
              <p>by Steve Nison</p>
            </Card.Header>
            <Card.Body className="text-center">
              {loading ? (
                <Spinner animation="border" variant="light" />
              ) : (
                <>
                  <p className="mb-4">
                    Master the secrets of successful trading with the power of Japanese Candlestick patterns. This book is your guide to uncovering hidden market trends, empowering your trading decisions.
                  </p>
                  <div className="mb-4">
                    {pdfUrl ? (
                      <iframe
                        src={pdfUrl}
                        width="100%"
                        height="500px"
                        style={{ border: 'none', borderRadius: '10px' }}
                        title="Japanese Candlestick Charting Techniques"
                      ></iframe>
                    ) : (
                      <p>Failed to load the PDF. Please try again later.</p>
                    )}
                  </div>
                  <Button
                    variant="success"
                    href={pdfUrl}
                    target="_blank"
                    style={{ borderRadius: '30px', padding: '10px 30px' }}
                  >
                    Download Your Book
                  </Button>
                </>
              )}
            </Card.Body>
          </Card>
        ) : (
          <div className="payment-section">
            <Card className="payment-card">
              <Card.Body>
                <h2 className="payment-title">Unlock Your Trading Potential</h2>
                <p className="payment-description">
                  Invest in yourself and gain full access to Steve Nison's <strong>Japanese Candlestick Charting Techniques</strong> book for only $9.00. Take your trading to the next level and start making smarter, more informed decisions in the market.
                </p>
                <div className="payment-buttons">
                  <div id="paypal-button-container" className="paypal-button"></div>
                </div>
              </Card.Body>
            </Card>
          </div>
        )
      }
    </Container>
  );
}

export default JapaneseCandleSticks;



import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CourseCard from './CourseCard';
import { Container, Row } from 'react-bootstrap';

const CourseList = () => {
  const [courses, setCourses] = useState([]); // Initialize courses as an empty array

  const fetchCourses = async () => {
    try {
      const tokenString = localStorage.getItem('providerToken');
      const tokenObject = JSON.parse(tokenString);
      let token = tokenObject?.token || tokenObject;

      const response = await axios.get('https://sell-skill.com/api/endpoints/getCoursesByProvider/:id', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Ensure response data is an array before setting it to state
      const courseData = Array.isArray(response.data) ? response.data : [];
      setCourses(courseData);
    } catch (error) {
      console.error('Error fetching courses:', error);
    }
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  // Add a check to ensure courses is an array before mapping over it
  if (!Array.isArray(courses)) {
    return <p>No courses available.</p>;
  }

  return (
    <Container>
      <Row>
        {courses.map((course) => (
          <CourseCard key={course._id} course={course} />
        ))}
      </Row>
    </Container>
  );
};

export default CourseList;

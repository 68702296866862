import React, { useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #E6F0FF; /* Light Blue background */
    color: #2F4F4F; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled(Card)`
  background-color: #FAFAFA; /* Off-White for the card background */
  color: #4169E1; /* Royal Blue for text */
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  margin-top: 50px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Card.Title)`
  text-align: center;
  font-size: 30px;
  color: #4169E1; /* Royal Blue for the title */
  margin-top: 20px;
`;

const FileButton = styled(Button)`
  width: 150px;
  margin: 20px auto;
  display: block;
  background-color: #FFD700; /* Golden Yellow for the button */
  border: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;

  &:hover {
    background-color: #4169E1; /* Royal Blue on hover */
    color: #FAFAFA; /* Off-White text on hover */
  }
`;

const ImagePreview = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 20px;
  margin-top: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #4169E1; /* Royal Blue border */
`;

const HiddenFileInput = styled.input`
  display: none;
`;

const Label = styled.label`
  display: block;
  margin-top: 10px;
  cursor: pointer;
  color: #2F4F4F; /* Dark Gray text */
  font-weight: bold;
  text-align: center;
`;

function Blog() {
  const navigate = useNavigate();
  const [intro, setIntro] = useState('');

  const handleAddBlog = async (file) => {
    const formData = new FormData();
    setIntro(file);
    formData.append('image', file);
    formData.append('name', 'image/png');
    await axios.post('https://sell-skill.com/api/endpoints/addBlog', formData);
  };

  const handleNextClick = async () => {
    navigate('/rate-per-hour');
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>
            <b>Intro video or you can do it later</b>
          </Title>
          <Card.Body className="text-center">
            <Label htmlFor="file-input">
              <FileButton as="span">
                Choose an image
              </FileButton>
            </Label>
            <HiddenFileInput
              id="file-input"
              type="file"
              name="image"
              onChange={(e) => handleAddBlog(e.target.files[0])}
            />
            {intro && <ImagePreview src={URL.createObjectURL(intro)} />}
            <Button 
              onClick={handleNextClick} 
              style={{ marginTop: '20px', width: '100%', backgroundColor: '#FFD700', border: 'none', fontSize: '16px', fontWeight: 'bold', borderRadius: '8px' }}
              onMouseOver={(e) => { e.target.style.backgroundColor = '#4169E1'; e.target.style.color = '#FAFAFA'; }}
              onMouseOut={(e) => { e.target.style.backgroundColor = '#FFD700'; e.target.style.color = '#4169E1'; }}
            >
              Next
            </Button>
          </Card.Body>
        </MainCard>
      </StyledContainer>
    </>
  );
}

export default Blog;

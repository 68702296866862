import React from 'react';
import { Helmet } from 'react-helmet';
import './PrivacyPolicy.css'; // You can add custom styles here if needed

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy & Policy | SellSkill</title>
        <meta name="description" content="SellSkill Privacy & Policy page for user data protection and terms." />
      </Helmet>
      <div className="privacy-policy-content">
        <h1 className="privacy-policy-title">Privacy & Policy</h1>
        <p className="privacy-policy-subtitle">
          Welcome to SellSkill! Your privacy is our priority.
        </p>
        
        <section>
          <h2>Data Collection</h2>
          <p>
            We collect various types of information, including personal information like your name, email, and contact details to provide you with the best service experience. Rest assured, your data will never be shared without your consent.
          </p>
        </section>

        <section>
          <h2>Cookies</h2>
          <p>
            Our website uses cookies to enhance your experience, personalize content, and analyze traffic. By using SellSkill, you consent to the use of cookies in accordance with our policy.
          </p>
        </section>

        <section>
          <h2>Third-Party Services</h2>
          <p>
            We may use third-party services like payment processors and analytics providers, who may have access to your data solely for business purposes. We ensure they meet strict data protection standards.
          </p>
        </section>

        <section>
          <h2>Your Rights</h2>
          <p>
            You have the right to access, update, or delete your personal information. If you have any concerns or requests regarding your data, feel free to contact us at privacy@sellskill.com.
          </p>
        </section>

        <section>
          <h2>Updates</h2>
          <p>
            Our Privacy Policy may be updated periodically to reflect changes in our practices or for legal reasons. Please review this page regularly for any updates.
          </p>
        </section>

        <button className="cta-button">Contact Us</button>
      </div>
    </div>
  );
};

export default PrivacyPolicy;

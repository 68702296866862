import React from 'react';
import styled from "styled-components";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container, Row, Col } from 'react-bootstrap';

function DefiningSlider() {
  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <GradientBackground>
      <Container>
        <Row className="justify-content-center">
          <Col lg={10} md={12}>
            <ImgSliderContainer>
              <Carousel {...settings}>
                <Wrap>
                  <img src="/clientSlider/HowToMakeMoney.jpg" alt="Slide 1" />
                </Wrap>
                <Wrap>
                  <img src="/clientSlider/MaximizeYourOnlineEarnings.jpg" alt="Slide 2" />
                </Wrap>
                <Wrap>
                  <img src="/clientSlider/DigitalMarketing.jpg" alt="Slide 3" />
                </Wrap>
                <Wrap>
                  <img src="/clientSlider/BestTradingStratrgegies.jpg" alt="Slide 4" />
                </Wrap>
              </Carousel>
            </ImgSliderContainer>
          </Col>
        </Row>
      </Container>
    </GradientBackground>
  );
}

export default DefiningSlider;

const GradientBackground = styled.div`
  background: linear-gradient(135deg, #004085, #000);
  padding: 50px 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgSliderContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
  box-sizing: border-box;
`;

const Carousel = styled(Slider)`
  .slick-prev:before,
  .slick-next:before {
    color: #fff;
  }

  ul li button {
    &:before {
      font-size: 12px;
      color: #fff;
    }
  }

  li.slick-active button:before {
    color: #d1ecf1;
  }

  .slick-list {
    overflow: hidden;
  }

  .slick-prev {
    left: 10px;
    z-index: 1;
  }

  .slick-next {
    right: 10px;
    z-index: 1;
  }
`;

const Wrap = styled.div`
  cursor: pointer;
  position: relative;

  img {
    border: 4px solid transparent;
    border-radius: 10px;
    width: 100%;
    height: auto;
    max-height: 500px;
    object-fit: contain;
    box-shadow: 0 26px 30px -10px rgba(0, 0, 0, 0.69), 
                0 16px 10px -10px rgba(0, 0, 0, 0.73);
    transition: border 300ms, transform 300ms;

    &:hover {
      border: 4px solid rgba(249, 249, 249, 0.8);
      transform: scale(1.05);
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { storage } from './firebase'; 
import { getStorage, ref, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";


function CourseUploadForm() {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [videoUrls, setVideoUrls] = useState([])
  const navigate = useNavigate();

  // Handle course creation
  const handleCourseCreation = async (e) => {
    e.preventDefault();
    const courseData = { title, description, price, providerId: '12345' };

    try {
      const response = await axios.post('https://sell-skill.com/api/endpoints/createCourse', courseData);
      if (response.status === 201) {
        setCourseId(response.data.courseId);
        alert('Course created successfully!');
      }
    } catch (error) {
      console.error('Error creating course:', error);
      alert('Failed to create course.');
    }
  };

  // Handle thumbnail upload
  const handleThumbnailUpload = async (file) => {
    if (!courseId) {
      alert('Please create the course first!');
      return;
    }

    const formData = new FormData();
    formData.append('thumbnail', file);
    formData.append('courseId', courseId);

    try {
      const response = await axios.post('https://sell-skill.com/api/endpoints/uploadThumbnail', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      if (response.status === 200) {
        alert('Thumbnail uploaded successfully!');
        setThumbnail(file);
      }
    } catch (error) {
      console.error('Error uploading thumbnail:', error);
      alert('Failed to upload thumbnail.');
    }
  };

  // Handle large video upload
           
  const handleVideosUpload = (files) => {
    if (!files || files.length === 0) return;
      
    Array.from(files).forEach((file) => {
      const storageRef = ref(storage, `videos/${file.name}`); // Create a reference to the file in Firebase Storage
      const uploadTask = uploadBytesResumable(storageRef, file);
      
      // Track upload progress
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress}% done`);
        },
        (error) => {
          // Handle upload errors
          console.error("Upload failed:", error);
        },
        () => {
          // On successful upload, get the download URL
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            console.log("File available at:", downloadURL);
            // You can store the download URL in your backend or do further processing
          });
        }
      );
    });
  };
  
  const fetchVideoUrls = async () => {
    const videoListRef = ref(storage, 'videos/'); // Reference to the 'videos' folder
    try {
      const response = await listAll(videoListRef); // List all items in the 'videos' folder
      const urls = await Promise.all(
        response.items.map((itemRef) => getDownloadURL(itemRef)) // Get download URLs for each video
      );
      setVideoUrls(urls); // Store the URLs in state
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  // Fetch videos on component mount
  useEffect(() => {
    fetchVideoUrls();
  }, []);

  return (
    <div style={styles.container}>
      <Container>
        <Card style={styles.outerCard}>
          <Card.Body style={styles.innerCard}>
            <Card.Title style={styles.title}>
              <b>Upload a New Course</b>
            </Card.Title>

            <form onSubmit={handleCourseCreation}>
              <input
                type="text"
                placeholder="Course Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
                style={styles.input}
              />
              <input
                type="text"
                placeholder="Course Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                required
                style={styles.input}
              />
              <input
                type="number"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                required
                style={styles.input}
              />
              <Button type="submit" style={styles.button}>
                Create Course
              </Button>
            </form>

            <div style={styles.uploadContainer}>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleThumbnailUpload(e.target.files[0])}
                style={styles.fileInput}
                disabled={!courseId}
              />
            </div>

             <div style={styles.uploadContainer}>
              <input
                type="file"
                accept="video/*"
                onChange={(e) => handleVideosUpload(e.target.files)}
                style={styles.fileInput}
                disabled={!courseId}
              />
            </div> 

            {thumbnail && (
              <img
                src={URL.createObjectURL(thumbnail)}
                style={styles.previewImage}
                alt="Thumbnail Preview"
              />
            )}
            {videoPreviews.length > 0 && (
               <div style={styles.videoPreviewContainer}>
                 {videoPreviews.map((previewUrl, index) => (
                   <video
                     key={index}
                     src={previewUrl}
                     controls
                     style={styles.previewVideo}
                   />
                 ))}
              </div>
            )
           }

                     {videoUrls.length > 0 && (
              <div style={styles.videoPreviewContainer}>
                <h4>Uploaded Videos:</h4>
                {videoUrls.map((url, index) => (
                  <video key={index} src={url} controls style={styles.previewVideo} />
                ))}
              </div>
            )}

            <div style={styles.buttonContainer}>
              <Button onClick={() => navigate('/next-page')} style={styles.button}>
                Next
              </Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}

const styles = {
  container: {
    height: '100vh',
    backgroundColor: '#002f6c',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  outerCard: {
    backgroundColor: '#F5F5F5',
    color: '#008080',
    borderRadius: '20px',
    padding: '20px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    width: '100%',
    maxWidth: '500px',
    margin: '0 auto',
  },
  innerCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '15px',
    padding: '20px',
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#002f6c',
  },
  uploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    flexDirection: 'column',
  },
  fileInput: {
    marginBottom: '10px',
    width: '100%',
    maxWidth: '350px',
    margin: 'auto',
  },
  input: {
    marginBottom: '10px',
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  previewImage: {
    width: '140px',
    height: '140px',
    borderRadius: '70px',
    margin: '20px auto',
    border: '2px solid #008080',
  },
  videoPreviewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  previewVideo: {
    width: '100%',
    maxWidth: '350px',
    marginBottom: '10px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '120px',
    backgroundColor: '#008080',
    borderColor: '#008080',
    color: '#ffffff',
  },
};

export default CourseUploadForm;

import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Courses.css'; // Custom CSS for additional styling
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import './PayPalButton.css';

function PSCourse() {
  const [activeModule, setActiveModule] = useState(null);
  const navigate = useNavigate(); 
  const [isPaidStatus, setIsPaidStatus] = useState(false);

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '9.00', // Replace with your amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            await axios.patch('https://sell-skill.com/api/endpoints/paidPSCourse');
            navigate('/facebook-ads-course');
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    // Load Stripe Script
    const loadStripeScript = async () => {
      const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
      const button = document.getElementById('stripe-button');
      button.addEventListener('click', async () => {
        const response = await axios.post('https://sell-skill.com/api/stripe/create-checkout-session', {
          amount: 500, // Amount in cents (500 cents = $5.00)
          currency: 'usd',
          name: 'Adobe photoshop edititng Course',
        });
        const { sessionId } = response.data;
        stripe.redirectToCheckout({ sessionId });
      });
    };

    addPayPalScript();
    loadStripeScript();
  }, []);

  // Function to toggle module descriptions
  const toggleModule = (module) => {
    setActiveModule(activeModule === module ? null : module);
  };

  // Function to navigate to the module route when the card is clicked
  const navigateToModule = (module) => {
    navigate(`/PS-course/week-${module}`);
  };

  useEffect(() => {
    const checkIsPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidPSCourse');
      setIsPaidStatus(response.data.isPaid);
    }
    checkIsPaid();
  }, []);

  return (
    <Container fluid className="crypto-course-container text-center">
      {isPaidStatus ? (
        <>
          <h1 className="course-title mb-4">Adobe Photoshop editing Course</h1>
          <Row className="justify-content-center">
            {[1, 2, 3].map((module) => (
              <Col key={module} xs={12} md={6} lg={6} className="mb-3">
                <Card className="module-card">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span
                      className="module-title"
                      onClick={() => navigateToModule(module)}
                      style={{ cursor: 'pointer' }}
                    >
                      Module {module}
                    </span>
                    <Button
                      variant="link"
                      className="module-toggle-btn"
                      onClick={() => toggleModule(module)}
                    >
                      {activeModule === module ? '▲' : '▼'}
                    </Button>
                  </Card.Header>
                  {activeModule === module && (
                    <Card.Body className="module-description">
                      <p>
                        This is the description for Module {module}. It covers various topics related to Adobe Photoshop editing.
                      </p>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div className="payment-section">
          <Card className="payment-card">
            <Card.Body>
              <h2 className="payment-title">Unlock the Course</h2>
              <p className="payment-description">Get full access to the Adobephotoshop editing Course by making a one-time payment of $7.00.</p>
              <div className="payment-buttons">
                <div id="paypal-button-container" className="paypal-button"></div>
                {/* <button id="stripe-button" className="stripe-button">Pay with Stripe</button> */}
              </div>
            </Card.Body>
          </Card>
        </div>
      )}
    </Container>
  );
}

export default PSCourse;
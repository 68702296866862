import React from 'react';
import { Helmet } from 'react-helmet';
import './TermsConditions.css'; // You can add custom styles here if needed

const TermsConditions = () => {
  return (
    <div className="terms-conditions-container">
      <Helmet>
        <title>Terms & Conditions | SellSkill</title>
        <meta name="description" content="SellSkill Terms & Conditions page outlining user rights and obligations." />
      </Helmet>
      <div className="terms-conditions-content">
        <h1 className="terms-conditions-title">Terms & Conditions</h1>
        <p className="terms-conditions-subtitle">
          Please read these terms and conditions carefully before using SellSkill.
        </p>

        <section>
          <h2>Introduction</h2>
          <p>
            By accessing and using the SellSkill platform, you agree to comply with the following terms and conditions. If you do not agree to these terms, please discontinue using our services immediately.
          </p>
        </section>

        <section>
          <h2>Use of the Platform</h2>
          <p>
            SellSkill provides an online learning platform where clients can post projects, and providers can offer their services. You agree to use the platform for lawful purposes only and to respect the rights of other users.
          </p>
        </section>

        <section>
          <h2>Account Responsibility</h2>
          <p>
            You are responsible for maintaining the confidentiality of your account credentials. Any activity conducted under your account is your responsibility, and you must notify us immediately of any unauthorized use.
          </p>
        </section>

        <section>
          <h2>Payments and Refunds</h2>
          <p>
            All payments made on SellSkill are subject to our payment and refund policies. Please review these policies before making any purchases or offering services.
          </p>
        </section>

        <section>
          <h2>Prohibited Activities</h2>
          <p>
            You agree not to engage in any activity that disrupts the functionality of the platform or violates the rights of other users. Prohibited activities include, but are not limited to, spamming, hacking, or impersonating others.
          </p>
        </section>

        <section>
          <h2>Termination</h2>
          <p>
            We reserve the right to terminate your access to SellSkill at our discretion, without notice, if you violate these terms or engage in any activity deemed harmful to the platform or its users.
          </p>
        </section>

        <section>
          <h2>Changes to Terms</h2>
          <p>
            SellSkill reserves the right to modify these Terms & Conditions at any time. Please review this page regularly to stay informed of any changes.
          </p>
        </section>

        <button className="cta-button">Contact Us</button>
      </div>
    </div>
  );
};

export default TermsConditions;

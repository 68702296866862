//To show resources inside a lesson




import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function InsideLesson() {
  const [resources, setResources] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const worldName = useSelector((state) => state.allow.worldName);
  const levelNumber = useSelector((state) => state.allow.levelNumber);
  const lessonNumber = useSelector((state) => state.allow.lessonNumber);
  console.log('worldName ==========>> ',worldName)
  console.log('levelNumber =======>> ',levelNumber)
  console.log('lessonNumber =======>> ',lessonNumber)
  useEffect(() => {
    if (worldName && levelNumber && lessonNumber) {
      const fetchResources = async () => {
        try {
          const response = await axios.get(`https://sell-skill.com/api/endpoints/getResources`, {
            params: { worldName, levelNumber, lessonNumber }
          });
          setResources(response.data);
        } catch (error) {
          console.error("Error fetching resources:", error);
        }
      };
  
      fetchResources();
    }
  }, [worldName, levelNumber, lessonNumber]);
  
  const renderResource = (resource, index) => {
    switch (resource.type) {
      case 'video':
        return (
          <div key={index} className="resource-card">
            <h3>Video</h3>
            <video controls src={resource.url} width="100%" />
          </div>
        );
      case 'image':
        return (
          <div key={index} className="resource-card">
            <h3>Image</h3>
            <img src={resource.url} alt="Resource" style={{ width: '100%' }} />
          </div>
        );
      case 'pdf':
        return (
          <div key={index} className="resource-card">
            <h3>PDF</h3>
            <a href={resource.url} target="_blank" rel="noopener noreferrer">
              View PDF
            </a>
          </div>
        );
      case 'text':
        return (
          <div key={index} className="resource-card">
            <h3>Text</h3>
            <p>{resource.content}</p>
          </div>
        );
      case 'quiz':
        return (
          <div key={index} className="resource-card">
            <h3>Quiz</h3>
            <div>
              <p>{resource.content.question}</p>
              <ul>
                {resource.content.choices.map((choice, i) => (
                  <li key={i}>{choice}</li>
                ))}
              </ul>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <h1>Lesson Resources</h1>
      <div className="resources-container">
        {resources.map((resource, index) => renderResource(resource, index))}
      </div>
    </div>
  );
}

export default InsideLesson;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

function Overview() {
    const postData = useSelector((state) => state.allow.overview);
    const [permission, setPermission] = useState(false);
    const tokenString = localStorage.getItem('clientToken');
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject.token || tokenObject;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPermission = async () => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPermission(response.data.permission);
        };
        fetchPermission();
    }, [token]);

    const handlePost = async () => {
        navigate('/client');
        await axios.post('https://sell-skill.com/api/endpoints/insertPost', postData);
    };

    const navigateSignUpIn = () => {
        navigate('/auth');
    };

    return (
        <div style={styles.container}>
            {permission ? (
                <Container>
                    <Card style={styles.outerCard}>
                        <Card.Body>
                            <Card.Title style={styles.title}>Overview</Card.Title>

                            <div style={styles.grid}>
                                {['Term', 'Title', 'Skills', 'Estimate Scope', 'Experience Level', 'Budget'].map((field, index) => (
                                    <Card key={index} style={styles.infoCard}>
                                        <Card.Title style={styles.infoTitle}>{field}</Card.Title>
                                        <div style={styles.infoText}>{postData[field.toLowerCase().replace(' ', '')]}</div>
                                    </Card>
                                ))}
                            </div>

                            <Card style={styles.descriptionCard}>
                                <Card.Title style={styles.infoTitle}>Description</Card.Title>
                                <div style={styles.infoText}>{postData.description}</div>
                            </Card>

                            <Button style={styles.postButton} onClick={handlePost}>Post</Button>
                        </Card.Body>
                    </Card>
                </Container>
            ) : (
                <div style={styles.signInPrompt}>
                    <Card style={styles.promptCard}>
                        <Button onClick={navigateSignUpIn} style={styles.signInButton}>Sign Up/In</Button>
                    </Card>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        height: '100vh',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Gradient background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    outerCard: {
        borderRadius: '30px',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Outer card gradient
        color: '#fff',
        width: '100%',
        maxWidth: '800px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
        padding: '30px',
    },
    title: {
        textAlign: 'center',
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#00c6ff', // Accent color for title
        marginBottom: '30px',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        gap: '20px',
        marginBottom: '30px',
    },
    infoCard: {
        borderRadius: '20px',
        background: '#0f2027', // Darker background for info cards
        color: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '20px',
        transition: 'transform 0.2s ease',
    },
    infoCardHover: {
        transform: 'scale(1.05)',
        boxShadow: '0 8px 16px rgba(0, 198, 255, 0.3)',
    },
    infoTitle: {
        color: '#00c6ff', // Accent color for info titles
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '10px',
    },
    infoText: {
        color: '#00c6ff',
        fontSize: '16px',
    },
    descriptionCard: {
        borderRadius: '20px',
        background: '#0f2027',
        color: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '20px',
        width: '100%',
        marginTop: '20px',
    },
    postButton: {
        width: '100%',
        height: '50px',
        backgroundColor: '#00c6ff', // Post button color
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        fontWeight: 'bold',
        fontSize: '16px',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
        padding: '12px',
        marginTop: '30px',
    },
    signInPrompt: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Background for sign-in prompt
    },
    promptCard: {
        padding: '30px',
        backgroundColor: '#0f2027',
        color: '#fff',
        borderRadius: '20px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
    },
    signInButton: {
        background: '#00c6ff',
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        padding: '10px 20px',
        fontWeight: 'bold',
        fontSize: '18px',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
    },
};

export default Overview;

import React, { useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #E6F0FF; /* Light Blue background */
    color: #2F4F4F; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  height: 100vh;
  background-color: #E6F0FF; /* Light Blue background */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OuterCard = styled(Card)`
  background-color: #FAFAFA; /* Off-White for the card background */
  color: #4169E1; /* Royal Blue text */
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 800px;
`;

const InnerCard = styled(Card)`
  background-color: #4169E1; /* Royal Blue for inner card */
  color: #FAFAFA; /* Off-White text */
  border-radius: 20px;
  padding: 20px;
`;

const Title = styled(Card.Title)`
  font-size: 30px;
  text-align: center;
  margin-bottom: 20px;
  color: #FFD700; /* Golden Yellow for the title */
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  width: 150px;
  margin: 5px;
  background-color: ${(props) => (props.selected ? '#FFD700' : '#4169E1')}; /* Golden Yellow for selected, Royal Blue otherwise */
  border-color: #4169E1; /* Royal Blue for border */
  color: ${(props) => (props.selected ? '#4169E1' : '#FAFAFA')}; /* Royal Blue text for selected, Off-White otherwise */
  border-radius: 8px;

  &:hover {
    background-color: #FFD700; /* Golden Yellow on hover */
    color: #4169E1; /* Royal Blue text on hover */
  }
`;

const Input = styled(Form.Control)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #2F4F4F; /* Dark Gray for input borders */
`;

const NextButton = styled(Button)`
  display: block;
  width: 100%;
  background-color: #FFD700; /* Golden Yellow for the button */
  border-color: #FFD700; /* Golden Yellow for border */
  color: #4169E1; /* Royal Blue text */
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;

  &:hover {
    background-color: #4169E1; /* Royal Blue on hover */
    color: #FAFAFA; /* Off-White text on hover */
  }
`;

function TypeOfWork() {
  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [others, setOthers] = useState('');

  const handleNextClick = async () => {
    navigate("/letter");
    console.log('categories ===> ' + category);
    await axios.post('https://sell-skill.com/api/endpoints/category', category);
  };

  const handleCategoryClick = (cat) => {
    setCategory((prevCategories) => {
      if (prevCategories.includes(cat)) {
        return prevCategories.filter((c) => c !== cat);
      } else {
        return [...prevCategories, cat];
      }
    });
  };

  const handleAddOther = () => {
    if (others.trim() && !category.includes(others)) {
      setCategory((prevCategories) => [...prevCategories, others]);
      setOthers('');
    }
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <OuterCard>
          <InnerCard>
            <Title>
              <b>Which category are you interested in?</b>
            </Title>
            <ButtonsContainer>
              {['Affiliate marketing', 'Digital marketing', 'Coding', 'AI', 'Medicine', 'Health & fitness', 'Legal & financial advice', 'Languages', 'Hobbies & skills', 'Cryptocurrency', 'Home improvement & DIY', 'Creative services', 'Education & tutoring', 'Entertainment & Leisure', 'Dropshipping', 'Pet services'].map((cat) => (
                <StyledButton
                  key={cat}
                  selected={category.includes(cat)}
                  onClick={() => handleCategoryClick(cat)}
                >
                  {cat}
                </StyledButton>
              ))}
            </ButtonsContainer>
            <Input
              type="text"
              value={others}
              onChange={(e) => setOthers(e.target.value)}
              placeholder="Enter other field ..."
            />
            <StyledButton onClick={handleAddOther}>Add Other</StyledButton>
            <NextButton onClick={handleNextClick}>Next</NextButton>
          </InnerCard>
        </OuterCard>
      </StyledContainer>
    </>
  );
}

export default TypeOfWork;

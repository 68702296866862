  import React, { useState } from 'react';
  import { Helmet } from 'react-helmet';
  import { Button, Alert, Card, Form, Container, Spinner } from 'react-bootstrap';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import axios from 'axios';
  import { GoogleLogin } from '@react-oauth/google';
  import { useNavigate } from 'react-router-dom';
  import { useDispatch } from 'react-redux';
  import { changeAuth, setProviderId, setProviderOrClientId } from '../reducers/reducers';

  function SignUpLogin() {
    const dispatch = useDispatch();
    const [notAllowed, setNotAllowed] = useState(false);
    const [signInData, setSignInData] = useState({ email: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    let responseProvider;
    let responseClient;
    let responseProviderId;
    let clientToken;
    let providerToken;
    const [clientResult, setClientResult] = useState(true);
    const [providerResult, setProviderResult] = useState(true);

    const googleSuccess = async (response) => {
      const { credential } = response;
      const payload = JSON.parse(atob(credential.split('.')[1]));
      const { email } = payload;

      setIsLoading(true);
      try {
        responseClient = await axios.post('https://sell-skill.com/api/endpoints/clientSignIn', { email, password: '' });
        clientToken = responseClient.data;
        localStorage.setItem('clientToken', JSON.stringify(clientToken));
        responseClient && navigate('/Client');
      } catch (error) {
        setClientResult(false);
        try {
          responseProvider = await axios.post('https://sell-skill.com/api/endpoints/providerSignIn', { email, password: '' });
          providerToken = responseProvider.data;
          localStorage.setItem('providerToken', JSON.stringify(providerToken));
          responseProvider && navigate('/provider');
        } catch (error) {
          setProviderResult(false);
        }
      }
      setIsLoading(false);
    };

    const handleClick = async () => {
      setIsLoading(true);
      try {
        try {
          responseClient = await axios.post('https://sell-skill.com/api/endpoints/clientSignIn', signInData);
          clientToken = responseClient.data;
          localStorage.setItem('clientToken', JSON.stringify(clientToken));
        } catch (error) {
          setClientResult(false);
          try {
            responseProvider = await axios.post('https://sell-skill.com/api/endpoints/providerSignIn', signInData);
            providerToken = responseProvider.data;
            localStorage.setItem('providerToken', JSON.stringify(providerToken));
          } catch (secondError) {
            setProviderResult(false);
            console.error('Both sign-in attempts failed:', secondError);
            return;
          }
        }

        localStorage.setItem('authenticated', 'true');
        dispatch(changeAuth(true));

        responseClient && navigate('/Client');
        responseProvider && navigate('/provider');
        if (responseClient || responseProvider) {
          const responseProviderOrClientId = await axios.get('https://sell-skill.com/api/endpoints/providerOrClientId');
          dispatch(setProviderOrClientId(responseProviderOrClientId.data));
        }
      } catch (error) {
        console.error('Sign-in failed:', error);
        setNotAllowed(true);
        localStorage.setItem('authenticated', 'false');
        dispatch(changeAuth(false));
      }
      setIsLoading(false);
    };

    return (
      <>
        <Helmet>
          <title>Sell-Skill | Sign Up or Login</title>
          <meta name="description" content="Sell-Skill is a platform that connects clients with providers. Sign up to get help or share your knowledge." />
          <meta name="keywords" content="Sell-Skill, client-provider platform, knowledge sharing, login, sign-up" />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <div style={{ minHeight: '100vh', backgroundColor: '#002f6c', padding: '2rem' }}>
          <Container className="d-flex flex-column justify-content-center align-items-center" style={{ minHeight: '100%' }}>
            <Card style={{ width: '100%', maxWidth: '500px', backgroundColor: '#fff', borderRadius: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <Card.Body>
                <Card.Title className="text-center mb-4" style={{ fontSize: '24px', color: '#002f6c' }}>
                  <b>Login to Your Account</b>
                </Card.Title>
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control 
                      type="email" 
                      placeholder="Email or username" 
                      onChange={(e) => setSignInData({ ...signInData, email: e.target.value })} 
                      style={{ borderColor: '#008080' }} 
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control 
                      type="password" 
                      placeholder="Password" 
                      onChange={(e) => setSignInData({ ...signInData, password: e.target.value })} 
                      style={{ borderColor: '#008080' }} 
                    />
                  </Form.Group>
                  <Button 
                    variant="primary" 
                    type="button" 
                    onClick={handleClick} 
                    className="w-100" 
                    disabled={isLoading}
                    style={{ backgroundColor: '#008080', borderColor: '#008080' }}
                  >
                    {isLoading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Log in'}
                  </Button>
                </Form>
                <div className="text-center mt-3">
                  <a href="/client-sign-up" style={{ color: '#002f6c', textDecoration: 'underline' }}>
                    Don't have an account? Sign up here
                  </a>
                </div>
                <div className="text-center mt-3">
                  <GoogleLogin onSuccess={googleSuccess} />
                </div>
                {notAllowed && (
                  <Alert variant="danger" className="mt-3">
                    Not allowed, please make sure you have an account
                  </Alert>
                )}
                {!clientResult && !providerResult && (
                  <Alert variant="danger" className="mt-3">
                    Email or password might be incorrect
                  </Alert>
                )}
              </Card.Body>
            </Card>
          </Container>
        </div>
      </>
    );
  }

  export default SignUpLogin;

import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import ReactPlayer from 'react-player'; // You'll need to install react-player: npm install react-player
import './DMAITrailer.css'; // Create a CSS file for styling
import { useNavigate } from 'react-router-dom';

function DMAITrailer() {
  const [videoUrl, setVideoUrl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const storage = getStorage();
        const videoRef = ref(storage, '/DigitalMarketingAI/Week1/1.mp4'); // Update video path for Digital Marketing with AI course
        const url = await getDownloadURL(videoRef);
        setVideoUrl(url);
      } catch (error) {
        console.error('Error fetching video URL: ', error);
      }
    };
    fetchVideoUrl();
  }, []);

  return (
    <div className="trailer-container">
      <div className="trailer-content">
        <h1 className="trailer-title">Master Digital Marketing with AI!</h1>
        <p className="trailer-description">
          Unlock the true potential of your marketing campaigns by combining the power of AI with cutting-edge digital marketing strategies. Dive into our exclusive course and learn how to elevate your business to new heights using automation, predictive analytics, and more. Watch the trailer to get a glimpse of how AI can revolutionize your marketing approach!
        </p>
        {videoUrl ? (
          <ReactPlayer
            url={videoUrl}
            controls={true}
            playing={true}
            width="100%"
            height="500px"
            className="video-player"
          />
        ) : (
          <p>Loading video...</p>
        )}
        <button className="cta-button"
          onClick={() => navigate('/DigitalMarketingAI-course')}
        >
          Enroll Now and Transform Your Marketing!
        </button>
      </div>
    </div>
  );
}

export default DMAITrailer;

import React from 'react'

import ImgSlider from './ImgSlider'
import Footer from '../../Footer/Footer'
import { Button, Card, Container, Row, Col } from 'react-bootstrap'
import './MainHome.css'
import TopBar from './TopBar'

function MainHome() {
  return (
    <div className="main-home">
      <TopBar />
      <ImgSlider />
      
      <Container className="text-center mt-5">
        <div className="pumping-image" title="Enter web developers planet">
          <img 
            src="/path/to/your/image.jpg" 
            alt="Developers Community" 
            className="animated-pumping-image"
          />
        </div>
        
        <Button variant="primary" className="mt-3 mb-5">Browse Courses</Button>
        
        <h3 className="section-title">Courses and Resources</h3>
        <Row className="courses-list">
          {Array.from({ length: 10 }).map((_, idx) => (
            <Col key={idx} sm={6} md={4} lg={3} className="course-card">
              <Card className="course">
                <Card.Img 
                  variant="top" 
                  src="/path/to/course-image.jpg" 
                  className="course-image"
                />
                <Card.Body className="text-center">
                  <Button className="course-button">View Course</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>

        <p className="ad-text">Ad for developers world</p>
      </Container>
      
      <Footer />
    </div>
  )
}

export default MainHome

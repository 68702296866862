import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Modules.css'; // Custom CSS for additional styling
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

function YTWeek1() {
  const navigate = useNavigate();
  const [isPaidStatus, setIsPaidStatus] = useState(false)
  // Array of video titles (you can replace these with actual video titles)
  const videos = [
    { title: '1_ Introduction to Adobe Photoshop Beginner to Advance', id: 1 },
    { title: '2_ Lesson 1 - Workspace, Documents Interface', id: 2 },
    { title: '3_  Lesson 2 - Layers, Layer masks Blending Modes', id: 3 },
    // { title: '4_ Easy Facebook Page Setup', id: 4},
    // { title: '5_ Easy Facebook Ads Manager Setup', id: 5 },
    // { title: '6_ Fun Facebook Ads Manager Tour', id: 6},
    // { title: '7_ Your Movie Studio - Easy Ad Campaign Structure', id: 7 },
    // { title: '8_ 4 Step Customer Pathway', id: 8},
    // { title: '9_ The Power of the Facebook Pixel', id: 9 },
  ];

  // Navigate to a video detail page when a button is clicked
  const goToVideo = (id) => {
    navigate(`/YT-course/week-1/video-${id}`);
  };

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '13.00', // Replace with your amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            await axios.patch('https://sell-skill.com/api/endpoints/paidYTCourse');
            navigate('/facebook-ads-course');
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    // Load Stripe Script
    const loadStripeScript = async () => {
      const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
      const button = document.getElementById('stripe-button');
      button.addEventListener('click', async () => {
        const response = await axios.post('https://sell-skill.com/api/stripe/create-checkout-session', {
          amount: 1300, // Amount in cents (500 cents = $5.00)
          currency: 'usd',
          name: 'youtube Course',
        });
        const { sessionId } = response.data;
        stripe.redirectToCheckout({ sessionId });
      });
    };

    addPayPalScript();
    loadStripeScript();
  }, []);
  useEffect(() => {
    const checkIsPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidYTCourse');
      setIsPaidStatus(response.data.isPaid);
    }
    checkIsPaid();
  }, []);
  return (
    <Container fluid className="module1-home-container text-center">
        {
            isPaidStatus ? (
                <>
                <h1 className="page-title mb-4">Crypto Module One Videos</h1>
      <Row className="justify-content-center">
        {videos.map((video) => (
          <Col key={video.id} xs={12} md={6} lg={6} className="mb-3">
            <Card className="video-card">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="video-title">{video.title}</span>
                <Button
                  variant="primary"
                  className="video-btn"
                  onClick={() => goToVideo(video.id)}
                >
                  Watch
                </Button>
              </Card.Header>
            </Card>
          </Col>
        ))}
      </Row></>
            ) : (
                <div className="payment-section">
                  <Card className="payment-card">
                    <Card.Body>
                      <h2 className="payment-title">Unlock the Course</h2>
                      <p className="payment-description">Get full access to the Adobephotoshop editing Course by making a one-time payment of $7.00.</p>
                      <div className="payment-buttons">
                        <div id="paypal-button-container" className="paypal-button"></div>
                        {/* <button id="stripe-button" className="stripe-button">Pay with Stripe</button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </div>)
        }
     
    </Container>
  );
}

export default YTWeek1;
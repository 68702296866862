import React from 'react';

const InstructorExam = ({ level }) => {
  // Define payment structure based on level
  const paymentStructure = {
    0: 30,
    1: 40,
    2: 50,
    // Add more levels as needed
  };

  const getPaymentAmount = (level) => {
    return paymentStructure[level] || "contact admin";
  };

  return (
    <div style={styles.container}>
      <h2>Exam Instructions</h2>
      <p>
        You are allowed to take the exam only <strong>two times</strong>. The exam will be conducted face-to-face over a video call.
      </p>
      <p>
        If you fail both attempts, you will need to pay again to retake the exam.
      </p>
      <p>
        <strong>Payment for Level {level}:</strong> ${getPaymentAmount(level)} per attempt if you need to re-sit the exam.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '500px',
    margin: 'auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
};

export default InstructorExam;

import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';

function NewMoneyLandingPage() {
  const [videoUrl1, setVideoUrl1] = useState('')
  const [videoUrl2, setVideoUrl2] = useState('')
  const [videoUrl3, setVideoUrl3] = useState('')
  const [videoUrl4, setVideoUrl4] = useState('')
  const [videoUrl5, setVideoUrl5] = useState('')
  const [videoUrl6, setVideoUrl6] = useState('')
    const navigate = useNavigate()
    useEffect(() => {
      const fetchVideoUrl = async () => {
          try {
              const storage = getStorage();
              const videoRef1 = ref(storage, '/NewMoneyUgc/IMG_0539.MP4');
              const url1 = await getDownloadURL(videoRef1);
              setVideoUrl1(url1);

          } catch (error) {
              console.error('Error fetching video URL: ', error);
          }
      };
      fetchVideoUrl();
  }, []);

  useEffect(() => {
    const fetchVideoUrl = async () => {
        try {
            const storage = getStorage();
            const videoRef2 = ref(storage, '/NewMoneyUgc/IMG_8761.MP4');
            const url2 = await getDownloadURL(videoRef2);
            setVideoUrl2(url2);
          

        } catch (error) {
            console.error('Error fetching video URL: ', error);
        }
    };
    fetchVideoUrl();
}, []);

useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef3 = ref(storage, '/NewMoneyUgc/IMG_8763.MP4');
          const url3 = await getDownloadURL(videoRef3);
          setVideoUrl3(url3);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);


useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef4 = ref(storage, '/NewMoneyUgc/IMG_9455.MP4');
          const url4 = await getDownloadURL(videoRef4);
          setVideoUrl4(url4);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);
useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef5 = ref(storage, '/NewMoneyUgc/IMG_9745.MP4');
          const url5 = await getDownloadURL(videoRef5);
          setVideoUrl5(url5);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);
useEffect(() => {
  const fetchVideoUrl = async () => {
      try {
          const storage = getStorage();
          const videoRef6 = ref(storage, '/NewMoneyUgc/4.MP4');
          const url6 = await getDownloadURL(videoRef6);
          setVideoUrl6(url6);
        

      } catch (error) {
          console.error('Error fetching video URL: ', error);
      }
  };
  fetchVideoUrl();
}, []);


  return (
    <div>
      {/* Motivational Video Section */}
      <div className="video-container text-center bg-dark">
        <video className="w-100" autoPlay loop muted>
          <source src="your-motivational-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="video-overlay text-white d-flex flex-column align-items-center justify-content-center">
          <h1 className="display-4 fw-bold mb-3">Welcome to the New Money World</h1>
          <p className="lead mb-4">Learn how to make money online and unlock your financial freedom</p>
          <Button variant="warning" size="lg" className="px-5 py-3" onClick={() => navigate('/new-money')}>Join the Revolution</Button>
        </div>
      </div>

      {/* Student Reactions Section */}
      <Container className="my-5">
        <h2 className="text-center mb-4">What Our Students Say</h2>
        <div className="d-flex justify-content-center">
          <div className="reaction-square bg-light p-4 shadow-lg">
            <Row>
              {/* Video 1 */}
              <Col md={4} className="mb-4">
                <Card className="video-card border-0">
                  <Card.Body className="p-0">
                  {videoUrl1 ? (
                        <video className="course-video" controls>
                            <source src={videoUrl1} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              {/* Video 2 */}
              <Col md={4} className="mb-4">
                <Card className="video-card border-0">
                  <Card.Body className="p-0">
                  {videoUrl2 ? (
                        <video className="course-video" controls>
                            <source src={videoUrl2} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              {/* Video 3 */}
              <Col md={4} className="mb-4">
                <Card className="video-card border-0">
                  <Card.Body className="p-0">
                  {videoUrl3 ? (
                        <video className="course-video" controls>
                            <source src={videoUrl3} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}

                  </Card.Body>
                </Card>
              </Col>
              



              <Col md={4} className="mb-4">
                <Card className="video-card border-0">
                  <Card.Body className="p-0">
                  {videoUrl4 ? (
                        <video className="course-video" controls>
                            <source src={videoUrl4} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="video-card border-0">
                  <Card.Body className="p-0">
                  {videoUrl5 ? (
                        <video className="course-video" controls>
                            <source src={videoUrl5} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}

                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="video-card border-0">
                  <Card.Body className="p-0">
                  {videoUrl6 ? (
                        <video className="course-video" controls>
                            <source src={videoUrl6} type="video/mp4" />
                        </video>
                    ) : (
                        <p>Loading video...</p>
                    )}

                  </Card.Body>
                </Card>
              </Col>
              {/* Add more videos in similar Col elements if needed */}
            </Row>
          </div>
        </div>
      </Container>

      {/* Challenge Button Section */}
      <div className="text-center my-5">
        <Button
          variant="success"
          size="lg"
          className="challenge-button px-5 py-3"
          onClick={() => navigate('/new-money')}
        >
          Ready to Take the Adventure? It's Time to be an online millionare!
        </Button>
      </div>

      {/* Custom CSS for Challenge Button */}
      <style type="text/css">
        {`
          .video-container {
            position: relative;
            height: 75vh;
          }
          .video-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
          }
          .reaction-square {
            width: 100%;
            max-width: 1200px;
          }
          .challenge-button {
            font-size: 1.5rem;
            background-color: #ff5722; /* Vibrant Orange */
            color: #fff;
            border: none;
            font-weight: bold;
            transition: transform 0.3s, background-color 0.3s;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
          }
          .challenge-button:hover {
            background-color: #e64a19;
            transform: scale(1.05);
            box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.3);
          }
        `}
      </style>
    </div>
  );
}

export default NewMoneyLandingPage;

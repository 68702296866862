import React, { useState, useEffect, useRef } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './Videos.css'; // Import the external CSS for styling
import axios from 'axios';
import { Card } from 'react-bootstrap';

function CRW1V2() {
    const [videoUrl, setVideoUrl] = useState('');
    const navigate = useNavigate();
    const [isPaidStatus, setIsPaidStatus] = useState(false);
    const [hasWatchedEnough, setHasWatchedEnough] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const storage = getStorage();
                const videoRef = ref(storage, '/Crypto2/Week1/2.mp4');
                const url = await getDownloadURL(videoRef);
                setVideoUrl(url);
            } catch (error) {
                console.error('Error fetching video URL: ', error);
            }
        };
        fetchVideoUrl();
    }, []);

    // Function to handle video progress
    const handleTimeUpdate = async() => {

        if (videoRef.current) {
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;
            if (duration > 0 && currentTime / duration >= 0.9 && !hasWatchedEnough) {
                // If the user has watched 4/5 or more of the video
                setHasWatchedEnough(true);
                await axios.patch('https://sell-skill.com/api/endpoints/CryptoW1V2Completion');
            }
        }
    };


    // PayPal integration as before
    useEffect(() => {
        const addPayPalScript = () => {
            if (window.paypal) {
                renderPayPalButton();
            } else {
                const script = document.createElement('script');
                script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;
                script.type = 'text/javascript';
                script.async = true;
                script.onload = renderPayPalButton;
                document.body.appendChild(script);
            }
        };

        const renderPayPalButton = () => {
            window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [
                            {
                                amount: {
                                    value: '12.00', // Replace with your actual amount
                                },
                            },
                        ],
                    });
                },
                onApprove: async (data, actions) => {
                    return actions.order.capture().then(async (details) => {
                        alert(`Transaction completed by ${details.payer.name.given_name}`);
                        try {
                            await axios.patch('https://sell-skill.com/api/endpoints/paidCrypto');
                            setIsPaidStatus(true);
                        } catch (error) {
                            console.error('Error updating payment status: ', error);
                        }
                        window.location.reload();
                    });
                },
                onError: (err) => {
                    console.error('PayPal Checkout Error: ', err);
                    alert('An error occurred during the transaction. Please try again.');
                },
            }).render('#paypal-button-container');
        };

        addPayPalScript();
    }, []);

    return (
        <div className="video-container">
            {
                true ? (
                    <>
                        <div className="video-header">
                            <button className="back-button" onClick={() => navigate('/crypto-course/week-1')}>
                                ← Back
                            </button>
                        </div>
                        <div className="video-content">
                            {videoUrl ? (
                                <video
                                    className="course-video"
                                    controls
                                    ref={videoRef}
                                    onTimeUpdate={handleTimeUpdate}
                                >
                                    <source src={videoUrl} type="video/mp4" />
                                </video>
                            ) : (
                                <p>Loading video...</p>
                            )}
                        </div>
                    </>
                ) : (
                    <div className="payment-section">
                        <Card className="payment-card">
                            <Card.Body>
                                <h2 className="payment-title">Unlock the Course</h2>
                                <p className="payment-description">Get full access to the Cryptocurrency Trading academy by making a one-time payment of $12.00.</p>
                                <div className="payment-buttons">
                                    <div id="paypal-button-container" className="paypal-button"></div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                )
            }
        </div>
    );
}

export default CRW1V2;

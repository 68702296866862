import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../MainCourse.css';
import axios from 'axios';
import { Button, Container, Card } from 'react-bootstrap';
import NewMoneyTopBar from '../../../../courses/NewMoneyTopBar';

function AwabMLLevel1() {
  const [permission, setPermission] = useState(false);
  const [progress, setProgress] = useState(0);
  const [materials, setMaterials] = useState([]); // Store materials and sources for ML Level 1
  const tokenString = localStorage.getItem('clientToken');
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject?.token;
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchPermissionAndMaterials = async () => {
      try {
        const permissionResponse = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermission(permissionResponse.data.permission);

        // Fetch Level 1 materials and sources for ML
        const materialsResponse = await axios.get('https://sell-skill.com/api/levels/ml-level-1/materials', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMaterials(materialsResponse.data.materials);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchPermissionAndMaterials();
  }, [token]);

  const filteredMaterials = materials.filter(material =>
    material.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="container-fluid main-course" style={{ backgroundColor: '#001f3f', minHeight: '100vh', paddingTop: '50px' }}>
      {permission ? (
        <>
          <NewMoneyTopBar />
          <h1 className="text-center text-white mb-5">Machine Learning Level 1: Materials and Sources</h1>

          <div className="row justify-content-center mb-4">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Search materials..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>

          <div className="row justify-content-center">
            {filteredMaterials.length > 0 ? (
              filteredMaterials.map((material) => (
                <div key={material.id} className="col-md-4 mb-4">
                  <div className="card course-card text-center">
                    <img src={material.image} className="card-img-top" alt={material.name} />
                    <div className="card-body">
                      <h5 className="card-title text-dark">{material.name}</h5>
                      <p className="card-text text-muted">{material.description}</p>
                      <div className="btn-group mt-3" role="group">
                        <Link to={material.source} className="btn btn-success">View Material</Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-white">No materials found</p>
            )}
          </div>

          <div className="text-center mt-5">
            <Button
              variant="primary"
              size="lg"
              className="exam-button"
              onClick={() => navigate('/ml-level-1-exam')}
            >
              Take the Exam and Advance to the Next Level
            </Button>
          </div>
        </>
      ) : (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <Card className="text-center p-4" style={{ width: '100%', maxWidth: '400px', backgroundColor: '#1e1e2f', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <Card.Body>
              <Card.Title style={{ fontSize: '24px', marginBottom: '20px' }}>Welcome Back!</Card.Title>
              <Button variant="outline-light" onClick={() => navigate('/auth')} style={{ padding: '10px 20px', fontSize: '18px' }}>
                Sign Up/In
              </Button>
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default AwabMLLevel1;

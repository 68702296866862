import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Modules.css'; // Custom CSS for additional styling
import axios from 'axios';

function PSWeek3() {
  const navigate = useNavigate();
  const [isPaidStatus, setIsPaidStatus] = useState(false)
  // Array of video titles (you can replace these with actual video titles)
  const videos = [
    { title: '1_ Introduction to Adobe Photoshop Beginner to Advance', id: 1 },
    { title: '2_ Lesson 1 - Workspace, Documents Interface', id: 2 },
    { title: '3_  Lesson 2 - Layers, Layer masks Blending Modes', id: 3 },
    // { title: '4_ Easy Facebook Page Setup', id: 4},
    // { title: '5_ Easy Facebook Ads Manager Setup', id: 5 },
    // { title: '6_ Fun Facebook Ads Manager Tour', id: 6},
    // { title: '7_ Your Movie Studio - Easy Ad Campaign Structure', id: 7 },
    // { title: '8_ 4 Step Customer Pathway', id: 8},
    // { title: '9_ The Power of the Facebook Pixel', id: 9 },
  ];

  // Navigate to a video detail page when a button is clicked
  const goToVideo = (id) => {
    navigate(`/PS-course/week-3/video-${id}`);
  };
useEffect(() => {
  const isPaid = async() => {
    const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidPSCourse')
    setIsPaidStatus(response.data.isPaid)
  }
   isPaid()


}, [])
  return (
    <Container fluid className="module1-home-container text-center">
      <h1 className="page-title mb-4">Crypto Module One Videos</h1>
      <Row className="justify-content-center">
        {videos.map((video) => (
          <Col key={video.id} xs={12} md={6} lg={6} className="mb-3">
            <Card className="video-card">
              <Card.Header className="d-flex justify-content-between align-items-center">
                <span className="video-title">{video.title}</span>
                <Button
                  variant="primary"
                  className="video-btn"
                  onClick={() => goToVideo(video.id)}
                >
                  Watch
                </Button>
              </Card.Header>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default PSWeek3;
import React from 'react';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';
import './BeautyFashionBlog.css'; 
import { useNavigate } from 'react-router-dom';

const BeautyFashionBlog = () => {
  const navigate = useNavigate();
  
  return (
    <div className="blog-container">
      <Helmet>
        <title>Top Fashion & Style Consultation: Elevate Your Look with Expert Advice</title>
        <meta 
          name="description" 
          content="Explore top-tier fashion consultation services for men. Get personalized style advice and luxury consulting to transform your wardrobe and boost your confidence." 
        />
        <meta 
          name="keywords" 
          content="men's fashion consultation, style advice, luxury consulting for men, male fashion consultant, wardrobe makeover, personal style enhancement" 
        />
      </Helmet>
      <Button onClick={() => navigate('/')} className="cta-button">Join as a Skiller</Button>
      <article>
        <header>
          <h1>Elevate Your Style: Expert Fashion Consultation for Men</h1>
          <p className="intro">
            Ready to redefine your style? Our expert fashion consultants are here to help you transform your look and enhance your confidence. Tailored just for men, our services will guide you in creating a style that is distinctively yours.
          </p>
        </header>

        <section className="content-section">
          <h2>Why You Need a Fashion Consultant</h2>
          <img src="/images/male-fashion-consultant.jpg" alt="Male fashion consultant advising a client" className="image-style" />
          <p>
            A fashion consultant brings specialized knowledge in men's fashion and luxury consulting, offering you personalized advice to ensure you always look your best. Whether it's for a special occasion or a daily wardrobe update, our consultants provide expert insights that align with your unique style and preferences.
          </p>
        </section>

        <section className="content-section">
          <h2>Enhance Your Style and Confidence</h2>
          <img src="/images/male-style-enhancement.jpg" alt="Man experiencing style enhancement" className="image-style" />
          <p>
            Fashion is about more than just clothing—it's a form of self-expression that can significantly boost your confidence. Our skilled fashion consultants work with you to understand your personal style and goals, crafting a tailored plan that enhances your appearance and helps you feel confident in any setting.
          </p>
        </section>

        <section className="content-section">
          <h2>Experience Our Exclusive Style House</h2>
          <img src="/images/male-style-house.jpg" alt="Exterior of a male-oriented style house" className="image-style" />
          <p>
            For a comprehensive style makeover, visit our exclusive style house. Here, you can receive personalized consultations, explore the latest trends in men's fashion, and discover a new style that perfectly suits you. Our team of experts is committed to helping you look and feel your best.
          </p>
        </section>
        
        <footer>
          <p className="call-to-action">
            Ready to upgrade your style? Contact our expert fashion consultants today and start your journey to a more confident, stylish you!
          </p>
        </footer>
      </article>
    </div>
  );
};

export default BeautyFashionBlog;

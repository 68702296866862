import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './Courses.css'; // Custom CSS for additional styling
import axios from 'axios';
import './PayPalButton.css';

function FaceBookAdsCourse() {
  const [activeModule, setActiveModule] = useState(null);


  //payment--/payment--/payment////--payment//start--start--start//
  const [isPaidStatus, setIsPaidStatus] = useState(false);
  const navigate = useNavigate(); 

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        
        // PayPal SDK script to include both PayPal and card payments (no funding sources are disabled)
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&components=buttons,funding-eligibility&enable-funding=card`;


        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        // No funding source restriction, so both PayPal and card will be available
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '9.00', // Replace with your actual amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            try {
              await axios.patch('https://sell-skill.com/api/endpoints/paidFacebookAds');
              setIsPaidStatus(true);

            } catch (error) {
              console.error('Error updating payment status: ', error);
            }
            window.location.reload();
          });
        },        
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    addPayPalScript();
  }, []);

  useEffect(() => {
    const checkIsPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidFacebookAdsCourse');
      setIsPaidStatus(response.data.isPaid);
    }
    checkIsPaid();
  }, []);

  
    //payment--/payment--/payment////--payment//end--end--end--end--end//

  // Function to toggle module descriptions
  const toggleModule = (module) => {
    setActiveModule(activeModule === module ? null : module);
  };

  // Function to navigate to the module route when the card is clicked
  const navigateToModule = (module) => {
    navigate(`/facebook-ads-course/week-${module}`);
  };



  
  return (
    <Container fluid className="crypto-course-container text-center">
      {isPaidStatus ? (
        <>
          <h1 className="course-title mb-4">Facebook Ads Course</h1>
          <Row className="justify-content-center">
            {[1, 2, 3, 4, 5].map((module) => (
              <Col key={module} xs={12} md={6} lg={6} className="mb-3">
                <Card className="module-card">
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <span
                      className="module-title"
                      onClick={() => navigateToModule(module)}
                      style={{ cursor: 'pointer' }}
                    >
                      Module {module}
                    </span>
                    <Button
                      variant="link"
                      className="module-toggle-btn"
                      onClick={() => toggleModule(module)}
                    >
                      {activeModule === module ? '▲' : '▼'}
                    </Button>
                  </Card.Header>
                  {activeModule === module && (
                    <Card.Body className="module-description">
                      <p>
                        This is the description for Module {module}. It covers various topics related to Facebook Ads.
                      </p>
                    </Card.Body>
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </>
      ) : (
        <div className="payment-section">
          <Card className="payment-card">
            <Card.Body>
              <h2 className="payment-title">Unlock the Course</h2>
              <p className="payment-description">Get full access to the Facebook Ads Course by making a one-time payment of $9.00.</p>
              <div className="payment-buttons">
                <div id="paypal-button-container" className="paypal-button"></div>
                {/* PayPal payment button will appear here */}
              </div>


            </Card.Body>
          </Card>

        </div>
      )}

<section className="py-5 text-center">
  <div className="container">
    <img 
      src="/LandingPagesImages/findCourse.png" 
      alt="Lifetime Access" 
      className="img-fluid rounded" 
      style={{ maxWidth: '100%', height: 'auto' }}
    />
    <h4 className="mt-3">Lifetime Access to Course Materials</h4>
    <p>Once enrolled, enjoy lifetime access to all course materials and updates.</p>
  </div>
</section>
    </Container>
  );
}

export default FaceBookAdsCourse;

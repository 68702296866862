import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
// Add Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const ThankYouPage1 = () => {
  const instagramUrl = 'YOUR_INSTAGRAM_URL'; // Replace with your Instagram profile URL
  const [pdfUrl, setPdfUrl] = useState('');
  const [videoUrls, setVideoUrls] = useState([]);
  
  useEffect(() => {
    const fetchVideoUrls = async () => {
      try {
        const storage = getStorage();
        const videoRefs = [
          ref(storage, 'FacebookAdsCourse1/Week1/1.mp4'),
          ref(storage, 'NewMoneyUgc/IMG_9745.MP4'),
          ref(storage, 'NewMoneyUgc/IMG_9455.MP4'),
          ref(storage, 'NewMoneyUgc/IMG_8763.MP4'),
        ];

        const urls = await Promise.all(videoRefs.map((videoRef) => getDownloadURL(videoRef)));
        setVideoUrls(urls);

        const pdfRef = ref(storage, 'ebooks/TradeGPT_100_Prompts_with_Cover.pdf');
        const url = await getDownloadURL(pdfRef);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching video URLs: ', error);
      }
    };
    fetchVideoUrls();

    if (window.fbq) {
      window.fbq('track', 'Lead', {
        content_name: 'Thank You Page Lead',
        value: 0,
        currency: 'USD'
      });
    }
  }, []);

  return (
    <Container
      fluid
      style={{
        background: 'linear-gradient(to right, #000428, #004e92)',
        color: 'white',
        minHeight: '100vh',
        padding: '50px 20px',
        textAlign: 'center',
      }}
    >
      <Row className="justify-content-center mb-5">
        <Col md={8}>
          <h1 className="display-4" style={{ color: 'orange' }}>Congrats!</h1>
          <p className="lead">
            You've successfully subscribed. Your free eBook is ready to be downloaded.
          </p>
          <img
            src="LandingPagesImages/TradeGpt.jpg"
            alt="eBook"
            className="img-fluid my-4"
            style={{ maxWidth: '250px' }}
          />
          <Button
            href={pdfUrl}
            variant="primary"
            size="lg"
            className="mb-4"
            style={{ backgroundColor: '#007bff', borderColor: '#007bff', marginLeft: '20px' }}
            download
          >
            Download eBook
          </Button>
          <div className="my-5">
            <h4>There is no successful brand without facebook ads!</h4>
            <p>
              Get the most knowledge and the million-dollar secret on setting Facebook ads. Don't believe it? Check the trailer below.
            </p>
            <p><a href="/facebook-ads-course" style={{ color: '#ffc107', textDecoration: 'underline' }}>Get the Facebook Ads Course</a> and become an online millionaire.</p>
          </div>
        </Col>
      </Row>
      
      {/* Main Video */}
      <Row className="justify-content-center mb-5">
        <Col md={10}>
          {videoUrls.length > 0 ? (
            <div className="video-card" style={{ backgroundColor: '#000428', padding: '15px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
              <video controls className="main-video" style={{ width: '100%', borderRadius: '5px' }}>
                <source src={videoUrls[0]} type="video/mp4" />
              </video>
              <p className="mt-3">Week 1, Video 1: Introduction to Facebook Ads</p>
            </div>
          ) : (
            <p>Loading video...</p>
          )}
        </Col>
      </Row>

      {/* Smaller Videos Section */}
      <Container className="mb-5">
        <Row className="g-4">
          {videoUrls.length > 1 ? (
            videoUrls.slice(1).map((url, index) => (
              <Col md={4} key={index} className="video-col">
                <div className="video-card" style={{ backgroundColor: '#000428', padding: '15px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                  <video controls className="course-video" style={{ width: '100%', borderRadius: '5px' }}>
                    <source src={url} type="video/mp4" />
                  </video>
                  <p className="mt-3">Week {Math.ceil((index + 2) / 3)}, Video {index + 2}</p>
                </div>
              </Col>
            ))
          ) : (
            <p>Loading videos...</p>
          )}
        </Row>
      </Container>
      
    </Container>
  );
};

export default ThankYouPage1;

import React, { useState } from 'react';
import './ClientProfile.css';

function ClientProfile() {
  const [name, setName] = useState('John Doe');
  const [lastName, setLastName] = useState('Doe');
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingLastName, setIsEditingLastName] = useState(false);
  const [profileImage, setProfileImage] = useState('default-profile.jpg'); // Placeholder image

  const handleNameChange = async () => {
    setIsEditingName(!isEditingName);
    if (isEditingName) {
      try {
        await fetch('https://sell-skill.com/api/endpoints/update-name', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name }),
        });
      } catch (error) {
        console.error('Error updating name:', error);
      }
    }
  };

  const handleLastNameChange = async () => {
    setIsEditingLastName(!isEditingLastName);
    if (isEditingLastName) {
      try {
        await fetch('https://sell-skill.com/api/endpoints/update-lastname', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ lastName }),
        });
      } catch (error) {
        console.error('Error updating last name:', error);
      }
    }
  };

  const handleProfileImageChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfileImage(URL.createObjectURL(file));

      // FormData to handle file upload
      const formData = new FormData();
      formData.append('profileImage', file);

      try {
        await fetch('https://sell-skill.com/api/endpoints/update-profile-image', {
          method: 'POST',
          body: formData,
        });
      } catch (error) {
        console.error('Error updating profile image:', error);
      }
    }
  };

  const handlePasswordChange = () => {
    // This could be handled by redirecting to a password change page or opening a modal

  };

  return (
    <div className="client-profile">
      <div className="profile-container">
        <h2>Account Settings</h2>

        {/* Profile Image */}
        <div className="field profile-image-field">
          <div className="image-wrapper">
            <img src={profileImage} alt="Profile" className="profile-image" />
            <label htmlFor="upload-profile" className="edit-icon">
              <span role="img" aria-label="Edit Image">🖼️</span>
            </label>
            <input
              type="file"
              id="upload-profile"
              style={{ display: 'none' }}
              onChange={handleProfileImageChange}
            />
          </div>
        </div>

        {/* Name */}
        <div className="field">
          <label>Name:</label>
          {isEditingName ? (
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              onBlur={handleNameChange}
              className="edit-input"
              autoFocus
            />
          ) : (
            <div className="editable-field">
              <span>{name}</span>
              <button onClick={handleNameChange} className="edit-button">✏️ Edit</button>
            </div>
          )}
        </div>

        {/* Last Name */}
        <div className="field">
          <label>Last Name:</label>
          {isEditingLastName ? (
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              onBlur={handleLastNameChange}
              className="edit-input"
              autoFocus
            />
          ) : (
            <div className="editable-field">
              <span>{lastName}</span>
              <button onClick={handleLastNameChange} className="edit-button">✏️ Edit</button>
            </div>
          )}
        </div>

        {/* Change Password */}
        <div className="field">
          <button onClick={handlePasswordChange} className="change-password-button">
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
}

export default ClientProfile;

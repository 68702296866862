import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOverviewDescription } from '../../reducers/reducers';
import axios from 'axios';

function Description() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const previousPostData = useSelector((state) => state.allow.overview);
    const [postData, setPostData] = useState(previousPostData);
    const [permission, setPermission] = useState(false);

    const tokenString = localStorage.getItem('clientToken');
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject.token || tokenObject;

    useEffect(() => {
        const fetchPermission = async () => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPermission(response.data.permission);
        };
        fetchPermission();
    }, [token]);

    const handleNextClick = () => {
        dispatch(setOverviewDescription(postData));
        navigate('/posting-overview');
    };

    const navigateSignUpIn = () => {
        navigate('/auth');
    };

    return (
        <div style={styles.container}>
            {permission ? (
                <Container>
                    <Card style={styles.outerCard}>
                        <Card.Body>
                            <Card style={styles.innerCard}>
                                <Card.Title style={styles.title}>
                                    Description
                                </Card.Title>
                                <Form style={styles.form}>
                                    <Form.Control
                                        as="textarea"
                                        rows={4}
                                        placeholder='Enter a detailed description for your call'
                                        onChange={(e) => setPostData({ ...postData, description: e.target.value })}
                                        style={styles.input}
                                    />
                                    <Button onClick={handleNextClick} style={styles.button}>
                                        Next
                                    </Button>
                                </Form>
                            </Card>
                        </Card.Body>
                    </Card>
                </Container>
            ) : (
                <div style={styles.signupPrompt}>
                    <Card style={styles.promptCard}>
                        <Button onClick={navigateSignUpIn} style={styles.signInButton}>Sign Up/In</Button>
                    </Card>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        height: '100vh',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Gradient background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    outerCard: {
        borderRadius: '30px',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Outer card gradient
        color: '#fff',
        width: '100%',
        maxWidth: '600px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
        padding: '30px',
    },
    innerCard: {
        borderRadius: '20px',
        background: '#0f2027', // Inner card background
        color: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '30px',
    },
    title: {
        textAlign: 'center',
        fontSize: '28px',
        fontWeight: 'bold',
        color: '#00c6ff', // Accent color for title
        marginBottom: '30px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    input: {
        marginBottom: '20px',
        borderRadius: '8px',
        border: '1px solid #00c6ff', // Border color matching the accent
        boxShadow: '0 2px 4px rgba(0, 198, 255, 0.1)',
        width: '100%',
        padding: '10px',
        color: '#000428',
    },
    button: {
        backgroundColor: '#00c6ff', // Bright button color
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        width: '100%',
        fontWeight: 'bold',
        fontSize: '16px',
        padding: '12px',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
    },
    signupPrompt: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Background for sign-in prompt
    },
    promptCard: {
        padding: '30px',
        backgroundColor: '#0f2027',
        color: '#fff',
        borderRadius: '20px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
    },
    signInButton: {
        background: '#00c6ff',
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        padding: '10px 20px',
        fontWeight: 'bold',
        fontSize: '18px',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
    },
};

export default Description;

//To show levels Thumbnails number and title


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLevelNumber, setWorldName } from '../../reducers/reducers';
import { useNavigate } from 'react-router-dom';

function MyLevels() { // Pass skillerId as a prop
  const [levels, setLevels] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const worldName = useSelector((state) => state.allow.worldName)
  console.log('world name from levels =====> ',worldName)

  const handleAddLevel = () => {
    dispatch(setWorldName(worldName))
    navigate('/add-level')
  }
  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await axios.get(`https://sell-skill.com/api/endpoints/getLevels`, {
          params: { worldName } // Pass worldName as a query parameter
        });
        setLevels(response.data);
      } catch (error) {
        console.error("Error fetching worlds:", error);
      }
    };
  
    if (worldName) {
      fetchLevels();
    }
  }, [worldName]);
  
  const handleShowLevel=  (levelNumber) => {
    dispatch(setLevelNumber(levelNumber))
    navigate ('/my-lessons')
  }
  const handleEditLevel = (levelNumber) => {
    dispatch(setLevelNumber(levelNumber))
    // use useSelector to get this name in the edit world route => send the name to back, then send info to front
    navigate('/edit-level')
    
  }

  return (
    <div>
      <h1>My Levels</h1>
      <button onClick={handleAddLevel}>add level</button>
      <div className="worlds-container">
        {levels.map((level, index) => (
          <div key={index} className="world-card">
            <h2>{level.name}</h2>
            <h2>{level.levelTitle}</h2>
            <p>{level.description}</p>

            <button onClick={()=>handleShowLevel(level.name)}>show lessons inside this level</button>
            <button onClick={()=>handleEditLevel(level.name)}>edit level</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyLevels;

import React, { useEffect, useState } from 'react';
import { Card, Alert, Modal, Button, Navbar, Nav } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Buffer } from 'buffer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import { FaHeadset } from 'react-icons/fa'; // Importing icon for customer support
import { Link } from 'react-router-dom';

function NewMoneyTopBar() {
  const [checkNewMessages, setCheckNewMessages] = useState(false);
  const [checkNewInvitationAcceptance, setCheckNewInvitationAcceptance] = useState(false);
  const [newProposals, setNewProposals] = useState(false);
  const [profilePictureSrc, setProfilePictureSrc] = useState('');
  const [name, setName] = useState('');
  const [contentType, setContentType] = useState('undefined');
  const [showModal, setShowModal] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);


  useEffect(() => {
    const fetchCheckClientNewMessage = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkClientNewMessage');
      setCheckNewMessages(response.data);
    };
    fetchCheckClientNewMessage();
  }, []);
  
  const handleClose = () => {
    setShowModal(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCheckClientNewMessage = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkClientNewMessage');
      setCheckNewMessages(response.data);
    };
    fetchCheckClientNewMessage();
  }, []);

  useEffect(() => {
    const fetchCheckNewInvitationAcceptance = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getNewInvitationAcceptanceState');
      setCheckNewInvitationAcceptance(response.data);
    };
    fetchCheckNewInvitationAcceptance();
  }, []);

  useEffect(() => {
    const checkNewProposal = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/checkClientNewProposals');
      setNewProposals(response.data);
    };
    checkNewProposal();
  }, []);

  useEffect(() => {
    fetch('https://sell-skill.com/api/endpoints/getClientProfileData')
      .then(response => response.json())
      .then(result => setName(result.name));
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getClientProfilePicture', {
          responseType: 'arraybuffer',
        });
        const base64 = Buffer.from(response.data, 'binary').toString('base64');
        const mimeType = response.headers['content-type'];
        setContentType(mimeType);
        setProfilePictureSrc(`data:${mimeType};base64,${base64}`);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchImage();
  }, [name]);

  const handleLogout = async () => {
    localStorage.removeItem('clientToken');
    navigate('/');
  };

  return (
    <TopBarContainer>
      <LeftSection>
        <AlertsContainer>
          {checkNewMessages && <StyledAlert variant="danger">You have new messages!</StyledAlert>}
        </AlertsContainer>
      </LeftSection>
      <MiddleSection>

                <Navbar.Toggle aria-controls="topbar-nav" />
   
                <Nav className="me-auto">
  <Nav.Link as={Link} to="/ask-advisor" className="eye-catching-button">
    <FaHeadset style={{ marginRight: '5px', fontSize: '1.5em' }} />
    Ask Your Advisor
  </Nav.Link>
  <Nav.Link as={Link} to="/cheat-sheets" className="eye-catching-button">
    Cheat Sheets
  </Nav.Link>
</Nav>

      </MiddleSection>
      <RightSection>
        <ProfileCard>
          <Card.Body>
            <ProfileContainer
              onMouseEnter={() => setShowDropdown(true)}
              onMouseLeave={() => setShowDropdown(false)}
            >
              <ProfileName>{name}</ProfileName>
              <ProfileDetails>

                {contentType !== 'undefined' ? (
                  <ProfileImage src={profilePictureSrc} />
                ) : (
                  <ProfileImage src="/images/NormalProfile.jpg" />
                )}
              </ProfileDetails>
              {showDropdown && (
                <DropdownMenu>
                  <ul>
                    <li onClick={() => navigate('/my-profile')}>My Profile</li>
                    <li onClick={() => navigate('/my-learning')}>My Learning</li>
                    <li onClick={() => navigate('/my-certifications')}>Certifications</li>
                    <li onClick={() => navigate('/client-received-messages')}>Messages</li>
                    <li onClick={handleLogout}>Log Out</li>
                  </ul>
                </DropdownMenu>
              )}
            </ProfileContainer>
          </Card.Body>
        </ProfileCard>
      </RightSection>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body className="modal-content">
          <h3 className="modal-title">Join New Money Library</h3>
          <p>Check out the best SellSkill courses</p>
          <Button
            variant="primary"
            className="cta-button"
            onClick={() => {
              handleClose();
              navigate('/new-money');
            }}
          >
            Learn how to earn!
          </Button>
        </Modal.Body>
      </Modal>
    </TopBarContainer>
  );
}

export default NewMoneyTopBar;


// Styled Components
const TopBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(135deg, #004085, #000);
  padding: 15px 30px;
  color: white;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 20px;
  }
`;

const LeftSection = styled.div`
  flex: 1;
`;

const MiddleSection = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
`;

const RightSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const AlertsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledAlert = styled(Alert)`
  background-color: #FFA500;
  color: black;
  font-weight: bold;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
  }
`;

const NavLink = styled.a`
  color: white;
  font-weight: bold;
  padding: 5px 10px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #32CD32;
    color: white;
  }
`;

const MoneyWorld = styled(NavLink)`
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 0 0 10px #FFD700, 0 0 20px #FFD700, 0 0 30px #FF4500;
  background-image: linear-gradient(90deg, #008000, #32CD32);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  border: 2px solid #FFD700;
  padding: 5px 15px;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 20px #FFD700;
    color: #fff;
  }
`;

const ProfileCard = styled(Card)`
  background-color: transparent;
  border: none;
`;


const ProfileContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: row-reverse; /* Make the image appear on the right */
`;

const ProfileName = styled.h5`
  margin-left: 50px; /* Adjust the margin for spacing from the image */
  color: white;
`;


const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #333;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 9999; /* Strong z-index to be above other components */

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 10px;
      cursor: pointer;
      color: white;

      &:hover {
        background-color: #555;
      }
    }
  }
`;

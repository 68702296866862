import React, { useEffect, useState } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import axios from 'axios'
import { useNavigate } from 'react-router-dom';
function KillerFacebookAds() {
  const [pdfUrl, setPdfUrl] = useState('');
  const [loading, setLoading] = useState(true);
    const [isPaidStatus, setIsPaidStatus] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        // Load PayPal Script
        const addPayPalScript = () => {
          if (window.paypal) {
            renderPayPalButton();
          } else {
            const script = document.createElement('script');
            script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
            script.type = 'text/javascript';
            script.async = true;
            script.onload = renderPayPalButton;
            document.body.appendChild(script);
          }
        };
    
        const renderPayPalButton = () => {
          window.paypal.Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: '9.00', // Replace with your amount
                    },
                  },
                ],
              });
            },
            onApprove: async (data, actions) => {
              return actions.order.capture().then(async (details) => {
                alert(`Transaction completed by ${details.payer.name.given_name}`);
                await axios.patch('https://sell-skill.com/api/endpoints/paidFacebookAds');
                navigate('/facebook-ads-course');
              });
            },
            onError: (err) => {
              console.error('PayPal Checkout Error: ', err);
              alert('An error occurred during the transaction. Please try again.');
            },
          }).render('#paypal-button-container');
        };
    
        // Load Stripe Script
        // const loadStripeScript = async () => {
        //   const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
        //   const button = document.getElementById('stripe-button');
        //   button.addEventListener('click', async () => {
        //     const response = await axios.post('https://sell-skill.com/api/stripe/create-checkout-session', {
        //       amount: 500, // Amount in cents (500 cents = $5.00)
        //       currency: 'usd',
        //       name: 'Crypto Course',
        //     });
        //     const { sessionId } = response.data;
        //     stripe.redirectToCheckout({ sessionId });
        //   });
        // };
    
        addPayPalScript();
        // loadStripeScript();
      }, []);


  useEffect(() => {
    const isPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidFacebookAdsCourse')
      setIsPaidStatus(response.data.isPaid)
    }
     isPaid()
  
  
  }, [])


  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        const storage = getStorage();
        const PdfRef = ref(storage, 'NewMoneyEbooks/killer_facebook_ads.pdf');
        const url = await getDownloadURL(PdfRef);
        setPdfUrl(url);
      } catch (error) {
        console.error('Error fetching PDF URL: ', error);
      } finally {
        setLoading(false);
      }
    };
    fetchPdfUrl();
  }, []);

  useEffect(() => {
    const checkIsPaid = async() => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/isPaidFacebookAdsCourse');
      setIsPaidStatus(response.data.isPaid);
    }
    checkIsPaid();
  }, []);

  return (
    <Container className="mt-5">
        {
            isPaidStatus ? (<Card className="shadow-lg" style={{ backgroundColor: '#2c3e50', color: '#fff', borderRadius: '15px' }}>
                <Card.Header className="text-center" style={{ backgroundColor: '#1abc9c', borderRadius: '15px 15px 0 0' }}>
                  <h2>Killer Facebook Ads</h2>
                  <p>by Marty Weintraub</p>
                </Card.Header>
                <Card.Body className="text-center">
                  {loading ? (
                    <Spinner animation="border" variant="light" />
                  ) : (
                    <>
                      <p className="mb-4">
                        This eBook provides a comprehensive guide on creating effective Facebook ads that convert. Learn the strategies that have helped numerous businesses achieve success with their ad campaigns.
                      </p>
                      <div className="mb-4">
                        {pdfUrl ? (
                          <iframe
                            src={pdfUrl}
                            width="100%"
                            height="500px"
                            style={{ border: 'none', borderRadius: '10px' }}
                            title="Killer Facebook Ads eBook"
                          ></iframe>
                        ) : (
                          <p>Failed to load the PDF. Please try again later.</p>
                        )}
                      </div>
                      <Button
                        variant="success"
                        href={pdfUrl}
                        target="_blank"
                        style={{ borderRadius: '30px', padding: '10px 30px' }}
                      >
                        Download eBook
                      </Button>
                    </>
                  )}
                </Card.Body>
              </Card>)
            : (
                <div className="payment-section">
                  <Card className="payment-card">
                    <Card.Body>
                      <h2 className="payment-title">Unlock Killer Facebook Ads</h2>
                      <p className="payment-description">Get full access to the Facebook Ads Course by making a one-time payment of $9.00.</p>
                      <div className="payment-buttons">
                        <div id="paypal-button-container" className="paypal-button"></div>
                        {/* <button id="stripe-button" className="stripe-button">Pay with Stripe</button> */}
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              )
        }
      
    </Container>
  );
}

export default KillerFacebookAds;


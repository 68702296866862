import React from 'react';
import { Container, Row, Col, Card, Button, ProgressBar, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TestimonialsPage.css';
import Footer from '../Footer/Footer';

function TestimonialsPage() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>User Success Stories - Transform Your Future with Sell-Skill</title>
        <meta
          name="description"
          content="Discover real success stories from Sell-Skill users who have transformed their careers and businesses. Join our platform today and create your own success story."
        />
        <meta
          name="keywords"
          content="Sell-Skill success stories, user testimonials, career transformation, business growth, remote work success, online learning, user reviews, customer feedback, social proof"
        />
        <meta name="author" content="Sell-Skill" />
        <link rel="canonical" href="https://yourwebsite.com/testimonials" />
      </Helmet>

      <Container fluid className="py-5 testimonials-page">
        {/* Hero Section */}
        <section className="hero-section text-center mb-5">
          <h1 className="page-title">User Success Stories</h1>
          <p className="page-subtitle">Discover how Sell-Skill is transforming lives.</p>
          <img
            src="/images/success-stories-hero.jpg"
            alt="Success Stories"
            className="img-fluid mb-4"
            style={{ maxHeight: '400px', width: '100%', objectFit: 'cover', borderRadius: '10px' }}
          />
        </section>

        {/* Success Stories Carousel */}
        <section className="carousel-section mb-5">
          <h2 className="text-center mb-4">Real Stories from Real Users</h2>
          <Carousel>
            <Carousel.Item>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>John Doe - Entrepreneur</Card.Title>
                  <Card.Text>
                    John transformed his small business using the expert advice and courses available on our platform. His
                    business has seen a 200% increase in revenue since joining.
                  </Card.Text>
                  <Button onClick={() => navigate('/auth')} className="cta-button">
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Jane Smith - Freelance Developer</Card.Title>
                  <Card.Text>
                    Jane found a steady stream of remote work opportunities through our platform. She now enjoys the
                    freedom of working from anywhere while pursuing her passion.
                  </Card.Text>
                  <Button onClick={() => navigate('/auth')} className="cta-button">
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Sarah Johnson - Marketing Specialist</Card.Title>
                  <Card.Text>
                    Sarah enhanced her marketing skills with our premium courses. She recently landed a promotion at her
                    job and attributes her success to the knowledge gained here.
                  </Card.Text>
                  <Button onClick={() => navigate('/auth')} className="cta-button">
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="text-center">
                <Card.Body>
                  <Card.Title>Michael Lee - Software Engineer</Card.Title>
                  <Card.Text>
                    Michael transitioned into a new tech role by leveraging the industry connections and expert advice
                    offered on our platform. His career has skyrocketed since.
                  </Card.Text>
                  <Button onClick={() => navigate('/auth')} className="cta-button">
                    Join Now
                  </Button>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </section>

        {/* User Ratings and Reviews */}
        <section className="ratings-section text-center mb-5">
          <h2 className="mb-4">What Our Users Say</h2>
          <Row className="justify-content-center">
            <Col md={3} className="mb-4">
              <Card className="rating-card">
                <Card.Body>
                  <Card.Title>Average Rating</Card.Title>
                  <h3 className="display-4">4.8/5</h3>
                  <ProgressBar now={96} label="96%" />
                  <p className="mt-2">Based on 1,234 reviews</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="rating-card">
                <Card.Body>
                  <Card.Title>User Satisfaction</Card.Title>
                  <h3 className="display-4">97%</h3>
                  <ProgressBar variant="success" now={97} label="97%" />
                  <p className="mt-2">High user satisfaction rate</p>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-4">
              <Card className="rating-card">
                <Card.Body>
                  <Card.Title>Returning Users</Card.Title>
                  <h3 className="display-4">85%</h3>
                  <ProgressBar variant="info" now={85} label="85%" />
                  <p className="mt-2">Users who come back</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </section>

        {/* Call to Action Section */}
        <section className="cta-section text-center py-5">
          <h2>Ready to Create Your Own Success Story?</h2>
          <p>Join our platform today and start your journey towards success.</p>
          <Button onClick={() => navigate('/auth')} className="cta-button">
            Sign Up / Login
          </Button>
        </section>

        {/* Social Proof Section */}
        <section className="social-proof-section text-center py-5">
          <h2>As Seen On</h2>
          <Row className="justify-content-center">
            <Col xs={6} sm={4} md={2} className="mb-3">
              <img src="/images/logo1.png" alt="Logo 1" className="img-fluid" />
            </Col>
            {/* <Col xs={6} sm={4} md={2} className="mb-3">
              <img src="/images/logo2.png" alt="Logo 2" className="img-fluid" />
            </Col>
            <Col xs={6} sm={4} md={2} className="mb-3">
              <img src="/images/logo3.png" alt="Logo 3" className="img-fluid" />
            </Col>
            <Col xs={6} sm={4} md={2} className="mb-3">
              <img src="/images/logo4.png" alt="Logo 4" className="img-fluid" />
            </Col> */}
          </Row>
        </section>

        <Footer />
      </Container>
    </>
  );
}

export default TestimonialsPage;

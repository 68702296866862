import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setWorldName } from '../reducers/reducers';

function MyWorlds() {
  const [worlds, setWorlds] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAddWorld = () => {
    navigate('/add-world');
  };

  useEffect(() => {
    const fetchWorlds = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getWorlds');
        setWorlds(response.data);
      } catch (error) {
        console.error('Error fetching worlds:', error);
      }
    };

    fetchWorlds();
  }, []);

  const handleGetInWorld = (worldName) => {
    dispatch(setWorldName(worldName));//should be set world id
    navigate('/my-levels'); //it should be closed unless he pay
  };

  const handleshowDescription = (worldName) => {
    dispatch(setWorldName(worldName));
    navigate('/showDescription');
  };

  return (
    <div className="container text-white" style={{ backgroundColor: 'black', minHeight: '100vh', padding: '2rem' }}>
      <h1 className="text-center mb-4" style={{ color: '#1E90FF' }}>My Worlds</h1>
      <button
        className="btn mb-4"
        style={{ backgroundColor: '#1E90FF', color: 'white', border: 'none' }}
        onClick={handleAddWorld}
      >
        Add World
      </button>
      <div className="row">
        {worlds.map((world, index) => (
          <div key={index} className="col-md-4 mb-4">
            <div
              className="card h-100 text-dark world-card"
              style={{
                border: '2px solid #1E90FF',
                borderRadius: '10px',
                transition: 'transform 0.3s',
              }}
            >
              <div className="card-body">
                <h2 className="card-title text-center" style={{ color: '#1E90FF' }}>{world.name}</h2>
                <p className="card-text text-center">{world.description}</p>
                {world.thumbnail && (
                  <img
                    src={world.thumbnail}
                    alt={`${world.name} Thumbnail`}
                    className="card-img-top mb-3"
                    style={{ borderRadius: '10px', maxHeight: '200px', objectFit: 'cover' }}
                  />
                )}
                <div className="d-flex justify-content-around">
                  <button
                    className="btn btn-success"
                    style={{ backgroundColor: '#32CD32', border: 'none' }}
                    onClick={() => handleGetInWorld(world.name)}
                  >
                    get in
                  </button>
                  <button
                    className="btn btn-warning"
                    style={{ backgroundColor: '#FFA500', border: 'none' }}
                    onClick={() => handleshowDescription(world.name)}
                  >
                    show description
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Custom CSS for hover effect */}
      <style jsx>{`
        .world-card:hover {
          transform: scale(1.05);
        }
      `}</style>
    </div>
  );
}

export default MyWorlds;

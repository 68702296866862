import axios from 'axios';
import React, { useState } from 'react';
import { Button, Card, Container, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #E6F0FF; /* Light Blue background */
    color: #2F4F4F; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled(Card)`
  background-color: #FAFAFA; /* Off-White for the card background */
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Card.Title)`
  text-align: center;
  font-size: 24px;
  color: #4169E1; /* Royal Blue for the title */
  margin-top: 20px;
`;

const EmailInput = styled(Form.Control)`
  margin: 20px 0;
  border-radius: 10px;
  background-color: #F0F0F0; /* Light gray background for input */
  color: #2F4F4F; /* Dark gray text */
  padding: 10px;
  border: 1px solid #2F4F4F; /* Dark Gray for input border */
`;

const NextButton = styled(Button)`
  width: 100%;
  margin-bottom: 20px;
  background-color: #FFD700; /* Golden Yellow for the button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;

  &:hover {
    background-color: #4169E1; /* Royal Blue on hover */
    color: #FAFAFA; /* Off-White text on hover */
  }
`;

function PaymentRegister() {
  const [paypalEmail, setPaypalEmail] = useState('');
  const navigate = useNavigate();

  const handleNext = async () => {
    navigate("/provider");
    await axios.post('https://sell-skill.com/api/endpoints/insertPaypalEmail', { paypalEmail });
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>
            <b>Payment Registration</b>
          </Title>
          <Form style={{ padding: '20px' }}>
            <EmailInput
              type="email"
              placeholder="Enter your correct PayPal email"
              onChange={(e) => setPaypalEmail(e.target.value)}
              value={paypalEmail}
            />
            <NextButton onClick={handleNext}>Next</NextButton>
          </Form>
        </MainCard>
      </StyledContainer>
    </>
  );
}

export default PaymentRegister;

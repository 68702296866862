import React from 'react';
import { Helmet } from 'react-helmet';
import './AboutUsPage.css'; // Create a CSS file for styling

const AboutUsPage = () => {
  return (
    <div className="about-us-page">
      <Helmet>
        <title>About Us | SellSkill - Empowering Your Online Learning</title>
        <meta name="description" content="SellSkill offers cutting-edge online courses designed to help you start making money instantly. Learn from the best in cryptocurrency, trading, and social marketing." />
        <meta name="keywords" content="SellSkill, online courses, cryptocurrency, trading, social marketing, make money online" />
        <link rel="canonical" href="https://sell-skill.com/about-us" />
      </Helmet>

      <section className="about-section">
        <h1>Welcome to SellSkill</h1>
        <p>At SellSkill, we believe in providing you with the skills you need to succeed in today's rapidly changing economy. Our platform offers a wide range of courses, each designed to help you make money online right after learning. Whether you're interested in cryptocurrency, trading, or social marketing, our expert-curated courses will guide you every step of the way.</p>
        
        {/* Hero Image */}
        <div className="hero-image">
          <img src="AboutUs/hero-image.jpg" alt="Global online learning" />
        </div>

        <h2>Why Choose Sell Skill?</h2>
        <ul>
          <li>Access to high-demand skills that translate into real-world income.</li>
          <li>Learn from industry experts with years of experience.</li>
          <li>Start making money immediately after completing our courses.</li>
          <li>Flexible learning environment tailored to your needs.</li>
        </ul>

        {/* Why Choose Us Image */}
        <div className="why-us-image">
          <img src="AboutUs/why-us-image.jpg" alt="Business growth through online learning" />
        </div>
        
        <div className="cta-section">
          <h2>Take Your First Step Towards Financial Independence!</h2>
          {/* CTA Image */}
          <div className="cta-image">
            <img src="AboutUs/cta-image.jpg" alt="Reach for success" />
          </div>
          <a href="/new-money" className="cta-button">Explore Our Courses</a>
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { setOverviewTerm } from '../../reducers/reducers';
import axios from 'axios';

function Term() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [postData, setPostData] = useState({
    _id: uuidv4(),
    term: '',
    title: '',
    skills: [],
    estimate: '',
    experience: '',
    budget: '',
    description: ''
  });

  const [permission, setPermission] = useState(false);
  let token = localStorage.getItem('clientToken');
  const tokenObject = JSON.parse(token);
  token = tokenObject.token || tokenObject;

  useEffect(() => {
    const fetchPermission = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPermission(response.data.permission);
    };
    fetchPermission();
  }, [token]);

  const handleNextClick = () => {
    dispatch(setOverviewTerm(postData));
    navigate("/posting-title");
  };

  const navigateSignUpIn = () => {
    navigate('/auth');
  };

  return (
    <div style={styles.container}>
      {permission ? (
        <Container>
          <Card style={styles.outerCard}>
            <Card.Body>
              <Card style={styles.innerCard}>
                <Card.Title style={styles.title}>
                  Is it one time or a lot?
                </Card.Title>
                <div style={styles.buttonContainer}>
                  <Button style={styles.button} onClick={handleNextClick}>
                    <Card style={styles.cardOption}>
                      <Card.Title style={styles.cardTitle}>
                        Only one call
                      </Card.Title>
                    </Card>
                  </Button>

                  <Button style={styles.button} onClick={handleNextClick}>
                    <Card style={styles.cardOption}>
                      <Card.Title style={styles.cardTitle}>
                        A lot
                      </Card.Title>
                    </Card>
                  </Button>
                </div>
              </Card>
            </Card.Body>
          </Card>
        </Container>
      ) : (
        <div style={styles.permissionContainer}>
          <Card style={styles.permissionCard}>
            <Button onClick={navigateSignUpIn} style={styles.signInButton}>Sign Up/In</Button>
          </Card>
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    height: '100vh',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Gradient background
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },
  outerCard: {
    position: 'relative',
    borderRadius: '30px',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Outer card gradient
    color: '#fff',
    width: '100%',
    maxWidth: '600px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
  },
  innerCard: {
    borderRadius: '20px',
    background: '#0f2027', // Darker background for inner card
    color: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    padding: '30px',
  },
  title: {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#00c6ff', // Bright accent color for title text
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: '20px',
  },
  button: {
    flex: 1,
    background: 'transparent',
    border: 'none',
    padding: '0',
  },
  cardOption: {
    backgroundColor: '#004e92', // Bright blue for options
    height: '150px',
    width: '100%',
    maxWidth: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    cursor: 'pointer',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  },
  cardTitle: {
    color: '#000428', // Dark text color
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  cardOptionHover: {
    transform: 'scale(1.05)',
    boxShadow: '0 8px 16px rgba(0, 198, 255, 0.3)',
  },
  permissionContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    background: 'linear-gradient(135deg, #000428, #004e92)', // Background for permission container
  },
  permissionCard: {
    borderRadius: '20px',
    backgroundColor: '#0f2027',
    color: '#fff',
    padding: '30px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
  },
  signInButton: {
    background: '#00c6ff',
    borderColor: '#00c6ff',
    color: '#000428',
    borderRadius: '8px',
    padding: '10px 20px',
    fontWeight: 'bold',
    fontSize: '18px',
    boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
  },
};

export default Term;

import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Buffer } from 'buffer';

function TopBar() {
  const [imageSrc, setImageSrc] = useState('');
  const [name, setName] = useState(null);

  useEffect(() => {
    fetch('https://sell-skill.com/api/endpoints/getProfileData')
      .then((response) => response.json())
      .then((result) => setName(JSON.stringify(result.name).replace(/"/g, '')));
  }, []);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getProfilePicture', {
          responseType: 'arraybuffer',
        });

        const base64 = Buffer.from(response.data, 'binary').toString('base64');
        const mimeType = response.headers['content-type'];
        setImageSrc(`data:${mimeType};base64,${base64}`);
      } catch (error) {
        console.error('Error fetching profile picture:', error);
      }
    };
    fetchImage();
  }, [name]);

  return (
    <Navbar bg="dark" variant="dark" expand="lg" style={{ padding: '15px 30px' }}>
      <Navbar.Brand href="/provider">My Dashboard</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/my-reports">Show Reports</Nav.Link>
          <Nav.Link href="/provider-messages">Messages</Nav.Link>
          <Nav.Link href="/provider-invitaions">My Invitations</Nav.Link>
          <Nav.Link href="/services">Blogs</Nav.Link>
          <Nav.Link href="/about-us">About us</Nav.Link>
        </Nav>
        <Nav>
          <Nav.Item className="d-flex align-items-center">
            <span className="mr-2 text-white">Welcome, {name}</span>
            <a href="/provider-profile-4provider">
              <Card className="text-center" style={{ backgroundColor: 'transparent', border: 'none' }}>
                <Card.Body>
                  {imageSrc && (
                    <img
                      src={imageSrc}
                      alt="Profile"
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '50%',
                        objectFit: 'cover',
                        border: '2px solid white',
                      }}
                    />
                  )}
                </Card.Body>
              </Card>
            </a>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default TopBar;

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../MainCourse.css'; // Custom CSS
import axios from 'axios';
import { Button, Container, Card } from 'react-bootstrap';

function AwabML() {
  const [permission, setPermission] = useState(false);
  const [cryptoCompletion, setCryptoCompletion] = useState(false);
  const navigate = useNavigate();

  const tokenString = localStorage.getItem('clientToken');
  const tokenObject = JSON.parse(tokenString);
  const token = tokenObject?.token;

  const levels = [
    { id: 1, name: 'Level 1', route: '/Awab-ML-level-1' },
    { id: 2, name: 'Level 2', route: '/Awab-ML-level-2' },
    { id: 3, name: 'Level 3', route: '/Awab-ML-level-3' },
    { id: 4, name: 'Level 4', route: '/Awab-ML-level-4' }
  ];

  // Fetch permission and completion status
  useEffect(() => {
    const fetchPermission = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setPermission(response.data.permission);
      } catch (error) {
        console.error('Error fetching permission:', error);
      }
    };

    const fetchCryptoCompletion = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getCryptoCompletion');
        setCryptoCompletion(response.data.completed); 
        if (response.data.completed) {
          await axios.post('https://sell-skill.com/api/endpoints/cryptoCertificate/upload');
        }
      } catch (error) {
        console.error("Error fetching crypto completion status:", error);
      }
    };

    fetchPermission();
    fetchCryptoCompletion();
  }, [token]);

  return (
    <div className="container-fluid main-course" style={{ backgroundColor: '#001f3f', minHeight: '100vh', paddingTop: '50px' }}>
      {permission ? (
        <div className="text-center">
          {levels.map(level => (
            <Button
              key={level.id}
              variant="primary"
              size="lg"
              disabled={!cryptoCompletion} // Disable if the crypto course is not completed
              className="exam-button my-2"
              onClick={() => navigate(level.route)}
            >
              {level.name}
            </Button>
          ))}
        </div>
      ) : (
        <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
          <Card className="text-center p-4" style={{ width: '100%', maxWidth: '400px', backgroundColor: '#1e1e2f', borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
            <Card.Body>
              <Card.Title style={{ fontSize: '24px', marginBottom: '20px' }}>Welcome Back!</Card.Title>
              <Button variant="outline-light" onClick={() => navigate('/auth')} style={{ padding: '10px 20px', fontSize: '18px' }}>
                Sign Up/In
              </Button>
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
}

export default AwabML;

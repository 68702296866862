import React from 'react'

function Lessons() {
  return (
    <div>
      lessons
    </div>
  )
}

export default Lessons

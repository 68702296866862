import React, { useState } from 'react';
import { Button, Card, Form, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #E6F0FF; /* Light Blue background */
    color: #2F4F4F; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled(Card)`
  background-color: #FAFAFA; /* Off-White for the card background */
  border-radius: 20px;
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Card.Title)`
  text-align: center;
  font-size: 30px;
  color: #4169E1; /* Royal Blue for the title */
  margin-top: 20px;
`;

const TextArea = styled(Form.Control)`
  height: 400px;
  border-radius: 20px;
  background-color: #F0F0F0; /* Light gray background for the text area */
  color: #2F4F4F; /* Dark gray text */
  padding: 15px;
`;

const NextButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #FFD700; /* Golden Yellow for the button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;

  &:hover {
    background-color: #4169E1; /* Royal Blue on hover */
    color: #FAFAFA; /* Off-White text on hover */
  }
`;

function Letter() {
  const [letter, setLetter] = useState('');
  const navigate = useNavigate();

  const handleNextClick = async () => {
    navigate("/certifications");
    await axios.post('https://sell-skill.com/api/endpoints/insertLetter', letter);
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>
            <b>Cover Letter</b>
          </Title>
          <Form style={{ padding: '20px' }}>
            <TextArea
              as="textarea"
              placeholder="Enter a cover letter for your profile"
              onChange={(e) => setLetter(e.target.value)}
              value={letter}
            />
            <NextButton onClick={handleNextClick}>Next</NextButton>
          </Form>
        </MainCard>
      </StyledContainer>
    </>
  );
}

export default Letter;

import React, { useEffect, useState } from "react";
import "./LandingPage.css"; // For custom styling and animations
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const LandingPage = () => {
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [isValid, setIsValid] = useState(false);

  const handleButtonClick = () => {
    setShowForm(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateForm();
  };

  const handlePhoneChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
    validateForm();
  };

  const validateForm = () => {
    const { name, email, phone } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidPhone = phone.length >= 10; // Phone validation based on length

    if (name && emailRegex.test(email) && isValidPhone) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const logFormData = () => {
    console.log('formData ===========================================>>>>  ', formData)
  }
  const handleSubmit = async () => {
    // Upload data to backend (Replace this with your actual API call)
    navigate('/download-FA-guide');

    await axios.post('https://sell-skill.com/api/endpoints/sendFAADCustomerInfo', formData);
  };


  useEffect(() => {
    const trackPageView = () => {
      if (window.fbq) {
        window.fbq('trackSingle', '3798752890336186', 'PageView', {
          content_name: 'Online Course Purchase',
          value: 0,
          currency: 'USD',
          content_ids: ['course_123'],
          content_type: 'product'
        });
      } else {
        console.error('Facebook Pixel is not defined');
      }
    };
  
    trackPageView();
  }, []);
  return (
    <div className="landing-page">
      <div className="content">
        <div className="left-side">
          <img
            src="/ebooksThumbnails/FA-CGEbook.jpeg"
            alt="Free Guidance on Facebook Ads"
            className="book-image"
          />
        </div>
        <div className="right-side">
          <div className="title-section">
            <h1 className="title">
              COMPLETE GUIDANCE Facebook ADS SECRETS AND HACKS
            </h1>
            
          </div>
          <button className="cta-button" onClick={handleButtonClick}>
            Get your free guidance now
          </button>
          {showForm && (
            <div className="form-container">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <PhoneInput
                country={'us'}
                value={formData.phone}
                onChange={handlePhoneChange}
                inputStyle={{
                  width: '100%',
                  padding: '10px',
                  fontSize: '16px',
                  borderRadius: '5px',
                }}
                required
              />
              <button
                className={`submit-button ${isValid ? "" : "disabled"}`}
                onClick={handleSubmit}
                disabled={!isValid}
              >
                DOWNLOAD
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <a href="/privacy-policy">Privacy Policy</a>
        <a href="/terms-conditions">Terms & Conditions</a>
        <a href="/contact-us">Contact Us</a>
      </div>
    </div>
  );
};

export default LandingPage;

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

function ClientProfilePicture() {
    const [picture, setPicture] = useState('');
    const navigate = useNavigate();

    const handleNextClick = () => {
        navigate("/client-category");
    };



    const handleUpload = async (file) => {
        const formData = new FormData();
        formData.append('picture', file);
        const response = await axios.post('https://sell-skill.com/api/endpoints/insertClientPicture', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        console.log('Upload success', response.data);
        console.log('formdata =========================>>>>>>>>>>>  ', formData)
        setPicture(file); // Update the picture preview
    };
    
    return (
        <div style={styles.container}>
            <Container>
                <Card style={styles.outerCard}>
                    <Card.Body style={styles.innerCard}>
                        <Card.Title style={styles.title}>
                            A Nice Profile Picture
                        </Card.Title>
                        <div style={styles.uploadContainer}>
                            <input
                                type="file"
                                name="picture"
                                onChange={(e) => handleUpload(e.target.files[0])}
                                style={styles.fileInput}
                            />
                        </div>
                        {picture && (
                            <img
                                src={URL.createObjectURL(picture)}
                                style={styles.previewImage}
                                alt="Profile Preview"
                            />
                        )}
                        <div style={styles.buttonContainer}>
                            <Button onClick={handleNextClick} style={styles.nextButton}>
                                Next
                            </Button>
                        </div>
                    </Card.Body>
                </Card>
            </Container>
        </div>
    );
}

export default ClientProfilePicture;
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    background: 'linear-gradient(135deg, #000428 0%, #004e92 100%)',
    color: '#fff',
    padding: '20px',
    width: '100vw',
    height: '100vh', // Ensures it takes the full viewport height
  },
  outerCard: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: 'none',
    borderRadius: '10px',
    padding: '20px',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(8px)',
    WebkitBackdropFilter: 'blur(8px)',
    maxWidth: '500px', // This ensures the card does not exceed 500px width
    width: '100%', // Make it responsive on smaller screens
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center content horizontally
  },
  innerCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center', // Center content vertically
    alignItems: 'center',
    textAlign: 'center',
    width: '100%',
  },
  title: {
    fontSize: '1.8rem',
    marginBottom: '1.5rem',
    color: '#ffffff',
    '@media (max-width: 768px)': {
      fontSize: '1.4rem',
    },
  },
  uploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  fileInput: {
    color: '#fff',
    borderRadius: '20px',
    backgroundColor: 'transparent',
    border: '2px solid #fff',
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  previewImage: {
    width: '150px',
    height: '150px',
    borderRadius: '50%',
    objectFit: 'cover',
    margin: '20px 0',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  nextButton: {
    backgroundColor: '#004e92',
    color: '#ffffff',
    borderRadius: '30px',
    padding: '10px 20px',
    transition: 'all 0.3s ease',
    fontWeight: 'bold',
    width: '100%', // Full-width for mobile
  },
};

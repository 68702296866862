// AddLevel.js
import React, { useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLevelNumber } from '../../reducers/reducers';

function AddLevel() {
  const [worldName, setWorldName] = useState('');
  const [levelNumber, setLevelNumberI] = useState('');
  const [levelTitle, setLevelTitle] = useState('');
  const [levelDescription, setLevelDescription] = useState('');
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    const levelData = {
      _id: uuidv4(), // Generate a unique ID for the level
      worldName, // World name to identify the specific world in the backend
      levelNumber: parseInt(levelNumber), // Convert to number if needed
      levelTitle,
      levelDescription,
    };

    try {
      await axios.post('https://sell-skill.com/api/endpoints/addLevel', levelData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      dispatch (setLevelNumber(levelNumber))
      navigate ('/add-lesson')
    } catch (error) {
      console.error(error);
      alert('Error adding level.');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label>World Name:</label>
        <input
          type="text"
          value={worldName}
          onChange={(e) => setWorldName(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Level Number:</label>
        <input
          type="number"
          value={levelNumber}
          onChange={(e) => setLevelNumberI(e.target.value)}
          required
        />
      </div>
      <div>
        <label>level title:</label>
        <input
          type="text"
          value={levelTitle}
          onChange={(e) => setLevelTitle(e.target.value)}
          required
        />
      </div>
      <div>
        <label>Level Description:</label>
        <textarea
          value={levelDescription}
          onChange={(e) => setLevelDescription(e.target.value)}
          required
        ></textarea>
      </div>
      <button type="submit">Add Level</button>
    </form>
  );
}

export default AddLevel;

import React, { useEffect } from 'react';
import './PayPalButton.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';

const FacebookAdsPayment = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Load PayPal Script
    const addPayPalScript = () => {
      if (window.paypal) {
        renderPayPalButton();
      } else {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.PAYPAL_CLIENT_ID}`;
        script.type = 'text/javascript';
        script.async = true;
        script.onload = renderPayPalButton;
        document.body.appendChild(script);
      }
    };

    const renderPayPalButton = () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: '5.00', // Replace with your amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          return actions.order.capture().then(async (details) => {
            alert(`Transaction completed by ${details.payer.name.given_name}`);
            await axios.patch('https://sell-skill.com/api/endpoints/paidFacebookAds');
            navigate('/facebook-ads-course');
          });
        },
        onError: (err) => {
          console.error('PayPal Checkout Error: ', err);
          alert('An error occurred during the transaction. Please try again.');
        },
      }).render('#paypal-button-container');
    };

    // Load Stripe Script
    const loadStripeScript = async () => {
      const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
      const button = document.getElementById('stripe-button');
      button.addEventListener('click', async () => {
        const response = await axios.post('https://sell-skill.com/api/stripe/create-checkout-session', {
          amount: 500, // Amount in cents (500 cents = $5.00)
          currency: 'usd',
          name: 'Crypto Course',
        });
        const { sessionId } = response.data;
        stripe.redirectToCheckout({ sessionId });
      });
    };

    addPayPalScript();
    loadStripeScript();
  }, [navigate]);

  return (
    <div className="paypal-container">
      <div className="paypal-content">
        <h2 className="paypal-title">Secure Payment Portal</h2>
        <p className="paypal-description">
          Choose your preferred payment method to access the Facebook Ads Course. We offer secure options through PayPal and Stripe. Your satisfaction and security are our top priorities.
        </p>
        <div className="payment-method">
          <div id="paypal-button-container" className="paypal-button"></div>
          <div className="stripe-container">
            <button id="stripe-button" className="stripe-button">
              Pay with Stripe
            </button>
          </div>
        </div>
        <div className="support-info">
          <p>
            Need help? <a href="mailto:support@sell-skill.com">Contact our support team</a> for assistance.
          </p>
        </div>
      </div>
    </div>
  );
};

export default FacebookAdsPayment;


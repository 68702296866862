import React from 'react'

function InstructorDashboard() {
  return (
    <div>

      **your channels
      **--sudents, sub teachers, chat, cash
      **upload worlds
      **iffy set for exam
      **your subscriptions worlds "he will be considered as an student"
    </div>
  )
}

export default InstructorDashboard

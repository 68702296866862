import React, { useState } from 'react';
import './MachineLearningCourse.css';

const CodeAcademyCourse = () => {
  const [quizCompleted, setQuizCompleted] = useState(false);

  const handleQuizSubmit = (e) => {
    e.preventDefault();
    setQuizCompleted(true);
  };

  return (
    <div className="academy-container">
      <div className="sidebar">
        <h2>Lessons in This Level</h2>
        <ul className="lesson-list">
          <li>Introduction to Machine Learning</li>
          <li>Types of Machine Learning</li>
          <li>Machine Learning Lifecycle</li>
          <li>Real-Life Applications</li>
          <li>Predicting House Prices (Case Study)</li>
        </ul>
        
        <button className="contact-instructor-btn">Contact Instructor</button>
      </div>

      <div className="main-content">
        <div className="content-section">
          <h1>Introduction to Machine Learning</h1>

          <div className="content-card">
            <h2>What is Machine Learning?</h2>
            <p>
              Machine learning is a subset of artificial intelligence (AI) that allows computers to learn
              from data without explicit programming. It involves algorithms that improve their accuracy
              with more data over time.
            </p>
          </div>

          <div className="content-card">
            <h2>Types of Machine Learning</h2>
            <h3>Supervised Learning</h3>
            <p>
              Supervised learning trains algorithms on labeled data, like predicting house prices.
              Examples include <strong>linear regression, decision trees</strong>, and more.
            </p>
            <h3>Unsupervised Learning</h3>
            <p>
              Unsupervised learning finds patterns in unlabeled data, such as customer segmentation.
              Examples include <strong>K-means clustering, PCA</strong>.
            </p>
            <h3>Reinforcement Learning</h3>
            <p>
              An agent interacts with an environment to maximize rewards, as in robotics navigation.
            </p>
          </div>

          <div className="content-card">
            <h2>Machine Learning Lifecycle</h2>
            <ul>
              <li>Problem Definition and Data Collection</li>
              <li>Data Preparation and Preprocessing</li>
              <li>Model Selection</li>
              <li>Model Training</li>
              <li>Model Evaluation</li>
              <li>Hyperparameter Tuning</li>
              <li>Model Deployment</li>
            </ul>
          </div>

          <div className="content-card">
            <h2>Case Study: Predicting House Prices</h2>
            <p>Objective: Predict house prices based on location, size, and amenities.</p>
            <p>Code Snippet:</p>
            <pre className="code-snippet">
              {`from sklearn.linear_model import LinearRegression
model = LinearRegression().fit(X, y)
predictions = model.predict(X_test)`}
            </pre>
          </div>

          {/* Quiz Section */}
          <div className="quiz-section">
            <h2>Course Quiz</h2>
            <form onSubmit={handleQuizSubmit}>
              <div className="quiz-question">
                <p>1. Which type of machine learning is used for customer segmentation?</p>
                <label>
                  <input type="radio" name="question1" value="supervised" required />
                  Supervised Learning
                </label>
                <label>
                  <input type="radio" name="question1" value="unsupervised" />
                  Unsupervised Learning
                </label>
                <label>
                  <input type="radio" name="question1" value="reinforcement" />
                  Reinforcement Learning
                </label>
              </div>
              
              <div className="quiz-question">
                <p>2. (True/False) Reinforcement learning requires labeled data.</p>
                <label>
                  <input type="radio" name="question2" value="true" required />
                  True
                </label>
                <label>
                  <input type="radio" name="question2" value="false" />
                  False
                </label>
              </div>
              
              <button type="submit" className="submit-btn">Submit Quiz</button>
            </form>

            {quizCompleted && <p className="quiz-completed">Quiz Completed! Great job!</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CodeAcademyCourse;

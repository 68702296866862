import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './LearnMorePage.css';

function LearnMorePage() {
  const navigate = useNavigate();

  return (
    <Container fluid className="py-5 learn-more-page">
      <section className="learn-more-section text-center mb-5">
        <h1 className="page-title">Discover More About Our Platform</h1>
        <p className="page-subtitle">Learn how our platform can help you achieve your goals.</p>
        <img
          src="/images/success-stories.png"
          alt="Discover how our platform can help you achieve your goals"
          className="img-fluid mb-4"
          loading="lazy"
          style={{ maxHeight: '400px', width: '100%', objectFit: 'cover' }}
        />
      </section>

      <section className="features-section mb-5">
        <h2 className="text-center mb-4">Platform Features</h2>
        <Row>
          <Col md={4} sm={6} className="mb-4">
            <Card className="text-center feature-card h-100">
              <Card.Body>
                <img src="/images/expertGuidance.png" alt="Expert Guidance" className="img-fluid mb-3" loading="lazy" />
                <Card.Title>Expert Guidance</Card.Title>
                <Card.Text>
                  Connect with industry experts who can provide tailored advice and guidance.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <Card className="text-center feature-card h-100">
              <Card.Body>
                <img src="/images/premiumCourses.png" alt="Premium Courses" className="img-fluid mb-3" loading="lazy" />
                <Card.Title>Premium Courses</Card.Title>
                <Card.Text>
                  Access a wide array of premium courses to enhance your skills and knowledge.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <Card className="text-center feature-card h-100">
              <Card.Body>
                <img src="/images/workOppertunites.png" alt="Work Opportunities" className="img-fluid mb-3" loading="lazy" />
                <Card.Title>Work Opportunities</Card.Title>
                <Card.Text>
                  Discover legitimate work-from-home jobs and freelance opportunities.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      <section className="user-testimonials-section text-center py-5">
        <h2>User Testimonials</h2>
        <p>See what our users are saying about our platform.</p>
        <Row>
          <Col md={4} sm={6} className="mb-4">
            <Card className="testimonial-card h-100">
              <Card.Body>
                <img src="/images/p1.png" alt="Jane Doe, Entrepreneur" className="img-fluid rounded-circle mb-3" loading="lazy" />
                <Card.Text>
                  "This platform has revolutionized my career. The expert advice is second to none."
                </Card.Text>
                <Card.Footer>— Will Doe, Entrepreneur</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <Card className="testimonial-card h-100">
              <Card.Body>
                <img src="/images/p2.png" alt="John Smith, Freelance Developer" className="img-fluid rounded-circle mb-3" loading="lazy" />
                <Card.Text>
                  "I found the perfect remote job opportunity through this platform. Highly recommend!"
                </Card.Text>
                <Card.Footer>— John Smith, Freelance Developer</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} sm={6} className="mb-4">
            <Card className="testimonial-card h-100">
              <Card.Body>
                <img src="/images/p3.png" alt="Sarah Johnson, Marketing Specialist" className="img-fluid rounded-circle mb-3" loading="lazy" />
                <Card.Text>
                  "The courses available here have significantly improved my skill set. Fantastic resource!"
                </Card.Text>
                <Card.Footer>— Adam Johnson, Marketing Specialist</Card.Footer>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </section>

      <section className="cta-section text-center py-5">
        <h2>Ready to Start Your Journey?</h2>
        <p>Sign up today to gain access to all these features and more!</p>
        <Button onClick={() => navigate('/auth')} className="cta-button">
          Sign Up / Login
        </Button>
      </section>

      <Footer />
    </Container>
  );
}

export default LearnMorePage;


import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function ProviderInvitation2() {
  const [choosenInvitation, setChoosenInvitation] = useState([]);
  const navigate = useNavigate();
  const clientId = useSelector((state) => state.allow.clientId);

  useEffect(() => { 
    const getChoosenInvitation = async () => {
      const response = await axios.get('https://sell-skill.com/api/endpoints/getInvitationContent');
      setChoosenInvitation(response.data);
    };
    getChoosenInvitation();
  }, []);

  const handleInviteAccept = async(clientID) => {
    try {
      console.log('clientId ======================================>     > > > '+clientID);
      await axios.post(`https://sell-skill.com/api/endpoints/insertInviteAcceptance/${clientID}`);
    } catch (error) {
      // Handle error
    }
    navigate('/provider');
  };

  return (
    <Container fluid className="py-5" style={{ backgroundColor: '#FAFAFA' }}>
      <Row className="justify-content-center">
        {choosenInvitation.length > 0 ? (
          choosenInvitation.map((invitation, index) => (
            <Col xs={12} md={8} lg={6} className="mb-4" key={index}>
              <Card className="h-100" style={{ borderColor: '#2F4F4F' }}>
                <Card.Body>
                  <Card.Text style={{ color: '#2F4F4F' }}>
                    {invitation?.invitaion?.invitaionContent}
                  </Card.Text>
                </Card.Body>
              </Card>
              <Button
                onClick={() => handleInviteAccept(invitation.invitaion.clientId)}
                style={{ backgroundColor: '#FFD700', borderColor: '#FFD700', color: '#2F4F4F' }}
              >
                Accept
              </Button>
            </Col>
          ))
        ) : (
          <Col xs={12} className="text-center">
            <div style={{ color: '#4169E1' }}>No invitations available.</div>
          </Col>
        )}
      </Row>
    </Container>
  );
}

export default ProviderInvitation2;

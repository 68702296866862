import React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './HowToUseFATrailer.css'; // Import custom CSS for styles

function JapaneseCandleSticksTrailer() {
    const navigate = useNavigate();

    return (
        <div className="ebook-trailer">
            <Helmet>
                <title>Unlock Trading Success: Japanese Candlestick Charting Techniques</title>
                <meta 
                    name="description" 
                    content="Master the powerful trading strategies of Japanese Candlestick Charting Techniques by Steve Nison. Unlock profitable trading insights today!" 
                />
                <meta 
                    name="keywords" 
                    content="Japanese Candlestick, trading, technical analysis, Steve Nison, candlestick charting, stock market, trading strategies" 
                />
            </Helmet>
            <Container className="py-5 d-flex justify-content-center">
                <Card className="shadow-lg ebook-card rounded-lg">
                    <Row noGutters>
                        <Col md={5} className="ebook-cover">
                            <img
                                src="/ebooksThumbnails/japanese-candlestick-techniques.jpg" // Replace with actual image path
                                alt="Japanese Candlestick Charting Techniques Book Cover"
                                className="img-fluid rounded-start"
                            />
                        </Col>
                        <Col md={7} className="p-4">
                            <Card.Body>
                                <Card.Title className="text-center mb-4">
                                    <h2 className="font-weight-bold">Japanese Candlestick Charting Techniques</h2>
                                    <p className="text-muted">Unveil the Secrets of Market Movements</p>
                                    <p>by Steve Nison</p>
                                </Card.Title>
                                <Card.Text className="text-justify">
                                    <p>
                                        <strong>"Japanese Candlestick Charting Techniques"</strong> is your essential guide to mastering one of the most powerful and visual trading tools in technical analysis. Whether you’re new to the markets or a seasoned trader, this book will help you make smarter and more profitable trading decisions.
                                    </p>
                                    <p>
                                        Learn how to decode the market's hidden signals with candlestick patterns that show the balance of supply and demand, emotional sentiment, and key turning points. Steve Nison's approach will empower you to predict market movements with more precision and confidence.
                                    </p>
                                    <p>
                                        Dive deep into chart patterns, strategies, and real-world applications to enhance your trading game. From identifying bullish and bearish trends to leveraging complex formations, this book is a comprehensive guide to profitable technical analysis using candlestick charts.
                                    </p>
                                </Card.Text>
                                <div className="text-center mt-4">
                                    <Button
                                        variant="success"
                                        className="btn-trailer px-5 py-2"
                                        onClick={() => navigate('/Japanese-Candle-Sticks')}
                                    >
                                        Learn More & Download
                                    </Button>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    );
}

export default JapaneseCandleSticksTrailer;

import React from 'react';

// Inline styles for simplicity, but ideally, you should use a separate CSS file or styled-components
const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: '"Arial", sans-serif',
    lineHeight: '1.6',
    color: '#333',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  },
  header: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#2c3e50',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  textarea: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    minHeight: '150px',
  },
  button: {
    backgroundColor: '#2980b9',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    transition: 'background-color 0.3s',
  },
  buttonHover: {
    backgroundColor: '#1f6391',
  },
};

function ContactUs() {
  return (
    <main style={styles.container}>
      <header>
        <h1 style={styles.header}>Contact Us</h1>
        <p>If you have any questions or need help with your tasks, feel free to reach out. We're here to connect clients with the best providers.</p>
      </header>
      <section>
        <form action="/submit-contact-form" method="POST">
          <div style={styles.formGroup}>
            <label htmlFor="name" style={styles.label}>Your Name</label>
            <input type="text" id="name" name="name" placeholder="Enter your full name" required style={styles.input} />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="email" style={styles.label}>Your Email</label>
            <input type="email" id="email" name="email" placeholder="Enter your email address" required style={styles.input} />
          </div>
          <div style={styles.formGroup}>
            <label htmlFor="message" style={styles.label}>Message</label>
            <textarea id="message" name="message" placeholder="Describe your issue or request" required style={styles.textarea}></textarea>
          </div>
          <div>
            <button type="submit" style={styles.button} onMouseOver={(e) => e.target.style.backgroundColor = '#1f6391'} onMouseOut={(e) => e.target.style.backgroundColor = '#2980b9'}>
              Send Message
            </button>
          </div>
        </form>
      </section>
      <footer style={{ marginTop: '30px', textAlign: 'center' }}>
        <p>&copy; {new Date().getFullYear()} sell-skill All rights reserved.</p>
      </footer>
    </main>
  );
}

export default ContactUs;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Buffer } from 'buffer';

function ClientCertifications() {
  const [certificateUrl, setCertificateUrl] = useState(null);
  const [error, setError] = useState('');
  const [userId, setUserId] = useState('')
  const [contentType, setContentType] = useState()
  const [profilePictureSrc, setProfilePictureSrc] = useState()

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/getCryptoCertificate', {
          responseType: 'arraybuffer',
        });
        const base64 = Buffer.from(response.data, 'binary').toString('base64');
        const mimeType = response.headers['content-type'];
        setContentType(mimeType);
        setProfilePictureSrc(`data:${mimeType};base64,${base64}`);
        console.log('PROFILE SRC ===========================> > > ', `data:${mimeType};base64,${base64}`)
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchImage();
  }, []);

  

  return (
    <div>
      {
        profilePictureSrc ? 
        (<img src={profilePictureSrc}/>) : (<div>loading ...</div>)

      }

    </div>
  );
}

export default ClientCertifications;












import axios from 'axios'; 
import React, { useState, useEffect } from 'react'; 
import { Button, Card, Form, Container, Row, Col, Alert } from 'react-bootstrap'; 
import { FaTrash } from 'react-icons/fa'; 
import { useSelector } from 'react-redux'; 
import { useNavigate } from 'react-router-dom'; 
import { v4 as uuidv4 } from 'uuid'; 
import styled, { createGlobalStyle } from 'styled-components'; 
import 'bootstrap/dist/css/bootstrap.min.css'; 

const GlobalStyle = createGlobalStyle` 
  body, html { 
    margin: 0; 
    padding: 0; 
    height: 100%; 
    background: #ECE5DD; 
    color: #333; 
    font-family: 'Roboto', sans-serif; 
  } 
`; 

const ChatContainer = styled(Container)` 
  margin-top: 20px; 
  padding: 0; 
  background: #ffffff; 
  border-radius: 12px; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
  overflow: hidden; 
  max-width: 600px;
  height: 80vh;
  display: flex;
  flex-direction: column;
`;

const MessagesContainer = styled.div`
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #ECE5DD;
`;

const MessageBubble = styled.div`
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 20px;
  margin-bottom: 10px;
  display: inline-block;
  word-wrap: break-word;
  position: relative;
`;

const UserMessageBubble = styled(MessageBubble)`
  background-color: #DCF8C6;
  margin-left: auto;
  color: #333;
  text-align: left;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #DCF8C6;
  }
`;

const OtherMessageBubble = styled(MessageBubble)`
  background-color: #ffffff;
  color: #333;
  text-align: left;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #ffffff;
  }
`;

const StyledForm = styled(Form)`
  padding: 15px;
  background: #f0f0f0;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledFormControl = styled(Form.Control)`
  flex-grow: 1;
  border-radius: 20px;
  padding: 10px 15px;
  border: none;
`;

const StyledButton = styled(Button)`
  background: #25D366;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background: #1ebf57;
  }
`;

const GenerateLinkButton = styled(Button)`
  background: #007bff;
  border: none;
  border-radius: 20px;
  padding: 10px;
  font-size: 16px;
  &:hover {
    background: #0056b3;
  }
`;

const WarningMessage = styled(Alert)` 
  background-color: #ffcc00; 
  color: black; 
  text-align: center; 
  font-weight: bold; 
  margin-bottom: 20px; 
  border-radius: 10px; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); 
`;

const SignUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #ECE5DD;
`;

const SignUpCard = styled(Card)`
  width: 400px;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

function ProviderToClientMessage() { 
  const clientID = useSelector((state) => state.allow.clientId); 
  const [messageContent, setMessageContent] = useState(''); 
  const navigate = useNavigate(); 
  const [messages, setMessages] = useState([]); 
  const [permission, setPermission] = useState(false); 
  const tokenString = localStorage.getItem('providerToken'); 
  const tokenObject = JSON.parse(tokenString); 
  const token = tokenObject?.token || tokenObject; 

  const handleProviderToClientMessage = async () => { 
    try {
      await axios.post(`https://sell-skill.com/api/endpoints/sendProviderToClientMessage/${clientID}`, { _id: uuidv4(), message: messageContent }); 
      setMessageContent(''); 
      fetchMessages(); // Refresh messages after sending
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  }; 

  const fetchMessages = async () => { 
    try { 
      const response = await fetch('https://sell-skill.com/api/endpoints/getMessagesFromClientIntoProvider'); 
      const result = await response.json(); 
      setMessages(result); 
    } catch (error) { 
      console.error('Failed to fetch messages:', error); 
    } 
  }; 

  useEffect(() => { 
    fetchMessages(); 
  }, []); 

  useEffect(() => { 
    const fetchPermission = async () => { 
      try {
        const response = await axios.get('https://sell-skill.com/api/endpoints/verifyProvider', { 
          headers: { 
            Authorization: `Bearer ${token}` 
          } 
        }); 
        setPermission(response.data.permission); 
      } catch (error) {
        console.error('Failed to fetch permission:', error);
      }
    }; 

    fetchPermission(); 
  }, [token]); 

  const navigateSignUpIn = () => { 
    navigate('/auth'); 
  }; 

  const deleteProviderMessage = async (id) => { 
    try {
      await axios.delete(`https://sell-skill.com/api/endpoints/deleteProviderMessage/${id}`); 
      fetchMessages(); // Refresh messages after deletion
    } catch (error) {
      console.error('Failed to delete message:', error);
    }
  }; 

  const handleJitsiClick = (messageContent) => {
    const match = messageContent.match(/https:\/\/meet.jit.si\/\S+/);
    if (match) {
      const url = match[0];
      window.open(url, '_blank');
    }
  };

  const generateJitsiMeetLink = () => { 
    const roomId = uuidv4(); 
    const jitsiMeetLink = `https://meet.jit.si/${roomId}`; 
    setMessageContent(jitsiMeetLink); 
  };

  return ( 
    <> 
      <GlobalStyle /> 
      <ChatContainer fluid> 
        {permission ? ( 
          <> 
            <WarningMessage variant="warning"> 
              To save your rights and avoid scams, please, before sending the link and before starting a live stream, send us on this Instagram account  
              <a href="https://www.instagram.com/sell_ur_skill/" target="_blank" rel="noopener noreferrer"> https://www.instagram.com/sell_ur_skill/</a> the following: 
              <ol> 
                <li>The client's payment transaction number</li> 
                <li>How much was the transaction</li> 
                <li>The client's email</li> 
                <li>Record the live stream and send it also to the Instagram account</li> 
              </ol> 
            </WarningMessage> 
            <MessagesContainer>
              {messages.length > 0 ? (
                messages.map((message) => (
                  <div key={message._id}>
                    {message.message.clientId === clientID ? (
                      message.message.response ? (
                        <UserMessageBubble onClick={() => handleJitsiClick(message.message.message)}>
                          {message.message.message}
                          <FaTrash style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => deleteProviderMessage(message._id)} />
                        </UserMessageBubble>
                      ) : (
                        <OtherMessageBubble onClick={() => handleJitsiClick(message.message.message)}>
                          {message.message.message}
                          <FaTrash style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => deleteProviderMessage(message._id)} />
                        </OtherMessageBubble>
                      )
                    ) : (
                      <OtherMessageBubble onClick={() => handleJitsiClick(message.message.message)}>
                        {message.message.message}
                        <FaTrash style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => deleteProviderMessage(message._id)} />
                      </OtherMessageBubble>
                    )}
                  </div>
                ))
              ) : (
                <Card className="mb-3">
                  <Card.Body>No messages available.</Card.Body>
                </Card>
              )}
            </MessagesContainer>
            <StyledForm>
              <StyledFormControl
                placeholder="Enter a message"
                onChange={(e) => setMessageContent(e.target.value)}
                value={messageContent}
              />
              <StyledButton onClick={handleProviderToClientMessage}>
                <i className="fas fa-paper-plane"></i>
              </StyledButton>
              <GenerateLinkButton onClick={generateJitsiMeetLink}>
                Generate Jitsi Link
              </GenerateLinkButton>
            </StyledForm>
          </>
        ) : ( 
          <SignUpContainer> 
            <SignUpCard> 
              <Card.Body> 
                <Card.Title>Please Sign Up or Log In</Card.Title> 
                <Card.Text> 
                  You need to be authenticated to view and send messages. 
                </Card.Text> 
                <Button onClick={navigateSignUpIn}> 
                  Go to Authentication 
                </Button> 
              </Card.Body> 
            </SignUpCard> 
          </SignUpContainer> 
        )} 
      </ChatContainer> 
    </> 
  ); 
} 

export default ProviderToClientMessage;


// import axios from 'axios';
// import React, { useState, useEffect } from 'react';
// import { Button, Card, Form, Container, Row, Col } from 'react-bootstrap';
// import { FaTrash } from 'react-icons/fa';
// import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
// import { v4 as uuidv4 } from 'uuid';
// import styled, { createGlobalStyle } from 'styled-components';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const GlobalStyle = createGlobalStyle`
//   body, html {
//     margin: 0;
//     padding: 0;
//     height: 100%;
//     background: linear-gradient(to bottom, #000428, #004e92);
//     color: #ffffff;
//     font-family: Arial, Helvetica, sans-serif;
//   }
// `;

// const StyledContainer = styled(Container)`
//   margin-top: 20px;
//   padding: 20px;
//   background: rgba(255, 255, 255, 0.1);
//   border-radius: 12px;
//   box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
// `;

// const MessageCard = styled(Card)`
//   margin: 10px 0;
//   background: rgba(255, 255, 255, 0.2);
//   border: none;
//   border-radius: 8px;
//   padding: 10px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// `;

// const SignUpContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 200px;
//   background-color: black;
//   border-radius: 12px;
//   margin-top: 20px;
// `;

// const SignUpCard = styled(Card)`
//   width: 400px;
//   text-align: center;
// `;

// function ProviderToClientMessage() {
//   const clientID = useSelector((state) => state.allow.clientId);
//   const [messageContent, setMessageContent] = useState('');
//   const navigate = useNavigate();
//   const [messages, setMessages] = useState([]);
//   const [permission, setPermission] = useState(false);
//   const tokenString = localStorage.getItem('providerToken');
//   const tokenObject = JSON.parse(tokenString);
//   const token = tokenObject?.token || tokenObject;

//   const handleProviderToClientMessage = async () => {
//     await axios.post(`https://sell-skill.com/api/endpoints/sendProviderToClientMessage/${clientID}`, { _id: uuidv4(), message: messageContent });
//     setMessageContent('')
   
//   };

//   useEffect(() => {
//     const fetchPosts = async () => {
//       try {
//         const response = await fetch('https://sell-skill.com/api/endpoints/getMessagesFromClientIntoProvider');
//         const result = await response.json();
//         setMessages(result);
//       } catch (error) {
//         console.error('Failed to fetch posts:', error);
//       }
//     };

//     fetchPosts();
//   }, []);

//   useEffect(() => {
//     const fetchPermission = async () => {
//       const response = await axios.get('https://sell-skill.com/api/endpoints/verifyProvider', {
//         headers: {
//           Authorization: `Bearer ${token}`
//         }
//       });
//       setPermission(response.data.permission);
//     };

//     fetchPermission();
//   }, [token]);

//   const navigateSignUpIn = () => {
//     navigate('/auth');
//   };

//   const deleteProviderMessage = async (id) => {
//     await axios.delete('https://sell-skill.com/api/endpoints/deleteProviderMessage/' + id);
//   };

//   return (
//     <>
//       <GlobalStyle />
//       <StyledContainer fluid className="py-5">
//         {permission ? (
//           <Row className="justify-content-center">
//             <Col xs={12} md={8} lg={6}>
//               <div>
//                 {messages.length > 0 ? (
//                   messages.map((message) => (
//                     <MessageCard key={message._id}>
//                       {message.message.clientId === clientID && (
//                         <>
//                         {
//                           message.message.response === true? 
//                           <span style={{'backgroundColor':'white'}}>{message.message.message}</span>
//                           :  <span><b style={{'color':'white'}}>{message.message.message}</b></span>
//                         }


//                           <Button variant="danger" onClick={() => deleteProviderMessage(message._id)} style={{'position':'relative', 'left':'300px'}}>
//                             <FaTrash /> 
//                           </Button>
//                         </>
//                       )}
//                     </MessageCard>
//                   ))
//                 ) : (
//                   <Card className="mb-3">
//                     <Card.Body>No messages available.</Card.Body>
//                   </Card>
//                 )}
//               </div>
//               <Form className="mt-3">
//                 <Form.Control
//                   placeholder="Enter a message"
//                   onChange={(e) => setMessageContent(e.target.value)}
//                   className="mb-3"
//                 />
//                 <Button variant="primary" onClick={handleProviderToClientMessage}>
//                   Send
//                 </Button>
//               </Form>
//             </Col>
//           </Row>
//         ) : (
//           <SignUpContainer>
//             <SignUpCard className="p-4">
//               <Button variant="primary" onClick={navigateSignUpIn}>
//                 Sign up/in
//               </Button>
//             </SignUpCard>
//           </SignUpContainer>
//         )}
//       </StyledContainer>
//     </>
//   );
// }

// export default ProviderToClientMessage;

import React from 'react';
import { Container, Card, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import './KillerFacebookAdsTrailer.css'; // Import custom CSS for styles

function KillerFacebookAdsTrailer() {
    const navigate = useNavigate();

    return (
        <div className="ebook-trailer">
            <Container className="py-5 d-flex justify-content-center">
                <Card className="shadow-lg ebook-card rounded-lg">
                    <Row noGutters>
                        <Col md={5} className="ebook-cover">
                            <img
                                src="/ebooksThumbnails/killer-facebook-ads.jpg" // Replace with your actual image path
                                alt="Killer Facebook Ads Book Cover"
                                className="img-fluid rounded-start"
                            />
                        </Col>
                        <Col md={7} className="p-4">
                            <Card.Body>
                                <Card.Title className="text-center mb-4">
                                    <h2 className="font-weight-bold">Killer Facebook Ads</h2>
                                    <p className="text-muted">Master Cutting-Edge Advertising Techniques</p>
                                    <p>by Marty Weintraub</p>
                                </Card.Title>
                                <Card.Text className="text-justify">
                                    <p>
                                        <strong>"Killer Facebook Ads"</strong> is your definitive guide to mastering the art of Facebook advertising. Whether you're a beginner or an experienced marketer, this book will equip you with advanced strategies to make your ads more effective.
                                    </p>
                                    <p>
                                        Learn how to leverage Facebook's targeting capabilities to reach specific audiences based on their interests, behaviors, and demographics. The book covers everything from understanding key performance indicators (KPIs) and creating compelling ad designs to optimizing campaigns for maximum ROI.
                                    </p>
                                    <p>
                                        Get insights into the Facebook ad creation process, campaign pricing, and scheduling, along with powerful techniques for targeting users in innovative ways. With detailed chapters on ad production workflow, guerilla user targeting, and mastering compound targeting, you'll gain a serious edge in the competitive world of social media advertising.
                                    </p>
                                </Card.Text>
                                <div className="text-center mt-4">
                                    <Button
                                        variant="primary"
                                        className="btn-trailer px-5 py-2"
                                        onClick={() => navigate('/killer-facebook-ads')}
                                    >
                                        Learn More & Download
                                    </Button>
                                </div>
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </div>
    );
}

export default KillerFacebookAdsTrailer;

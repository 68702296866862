import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import './BlogsPage.css';
import { useNavigate } from 'react-router-dom';

function BlogsPage() {
  const navigate = useNavigate()
  const blogPosts = [
    {
      title: 'Top 10 Remote Work Opportunities in 2024',
      image: '/images/remote-work.jpg',
      excerpt: 'Discover the best remote work opportunities this year and how to apply for them.',
      link: '/blog/top-10-remote-work-opportunities-2024',
    },
    {
      title: 'How to Upskill with Online Courses',
      image: '/images/upskill-online-courses.jpg',
      excerpt: 'Learn how to choose the right online courses to enhance your career.',
      link: '/blog/how-to-upskill-online-courses',
    },
    {
      title: 'Expert Tips for Starting a Freelance Career',
      image: '/images/freelance-career.jpg',
      excerpt: 'Get advice from industry experts on how to kickstart your freelance career.',
      link: '/blog/expert-tips-freelance-career',
    },
  ];

  return (
    <Container fluid className="py-5 blogs-page">
            <Button onClick={() => navigate('/')}>visit sell-skill</Button>
      <section className="hero-section text-center mb-5">
        <h1 className="page-title">Our Latest Blogs</h1>
        <p className="page-subtitle">
          Stay informed with the latest trends, tips, and insights from our expert contributors.
        </p>
      </section>

      <section className="blogs-list-section">
        <Row>
          {blogPosts.map((post, index) => (
            <Col md={4} key={index} className="mb-4">
              <Card className="blog-post-card h-100">
                <Card.Img variant="top" src={post.image} alt={post.title} />
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Text>{post.excerpt}</Card.Text>
                  <Card.Link href={post.link}>Read More</Card.Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </section>

      <section className="cta-section text-center py-5">
        <h2>Never Miss an Update</h2>
        <p>Subscribe to our newsletter and get the latest blogs delivered straight to your inbox.</p>
        <Button href="/subscribe" className="cta-button">
          Subscribe Now
        </Button>
      </section>

      <Footer />
    </Container>
  );
}

export default BlogsPage;

import React from 'react';
import { useNavigate } from 'react-router-dom';
import Helmet from 'react-helmet';
import Footer from '../Footer/Footer';

const SEOTrainingGuide = () => {
    const navigate = useNavigate();

    const handleSignUp = () => {
        navigate('/auth');
    };

    return (
        <>
            <Helmet>
                <title>Mastering SEO: A Comprehensive Guide to Becoming an Expert</title>
                <meta name="description" content="Learn how to master SEO with our comprehensive guide. Discover the time it takes to become an SEO expert, the essential pillars of SEO, and tips for speeding up your learning process." />
                <meta name="keywords" content="SEO training, SEO learning, SEO mastery, On-page SEO, Off-page SEO, Technical SEO, Local SEO, SEO trends 2024, SEO career" />
                <meta name="robots" content="index, follow" />
            </Helmet>
            <div style={styles.container}>
                <h1 style={styles.title}>Mastering SEO: A Comprehensive Guide to Becoming an Expert</h1>
                <img src="/images/seo-training-guide.jpg" alt="SEO Training Guide" style={styles.image} />

                <div style={styles.content}>
                    <h2 style={styles.subheading}>Understanding the SEO Learning Journey</h2>
                    <img src="/images/seo-learning-journey.jpg" alt="SEO Learning Journey" style={styles.image} />
                    <p style={styles.paragraph}>
                        Learning SEO is a journey that varies for each individual. The time it takes depends on several factors, including your prior knowledge of digital marketing, the time you can dedicate each week, and the quality of resources and mentorship available to you.
                    </p>

                    <h2 style={styles.subheading}>The 10,000-Hour Rule: What It Means for SEO Mastery</h2>
                    <img src="/images/seo-mastery.jpg" alt="SEO Mastery" style={styles.image} />
                    <p style={styles.paragraph}>
                        The 10,000-hour rule emphasizes the importance of practice and consistency in mastering SEO. Here's how it breaks down in the context of SEO...
                    </p>

                    <h2 style={styles.subheading}>SEO Learning: A Step-by-Step Plan to Become an Expert</h2>
                    <img src="/images/seo-step-by-step.jpg" alt="SEO Step by Step" style={styles.image} />
                    <p style={styles.paragraph}>
                        Becoming an SEO expert involves a structured approach to learning. Start with the basics, then dive into the three pillars of SEO: On-page, Off-page, and Technical SEO...
                    </p>

                    <h2 style={styles.subheading}>On-Page, Off-Page, and Technical SEO: Understanding the Three Pillars</h2>
                    <img src="/images/seo-pillars.jpg" alt="Three Pillars of SEO" style={styles.image} />
                    <p style={styles.paragraph}>
                        On-page SEO is all about optimizing individual pages on your website to rank higher and earn more relevant traffic. Off-page SEO focuses on building your website's authority, and Technical SEO involves optimizing your website's infrastructure...
                    </p>

                    <h2 style={styles.subheading}>Top Resources to Learn SEO: From Podcasts to Online Courses</h2>
                    <img src="/images/seo-resources.jpg" alt="SEO Resources" style={styles.image} />
                    <p style={styles.paragraph}>
                        Learning SEO requires access to the right resources. Here are some of the best blogs, podcasts, and online courses to help you on your journey...
                    </p>

                    <h2 style={styles.subheading}>Why SEO Mentors Matter: Learning from the Best in the Industry</h2>
                    <img src="/images/seo-mentor.jpg" alt="SEO Mentor" style={styles.image} />
                    <p style={styles.paragraph}>
                        Having an SEO mentor can significantly accelerate your learning process. Personalized guidance and experience sharing are invaluable when mastering SEO...
                    </p>

                    <h2 style={styles.subheading}>Speeding Up Your SEO Learning: Tips and Strategies</h2>
                    <img src="/images/seo-learning-tips.jpg" alt="SEO Learning Tips" style={styles.image} />
                    <p style={styles.paragraph}>
                        Learning SEO can be overwhelming, but you can speed up the process with these tips. From pacing yourself to focusing on local SEO, discover how to learn faster...
                    </p>

                    <button onClick={handleSignUp} style={styles.button}>Sign Up to Start Your SEO Journey</button>

                    <div style={styles.linkContainer}>
                        <p style={styles.linkText}>Explore more resources to help you succeed:</p>
                        <ul style={styles.linkList}>
                            <li><a href="/seo-audit" style={styles.link}>Understanding SEO Audits: What to Expect and Why They Matter</a></li>
                            <li><a href="/seo-tools" style={styles.link}>The Essential Tools for Every Aspiring SEO Specialist</a></li>
                            <li><a href="/local-seo-strategies" style={styles.link}>Why Local SEO is Essential for Your Small Business</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <Footer/>
        </>
    );
};

const styles = {
    container: {
        maxWidth: '850px',
        margin: '0 auto',
        padding: '20px',
        backgroundColor: '#ffffff',
        borderRadius: '12px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.8',
        color: '#333',
        boxSizing: 'border-box',
    },
    title: {
        fontSize: '2.5rem',
        fontWeight: '700',
        color: '#222',
        textAlign: 'center',
        marginBottom: '30px',
    },
    image: {
        width: '100%',
        borderRadius: '12px',
        marginBottom: '20px',
    },
    content: {
        padding: '0 15px',
    },
    subheading: {
        fontSize: '1.8rem',
        fontWeight: '600',
        color: '#333',
        marginTop: '30px',
    },
    paragraph: {
        fontSize: '1.1rem',
        color: '#555',
        marginTop: '15px',
        textAlign: 'justify',
        lineHeight: '1.7',
    },
    button: {
        display: 'block',
        width: 'fit-content',
        margin: '30px auto',
        padding: '12px 35px',
        fontSize: '1.1rem',
        fontWeight: '600',
        color: '#fff',
        backgroundColor: '#007BFF',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
    },
    buttonHover: {
        backgroundColor: '#0056b3',
    },
    linkContainer: {
        marginTop: '40px',
        textAlign: 'center',
    },
    linkText: {
        fontSize: '1.2rem',
        color: '#333',
        marginBottom: '15px',
    },
    linkList: {
        listStyleType: 'none',
        padding: 0,
    },
    link: {
        color: '#007BFF',
        textDecoration: 'none',
        fontSize: '1.1rem',
    },
};

export default SEOTrainingGuide;

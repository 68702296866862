import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

function ClientPosts() {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const fetchPosts = async () => {
            try {
                const responseProviderOrClientId = await axios.get('https://sell-skill.com/api/endpoints/providerOrClientId');
                const clientId = responseProviderOrClientId.data;

                const response = await axios.get(`https://sell-skill.com/api/endpoints/showClientPosts/${clientId}`);
                setPosts(response.data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            }
        };
        fetchPosts();
    }, []);

    const deletePost = async (postId) => {
        await axios.delete(`https://sell-skill.com/api/endpoints/deletePost/${postId}`);
        setPosts(posts.filter(post => post._id !== postId)); // Update state to remove deleted post
    };

    return (
        <Container className="py-4">
            <h2 className="text-center mb-4" style={styles.header}>Your Posts</h2>
            <Row className="justify-content-center">
                {posts.length > 0 ? (
                    posts.map((post) => (
                        <Col xs={12} sm={6} md={4} lg={3} key={post._id} className="mb-3">
                            <Card className="text-center" style={styles.card}>
                                <Card.Body>
                                    <Card.Title style={styles.cardTitle}>
                                        {post.title || 'Untitled Post'}
                                    </Card.Title>
                                    <Card.Text style={styles.cardText}>
                                        {post.description || 'No content available.'}
                                    </Card.Text>
                                    <Button onClick={() => deletePost(post._id)} style={styles.button}>
                                        <FaTrash /> Delete Post
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : (
                    <Col>
                        <p className="text-center" style={styles.noPostsText}>No posts available.</p>
                    </Col>
                )}
            </Row>
        </Container>
    );
}

const styles = {
    header: {
        color: '#002f6c', // Navy Blue
    },
    card: {
        backgroundColor: '#FFFFFF', // White
        color: '#008080', // Teal
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 47, 108, 0.2)', // Navy Blue shadow
    },
    cardTitle: {
        color: '#002f6c', // Navy Blue
    },
    cardText: {
        color: '#008080', // Teal
    },
    button: {
        backgroundColor: '#FFA500', // Bright Orange
        color: '#FFFFFF',
        borderRadius: '30px',
        padding: '10px',
        width: '100%',
    },
    noPostsText: {
        color: '#002f6c', // Navy Blue
    }
};

export default ClientPosts;

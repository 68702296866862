import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import './Videos.css'; // Import the external CSS for styling

function FaW1V6() {
    const [videoUrl, setVideoUrl] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVideoUrl = async () => {
            try {
                const storage = getStorage();
                const videoRef = ref(storage, '/AdobePhotoshopCourse/Week1/6.mp4');
                const url = await getDownloadURL(videoRef);
                setVideoUrl(url);
            } catch (error) {
                console.error('Error fetching video URL: ', error);
            }
        };
        fetchVideoUrl();
    }, []);

    return (
        <div className="video-container">
            <div className="video-header">
                <button className="back-button" onClick={() => navigate('/PS-course/week-1')}>
                    ← Back
                </button>
                <h1 className="course-title">Layers, Layer masks Blending Modes</h1>

            </div>
            <div className="video-content">
                {videoUrl ? (
                    <video className="course-video" controls>
                        <source src={videoUrl} type="video/mp4" />
                    </video>
                ) : (
                    <p>Loading video...</p>
                )}
            </div>
        </div>
    );
}

export default FaW1V6;
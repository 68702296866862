import React, { useState } from 'react';
import { Button, Card, Form, Container } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: #E6F0FF; /* Light Blue background */
    color: #2F4F4F; /* Dark Gray for text */
    font-family: Arial, Helvetica, sans-serif;
  }
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const MainCard = styled(Card)`
  background-color: #FAFAFA; /* Off-White for the card background */
  border-radius: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled(Card.Title)`
  text-align: center;
  font-size: 24px;
  color: #4169E1; /* Royal Blue for the title */
  margin-top: 20px;
`;

const StyledForm = styled(Form)`
  margin: 20px;
`;

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: 20px;
  background-color: #FFD700; /* Golden Yellow for the button */
  border: none;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;

  &:hover {
    background-color: #4169E1; /* Royal Blue on hover */
    color: #FAFAFA; /* Off-White text on hover */
  }
`;

function RatePerHour() {
  const [rate, setRate] = useState('');
  const navigate = useNavigate();

  const handleNextClick = async () => {
    navigate("/payment-register");
    await axios.post('https://sell-skill.com/api/endpoints/addRate', { ratePerHour: rate });
  };

  return (
    <>
      <GlobalStyle />
      <StyledContainer>
        <MainCard>
          <Title>
            <b>Rate per Hour</b>
          </Title>
          <StyledForm>
            <Form.Control 
              placeholder='How much you want to charge per hour?' 
              onChange={(e) => setRate(e.target.value)} 
              style={{ borderRadius: '20px', padding: '10px', border: '1px solid #2F4F4F' }} /* Dark Gray for input border */
            />
          </StyledForm>
          <SubmitButton onClick={handleNextClick}>Next</SubmitButton>
        </MainCard>
      </StyledContainer>
    </>
  );
}

export default RatePerHour;

import React, { useEffect, useState } from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setOverviewExperience } from '../../reducers/reducers';
import axios from 'axios';

function Experience() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const previousPostData = useSelector((state) => state.allow.overview);
    const [postData, setPostData] = useState(previousPostData);
    const [permission, setPermission] = useState(false);

    const tokenString = localStorage.getItem('clientToken');
    const tokenObject = JSON.parse(tokenString);
    const token = tokenObject.token || tokenObject;

    useEffect(() => {
        const fetchPermission = async () => {
            const response = await axios.get('https://sell-skill.com/api/endpoints/verifyClient', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setPermission(response.data.permission);
        };
        fetchPermission();
    }, [token]);

    const handleNextClick = () => {
        dispatch(setOverviewExperience(postData));
        navigate('/posting-pudget');
    };

    const navigateSignUpIn = () => {
        navigate('/auth');
    };

    return (
        <div style={styles.container}>
            {permission ? (
                <Container>
                    <Card style={styles.outerCard}>
                        <Card.Body>
                            <Card style={styles.innerCard}>
                                <Card.Title style={styles.title}>
                                    Your request needs a
                                </Card.Title>
                                <div style={styles.buttonContainer}>
                                    {['Senior', 'Medium', 'Junior'].map((level) => (
                                        <Button
                                            key={level}
                                            style={postData.experience === level ? styles.selectedButton : styles.experienceButton}
                                            onClick={() => setPostData({ ...postData, experience: level })}
                                        >
                                            {level}
                                        </Button>
                                    ))}
                                </div>
                                <Button style={styles.nextButton} onClick={handleNextClick}>
                                    Next
                                </Button>
                            </Card>
                        </Card.Body>
                    </Card>
                </Container>
            ) : (
                <div style={styles.signupPrompt}>
                    <Card style={styles.promptCard}>
                        <Button onClick={navigateSignUpIn} style={styles.signInButton}>Sign Up/In</Button>
                    </Card>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        height: '100vh',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Gradient background
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px',
    },
    outerCard: {
        borderRadius: '30px',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Outer card gradient
        color: '#fff',
        width: '100%',
        maxWidth: '600px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
        padding: '30px',
    },
    innerCard: {
        borderRadius: '20px',
        background: '#0f2027', // Inner card background
        color: '#fff',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        padding: '30px',
        textAlign: 'center',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#00c6ff', // Accent color for title
        marginBottom: '30px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '30px',
    },
    experienceButton: {
        width: '120px',
        height: '60px',
        backgroundColor: '#004e92', // Standard button background
        borderColor: '#004e92',
        color: '#fff',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        transition: 'transform 0.2s ease',
        cursor: 'pointer',
    },
    selectedButton: {
        width: '120px',
        height: '60px',
        backgroundColor: '#00c6ff', // Selected button background
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        fontSize: '16px',
        fontWeight: 'bold',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
        transition: 'transform 0.2s ease',
        cursor: 'pointer',
        transform: 'scale(1.05)', // Slightly larger scale for selected button
    },
    nextButton: {
        width: '100%',
        height: '50px',
        backgroundColor: '#00c6ff', // Next button color
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        fontWeight: 'bold',
        fontSize: '16px',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
        padding: '12px',
        marginTop: '30px',
    },
    signupPrompt: {
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        background: 'linear-gradient(135deg, #000428, #004e92)', // Background for sign-in prompt
    },
    promptCard: {
        padding: '30px',
        backgroundColor: '#0f2027',
        color: '#fff',
        borderRadius: '20px',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.4)',
    },
    signInButton: {
        background: '#00c6ff',
        borderColor: '#00c6ff',
        color: '#000428',
        borderRadius: '8px',
        padding: '10px 20px',
        fontWeight: 'bold',
        fontSize: '18px',
        boxShadow: '0 4px 8px rgba(0, 198, 255, 0.3)',
    },
};

export default Experience;

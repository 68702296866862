import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function DetermineSignUp() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: '#002f6c', // Navy blue background
      }}
    >
      <Container>
        <Row className="justify-content-center">
          <Col md={5} className="p-2">
            <Button
              variant="primary"
              size="lg"
              href="/client-sign-up"
              className="w-100"
              style={{
                height: '300px', // Button height
                borderRadius: '10px',
                backgroundColor: '#008080', // Teal color
                color: '#ffffff', // White text color
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5em',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.05)';
                e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
                e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
              }}
            >
              <img
                src="/images/clientLogo.png"
                alt="Client Logo"
                style={{ width: '80px', height: '80px', marginBottom: '20px' }}
              />
              Client
            </Button>
          </Col>
          <Col md={5} className="p-2">
            <Button
              variant="secondary"
              size="lg"
              href="/provider-sign-up"
              className="w-100"
              style={{
                height: '300px', // Button height
                borderRadius: '10px',
                backgroundColor: '#32CD32', // Vibrant green color
                color: '#ffffff', // White text color
                border: 'none',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '1.5em',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s ease, box-shadow 0.2s ease',
              }}
              onMouseEnter={(e) => {
                e.target.style.transform = 'scale(1.05)';
                e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.2)';
              }}
              onMouseLeave={(e) => {
                e.target.style.transform = 'scale(1)';
                e.target.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1)';
              }}
            >
              <img
                src="/images/teacherLogo.png"
                alt="Provider Logo"
                style={{ width: '80px', height: '80px', marginBottom: '20px' }}
              />
              Provider
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DetermineSignUp;

